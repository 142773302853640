import React, { Component } from 'react';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import zipObject from 'lodash/zipObject';
import { ENTER_KEYCODE, ESC_KEYCODE, SPACE_KEYCODE } from 'client-utils/keyCodes';
import { DESIGNER, IN_STORE } from 'plp/constants';
import { inStoreFilterCount } from './components/InStoreFilter/inStoreFilter';
import FilterDropDown from './components/FilterDropDown/filterDropDown';
import './filters.scss';
import './inline-filters.scss';


export function getSelectedFilterCountMobile(selectedFilters){
  return reduce(selectedFilters, (result, value, key) => {
    return result + value.length;
  }, 0);
}


export function getSelectedFilterCount(selectedFilters) {
  return reduce(selectedFilters, (result, value, key) => {
    if(key === IN_STORE){
      return result + inStoreFilterCount(value);
    }
    return result + value.length;
  }, 0);
}

export function getSelectedFilterCountExcludingCategory(selectedFilters) {
  return reduce(selectedFilters, (result, value, key) => {
    if (key === 'Category') {
      return result;
    }

    if (key === IN_STORE) {
      return result + inStoreFilterCount(value);
    }
    return result + value.length;
  }, 0);
}

class Filters extends Component {
  constructor(props) {
    super(props);
    const { applicableFilters = [] } = props;
    this.state = {
      filterState: Object.assign({}, this.getInitialFilters(applicableFilters),
        this.getSelectedFilters(props.applicableFilters, props.submittedFilters)),
    };
    this.openFilter = this.openFilter.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.keyOpenFilter = this.keyOpenFilter.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  onFilterChange(selectedOption) {
    const currentFilterState = Object.assign({}, this.state.filterState,
      selectedOption);
    this.props.onFilterChange(currentFilterState);
  }

  getInitialFilters(applicableFilters) {
    return reduce(applicableFilters,
      (result, value) => Object.assign({}, result, { [value.filterKey]: [] }), {});
  }

  getSelectedFilters(applicableFilters, selectedFilters) {
    const filterKeys = map(applicableFilters, 'filterKey');
    const selectedOptions = map(filterKeys, (filterKey, index) => {
      const selectedFiltersForKey = get(selectedFilters, filterKey, []);
      return selectedFiltersForKey
        .filter((opt) => applicableFilters[index].facets !== undefined
        && applicableFilters[index].facets[opt]);
    });
    const selectedFiltersWithOptions = zipObject(filterKeys, selectedOptions);
    if (includes(filterKeys, IN_STORE)) {
      selectedFiltersWithOptions[IN_STORE] = selectedFilters[IN_STORE] || [];
    }
    return selectedFiltersWithOptions;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const selectedFilters = this.getSelectedFilters(nextProps.applicableFilters,
      nextProps.submittedFilters);
    const filters = Object.assign({}, this.getInitialFilters(this.props.applicableFilters),
      selectedFilters);
    if (!isEqual(filters, this.state.filterState)) {
      this.setState({ filterState: filters });
    }
  }

  drawerDirection(id, filterKey) {
    if (filterKey !== DESIGNER) {
      if (!this.state[id]) {
        const mainContent = document.getElementById('mainContent');
        if (mainContent) {
          const windowWidth = parseFloat(window.getComputedStyle(mainContent).getPropertyValue('width'));
          const totalWidth = (document.documentElement.clientWidth - windowWidth) / 2;
          const rectRight = this.state.dropDiv.getBoundingClientRect().right - totalWidth;
          if (rectRight > windowWidth) {
            this.setState({
              [id]: 1,
            });
          } else {
            this.setState({
              [id]: 0,
            });
          }
        }
      }
    }
  }

  openFilter(filterKey, e) {
    const id = `${filterKey}-direction`;
    if (this.state[id] === undefined) {
      const currentDropA = e.target.parentNode.nextElementSibling;
      const currentDropSpan = e.target.parentNode.parentNode.nextElementSibling;
      if (currentDropA === null) {
        this.setState({
          dropDiv: currentDropSpan,
        });
      } else {
        this.setState({
          dropDiv: currentDropA,
        });
      }
    }

    if (!this.state[filterKey]) {
      this.setState({
        [filterKey]: true,
      }, () => {
        this.drawerDirection(id, filterKey);
      });
    } else {
      this.setState({ [filterKey]: false });
    }
  }

  keyOpenFilter(e) {
    if (e.which === ENTER_KEYCODE || e.which === SPACE_KEYCODE) {
      e.preventDefault();
      this.closeFilters();
      this.openFilter(e.target.id, e);
    } else if (e.which === ESC_KEYCODE) {
      this.closeFilters();
    }
  }

  closeFilters() {
    const activeFilter = Object.keys(this.state).find((key) => this.state[key] === true);
    if (activeFilter) {
      this.setState({ [activeFilter]: false });
    }
  }

  showClearFilters() {
    return getSelectedFilterCount(this.props.submittedFilters) > 1
      && <button className="clear-filters" onClick={this.props.onClearFilters} tabIndex="0">Clear All Filters</button>;
  }

  render() {
    return (
      <div className="filter">
        <span className="filter-by-text">Filter By:</span>
        {this.showClearFilters()}
        <ul>
          {this.props.applicableFilters.map((filterOption) => (
            <FilterDropDown
              key={get(filterOption, 'filterKey').split(' ')[0]}
              filterOption={filterOption}
              onFilterChange={this.onFilterChange}
              openFilter={this.openFilter}
              closeFilters={this.closeFilters}
              keyOpenFilter={this.keyOpenFilter}
              selectedFilter={this.state[filterOption.filterKey]}
              selectedOptions={get(this.state.filterState, get(filterOption, 'filterKey'), [])}
              drawerDirection={this.state[`${filterOption.filterKey}-direction`]}
              totalItems={this.props.totalItems}
              loadFilterOptions={this.props.loadFilterOptions}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export default Filters;
