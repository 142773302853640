/* eslint-disable camelcase */
import cookie from 'react-cookie';
import omitBy from 'lodash/omitBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getParams } from 'client-utils/utilities-router';
import { setSelectedGender } from 'client-utils/utilities-gender';
import { getStoreJson } from 'shared/components/YourNeimans/components/utilities';
import { TYPEAHEAD_TEST, ABTEST_COLLAPSED_SEARCH_BAR } from 'shared/actions/actions-page';
import { DT_SELECTED_STORE, DT_FAVORITE_STORE, DT_SELECTED_ZIPCODE } from 'plp/constants';

export function getCustomSearchTerm(searchKeyword, autoCorrectToggle,
  autoCorrect, relaxedQueryToggle, relaxedQuery) {
  let customKeyword = searchKeyword;
  if (autoCorrectToggle && autoCorrect) {
    customKeyword = autoCorrect;
  } else if (relaxedQueryToggle && relaxedQuery) {
    customKeyword = relaxedQuery;
  }
  return customKeyword;
}

export function getDeviceType(deviceList = {}) {
  if (deviceList.isMobilePhone) {
    return 'Mobile';
  }
  if (deviceList.isTablet) {
    return 'Tablet';
  }
  return 'Desktop';
}

export const isModalOpen = (state, modalType) => {
  const open = get(state, 'modal.open');
  const type = get(state, 'modal.type');
  return open && (type === modalType);
};

export const isGenderOnHPandSRP = (state) => {
  const hideGenderSwitch = state.toggles.GENDERLESS_SRP;
  const genderNavToggleSRP = state.toggles.SRP_GENDER;
  const genderNavToggleHP = state.toggles.HP_GENDER;

  return (genderNavToggleSRP && genderNavToggleHP) && !hideGenderSwitch;
};

export const promoTitlePlaceHolders = {
  Mobile: {
    withOutLazyLoad: [8, 45, 60, 61],
    withLazyLoad: [8, 46, 77, 99],
  },
  Desktop: {
    withOutLazyLoad: [8, 46, 60, 61],
    withLazyLoad: [8, 46, 77, 101],
  },
  Tablet: {
    withOutLazyLoad: [8, 47, 62],
    withLazyLoad: [8, 46, 77],
  },
};

export const getMenuItems = (menu) => {
  let result = menu;
  const hasSubcategories = menu.some((item) => Object.prototype.hasOwnProperty.call(item, 'categories'));
  const subCategories = menu.filter((item) => item.categories);

  if (hasSubcategories) {
    const newMenu = subCategories[0].categories;

    result = getMenuItems(newMenu);
    return result;
  }

  return result;
};

function findNavItem(leftNav = [], crumb = '') {
  let retVal = {};
  for (let i = 0; i < leftNav.length; i++) {
    if (leftNav[i].crumb === crumb) {
      retVal = leftNav[i];
      break;
    }
  }
  return retVal;
}

export function getSearchBreadCrumb(currentCategoryCrumb, leftNav) {
  if (currentCategoryCrumb === undefined) {
    return [];
  }

  const categoryCrumb = currentCategoryCrumb.charAt(0) === '/'
    ? currentCategoryCrumb.substring(1, currentCategoryCrumb.length)
    : currentCategoryCrumb;
  const bcArray = categoryCrumb.split('/');
  const keys = [];
  const crumbs = [];
  for (let i = 0; i < bcArray.length; i++) {
    keys.push(`/${bcArray.slice(0, i + 1).join('/')}`);
  }
  let categories = leftNav;
  keys.forEach((key) => {
    const crumb = findNavItem(categories, key);
    crumbs.push(crumb);
    categories = crumb.categories ? crumb.categories : [];
  });
  return crumbs;
}

const DEFAULT_REQUEST_OPTIONS = {
  page: 1,
  sortBy: '',
  src: '',
};

export function getRequestOptions(props) {
  const { location = {} } = props;
  const {
    sortBy, page, filterOptions, src,
  } = getParams(location);
  return {
    ...DEFAULT_REQUEST_OPTIONS,
    ...omitBy({
      sortBy, page, filterOptions, src,
    }, isEmpty),
    keyword: get(location, 'query.q', ''),
  };
}

export const isGenderNavOn = (state) => {
  return state.toggles.SRP_GENDER;
};

export const refreshResultsForGenderSwitch = (gender, router, isNewSrpUI = false) => {
  setSelectedGender(gender);
  const query = router?.location?.query;
  const {
    from,
    responsive,
    request_type,
    search_type,
    q,
    l,
    fl,
  } = query;
  const nextHref = `${isNewSrpUI ? '/s/' : '/search.jsp'}?from=${from}&responsive=${responsive}&request_type=${request_type}&search_type=${search_type}&q=${q}&l=${l}&fl=${fl}`;
  window.location.href = nextHref;
};

export const isTypeAheadOn = (state) => {
  const isDomestic = get(state, 'locale.countryCode', 'US') === 'US';
  const toggle = state.toggles.TYPEAHEAD;
  const typeAheadAbTest = state.toggles.ABTEST_TYPEAHEAD;
  const isGenderTypeahead = state.toggles.GENDER_TYPEAHEAD; 
  const testAssignment = get(state, `abTestsOpt.${TYPEAHEAD_TEST}.variation`) === 'b';
  const isTypeAheadExperience = (toggle || (!isGenderTypeahead && typeAheadAbTest && (testAssignment))) && isDomestic;
  return isTypeAheadExperience;
};

export const isCollapsedSearchBarOn = (state) => {
  const isDomestic = get(state, 'locale.countryCode', 'US') === 'US';
  const collapseSearchToggle = state.toggles.COLLAPSED_SEARCH_BAR;
  const collapseSearchAbTest = state.toggles.ABTEST_COLLAPSED_SEARCH_BAR && 
  get(state, `abTestsOpt.${ABTEST_COLLAPSED_SEARCH_BAR}.variation`) === 'b';
  const isCollapsedSearchExperience = ((collapseSearchToggle || collapseSearchAbTest) && isDomestic);
  return isCollapsedSearchExperience;
};

export const renderSelectedSRP = (selectedFilterOptions = {}, catSelected, genderNavExperience) => {
  let sum = 0;
  if (typeof selectedFilterOptions !== 'string') {
    Object.keys(selectedFilterOptions).forEach((option) => {
      if (!isEmpty(selectedFilterOptions[option]) && option !== 'In Store' && option !== 'Category') {
        sum += selectedFilterOptions[option].length;
      } else if (!isEmpty(selectedFilterOptions[option]) && option === 'In Store') {
        let inStoreSum = 0;
        const inStoreArray = selectedFilterOptions[option].slice(1);
        inStoreArray.forEach((deliveryOption) => {
          if (!isEmpty(deliveryOption)) {
            inStoreSum += 1;
          }
        });
        sum += inStoreSum;
      }
    });
  }
  if (!isEmpty(catSelected) && genderNavExperience) {
    const catCount = catSelected.length;
    sum += catCount;
  }
  return sum;
};

export const formatTopItems = (topProductsData) => {
  if (typeof topProductsData[0] === 'string') {
    return topProductsData;
  }
  if (!isEmpty(topProductsData)) {
    const topItems = topProductsData
      .reduce((filtered, product) => {
        if (product) {
          const { price } = product;
          let prices = [];
          const retailPrice = parseInt(price.retailPrice, 10);
          // eslint-disable-next-line no-prototype-builtins
          if (price.hasOwnProperty('adornments')) {
            prices = [
              ...price.adornments.map(({ price }) => parseInt(price, 10)),
              price.promotionalPrice,
              retailPrice,
            ].sort((a, b) => +a - +b).filter((e) => e !== undefined);
          } else {
            prices = [
              price.promotionalPrice,
              retailPrice,
            ].sort((a, b) => +a - +b);
          }
          const highPrice = prices[prices.length - 1];
          const lowPrice = prices[0];
          let onePrice = null;
          if (parseInt(highPrice, 10) === parseInt(lowPrice, 10)) {
            onePrice = lowPrice;
          }
          if (!highPrice) {
            onePrice = `${lowPrice}`;
          }

          const nextProduct = {
            title: product.designer.name,
            url: product.url,
            img: product.main,
            oldPrice: highPrice,
            price: lowPrice,
            onePrice,
          };
          filtered.push(nextProduct);
        }
        return filtered;
      }, []);
    return topItems;
  }
  return [];
};

export const isIncircleMemberCheck = () => {
  try {
    const incircleData = window?.sessionStorage?.getItem('incircleData');
    const incircleStatusLevel = !isEmpty(incircleData)
      && JSON.parse(incircleData)?.[0]?.benefit_level;
    const isIncircleMember = +incircleStatusLevel >= 3;

    return isIncircleMember;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Error occurred while getting incircle data. Error: ${e}`);
    return false;
  }
};

export const isMultiSelectOnMobile = (facetedFiltersList, selectedFilterKey) => {
  const selectedList = facetedFiltersList[selectedFilterKey];
  const listKeys = Object.keys(selectedList);
  const selectedValues = listKeys.filter((element) => selectedList[element] === true);
  const valuesLength = selectedValues.length;
  let isMultiSelect;

  if (valuesLength >= 1) isMultiSelect = true;
  if (valuesLength === 0) isMultiSelect = false;

  return isMultiSelect;
};

export const getAvailableStoresInfo = () => {
  const selectedStore = cookie.load(DT_SELECTED_STORE);
  const favoriteStore = cookie.load(DT_FAVORITE_STORE);
  const cleanZip = cookie.load(DT_SELECTED_ZIPCODE)
    && cookie.load(DT_SELECTED_ZIPCODE).slice(1, -1);

  return { selectedStore, favoriteStore, cleanZip };
};

export const extractStoreNumber = (storeId) => {
  if (storeId) {
    return storeId.indexOf('/') === -1 ? storeId : storeId.slice(0, storeId.indexOf('/'));
  }

  return storeId;
};

export const convertLowerCase = (name) => {
  const storeName = name.toLowerCase().split(' ');
  const displayName = [];

  storeName.forEach((e) => displayName.push(e.charAt(0).toUpperCase() + e.slice(1)));

  return displayName.join(' ');
};

export const getMappedStoreInfo = async (selectedStore, favoriteStore, selectedZip) => {
  let storeInfo = {};
  const getStoreData = async (key, store) => {
    try {
      const storeData = await getStoreJson(extractStoreNumber(store));
      const displayText = convertLowerCase(storeData?.data?.name);

      storeInfo = { ...storeInfo, [key]: displayText, selectedZip };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error occurred while getting info about stores. Error: ${e}`);
    }
  };

  if (selectedStore && selectedStore !== 'undefined') await getStoreData('selectedStore', selectedStore);
  if (favoriteStore && favoriteStore !== 'undefined') await getStoreData('favoriteStore', favoriteStore);
  if (selectedZip) storeInfo = { ...storeInfo, selectedZip };

  return storeInfo;
};
