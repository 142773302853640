export const DESIGNER_CAT_ID = 'cat000730';
export const CUSP_CAT_ID = 'cat58930763';
export const UNITED_STATES = 'US';
export const CHINA = 'CN';
export const DESKTOP_DEVICE_TYPE = 'desktop';
export const MOBILE_DEVICE_TYPE = 'mobile';
export const CANADA = 'CA';
export const GIFTS_CATEGORY_NAME = 'Gifts';
export const LEFT_NAV = 'leftNav';
export const GENDER_MAP = {
  M: 'male',
  W: 'female',
};
export const ABTEST_SALE = 'nmsa0001';
export const SALE_SILO_NAME = 'Sale';
export const BR_SEG_COOKIE_NAME = 'bre_brd_segmentation';
export const ABTEST_BF_SALE = 'tl150';
export const ABTEST_GIFT = 'ngs1';
export const FAVORITE_DESIGNERS = 'Favorite Designers';
export const ABTEST_HN_RIGHT = 'tl228';