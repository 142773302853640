import React, { Component } from 'react';
import { SIZE, DESIGNER, IN_STORE } from 'plp/constants';
import { unescape } from 'he';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import Button from 'shared/components/Button/button';
import DesignerFilter from '../DesignerFilter/designerFilter';
import SizeFilterComponent from '../SizeFilter/sizeFilter';
import GeneralFilter from '../GeneralFilter/generalFilter';
import FilterButtons from '../FilterButtons/filterButtons';
import InStoreFilter, { inStoreFilterCount } from '../InStoreFilter/inStoreFilter';
import { sortSelectedValuesForDesigner, sortSelectedValuesForGeneral } from '../../filterSorter';
import DesignerFilterSelections from '../DesignerFilter/components/DesignerFilterSelections/designerFilterSelections';
import '../../filters.scss';
import '../../inline-filters.scss';

class FilterDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedOptions: props.selectedOptions, isHovering: false };
    this.onFilterValueClick = this.onFilterValueClick.bind(this);
    this.onCurrentSelectionCancelClick = this.onCurrentSelectionCancelClick.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.showSelectedOptions = this.showSelectedOptions.bind(this);
    this.closeFilterDropdown = this.closeFilterDropdown.bind(this);
    this.inStoreFilterSubmitHandler = this.inStoreFilterSubmitHandler.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.selectAll = this.selectAll.bind(this);
  }

  onFilterValueClick(filterKey, selectedOption) {
    if (this.state.selectedOptions.includes(selectedOption)) {
      this.setState({
        selectedOptions:
          filter(this.state.selectedOptions, (option) => option !== selectedOption),
      });
    } else {
      this.setState({ selectedOptions: this.state.selectedOptions.concat([selectedOption]) });
    }
  }

  onCurrentSelectionCancelClick(selectedOption) {
    if (this.state.selectedOptions.includes(selectedOption)) {
      this.setState({
        selectedOptions:
          filter(this.state.selectedOptions, (option) => option !== selectedOption),
      });
      const srAlert = document.getElementById('screenReaderAlert');
      srAlert && (srAlert.textContent = `Removed ${selectedOption}`);
    }
  }

  getSortedSelectedValues(facets, validSelections, filterKey) {
    if (isEqual(filterKey, 'Designer')) {
      return sortSelectedValuesForDesigner(validSelections);
    }
    return sortSelectedValuesForGeneral(facets, validSelections);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.selectedOptions, this.state.selectedOptions)) {
      this.setState({ selectedOptions: nextProps.selectedOptions });
    }
  }

  inStoreFilterSubmitHandler(selectedOptions) {
    if (isEmpty(this.props.selectedOptions) && isEqual(selectedOptions, ['', ''])) {
      return;
    }
    if (!isEqual(this.props.selectedOptions, selectedOptions)) {
      this.closeFilterDropdown();
      this.setState({ selectedOptions });
      this.props.onFilterChange({ [IN_STORE]: selectedOptions });
    }
  }

  handleFilterSubmit(filterKey) {
    if (isEqual(filterKey, IN_STORE)) {
      if (this.inStoreFilterRef) {
        const selectedOptions = this.inStoreFilterRef.getInStoreOptions();
        this.inStoreFilterSubmitHandler(selectedOptions);
      }
    } else if (!isEqual(this.props.selectedOptions, this.state.selectedOptions)) {
      this.closeFilterDropdown();
      if (isEqual(filterKey, 'Size')) {
        sortSelectedValuesForGeneral(
          this.props.filterOption.facetsOrderList, this.state.selectedOptions
        );
      }
      this.props.onFilterChange({ [filterKey]: this.state.selectedOptions });
    }
  }

  showSelectedOptions() {
    this.setState({
      isHovering: true,
    });
  }

  closeFilterDropdown() {
    this.props.closeFilters();
    this.setState({
      isHovering: false,
    });
  }

  indexClickHandler(scrollContainer, header) {
    header.focus();
    scrollContainer.scrollTop = header.offsetTop - scrollContainer.offsetTop;
  }

  clearAll(filterKey) {
    this.setState({ selectedOptions: [] });
    const id = `#${filterKey}_selectButton`;
    const selectButton = document.querySelectorAll(id)[0];

    setImmediate(() => {
      selectButton && selectButton.focus();
    });
  }

  selectAll(displayableFacets, filterKey) {
    this.setState({ selectedOptions: keys(displayableFacets) });
    const id = `#${filterKey}_clearButton`;
    const clearButton = document.querySelectorAll(id)[0];

    setImmediate(() => {
      clearButton && clearButton.focus();
    });
  }

  render() {
    const { loadFilterOptions = true } = this.props;
    const filterKey = get(this.props.filterOption, 'filterKey');
    const facets = get(this.props.filterOption, 'facets', {}) || {};
    const displayText = get(this.props.filterOption, 'displayText', '');
    const expandedState = this.props.selectedFilter ? this.props.selectedFilter : false;
    const validSelections = this.props.selectedOptions.filter((option) => facets[option]);
    const displayableFacets = facets;
    const facetsOrderList = get(this.props.filterOption, 'facetsOrderList', []) || [];
    const showFilterDisplayName = () => {
      const filterDisplayName = displayText || filterKey;
      const selectedOptionsLength = filterKey === IN_STORE
        ? inStoreFilterCount(this.props.selectedOptions)
        : validSelections.length;
      return (
        <span className="filter-display-name">
          {selectedOptionsLength > 0 && filterKey === IN_STORE && <span className="in-store-check" />}
          {unescape(filterDisplayName)}
          <span className="selected-filter-count">
            {selectedOptionsLength > 0 && filterKey !== IN_STORE && `(${selectedOptionsLength})`}
          </span>
        </span>
      );
    };
    const filterButtons = () => (
      <FilterButtons
        onSelectAll={() => { this.selectAll(displayableFacets, filterKey); }}
        onClear={() => { this.clearAll(filterKey); }}
        onSubmit={() => { this.handleFilterSubmit(filterKey); }}
        filterKey={filterKey}
        selectedValues={this.state.selectedOptions}
      />
    );
    const generalFilter = () => (
      <section>
        {filterButtons()}
        <GeneralFilter
          facets={displayableFacets}
          filterKey={filterKey}
          onFilterValueClick={this.onFilterValueClick}
          selectedValues={this.state.selectedOptions}
          facetsOrderList={facetsOrderList}
        />
      </section>
    );
    const renderFilter = () => {
      switch (filterKey) {
        case DESIGNER:
          return (
            <DesignerFilter
              facets={displayableFacets}
              filterKey={filterKey}
              onFilterValueClick={this.onFilterValueClick}
              selectedValues={this.state.selectedOptions}
              onSelectAll={() => { this.selectAll(displayableFacets, filterKey); }}
              onClear={() => { this.clearAll(filterKey); }}
              onSubmit={() => { this.handleFilterSubmit(filterKey); }}
              onCurrentSelectionCancelClick={this.onCurrentSelectionCancelClick}
              indexClickHandler={this.indexClickHandler}
            />
          );
        case IN_STORE:
          return (
            <section className="in-store-section">
              <InStoreFilter
                filterKey={filterKey}
                ref={(ref) => { this.inStoreFilterRef = ref; }}
                selectedValues={this.state.selectedOptions}
                onClear={() => this.setState({ selectedOptions: [] })}
                onSubmit={this.inStoreFilterSubmitHandler}
              />
            </section>
          );
        case SIZE:
          return (
            <section>
              {filterButtons()}
              <SizeFilterComponent
                facets={displayableFacets}
                filterKey={filterKey}
                onFilterValueClick={this.onFilterValueClick}
                selectedValues={this.state.selectedOptions}
                facetsOrderList={facetsOrderList}
                storageHandler={this.props.storageHandler}
              />
            </section>
          );
        default:
          return generalFilter();
      }
    };
    const renderFilterOnSelectedFlag = () => {
      if (loadFilterOptions) {
        return renderFilter();
      }
      if (this.props.selectedFilter) {
        return renderFilter();
      }
      return false;
    };
    return (
      <li
        onMouseEnter={this.showSelectedOptions}
        onMouseLeave={this.closeFilterDropdown}
        data-tid={`filter-key-${filterKey}`}
      >
        <div
          className={
            classnames(
              'filter-items',
              {
                'filter-item-tab-border': this.props.selectedFilter,
                'filter-disable': isEmpty(displayableFacets) && filterKey !== IN_STORE,
              }
            )
          }
        >
          <Button
            className="filter-item"
            ariaLabel={`${unescape(displayText)} filter`}
            ariaExpanded={expandedState}
            id={filterKey}
            onClick={(e) => this.props.openFilter(filterKey, e)}
            onKeyDown={(e) => this.props.keyOpenFilter(e)}
            value={showFilterDisplayName()}
          />

          {this.state.isHovering && !isEmpty(this.state.selectedOptions)
          && filterKey !== IN_STORE
            && (
            <div className="tooltip">
              <h4 className="tooltip__title">CURRENT SELECTIONS:</h4>
              <DesignerFilterSelections
                facets={displayableFacets}
                selectedValues={
                  this.getSortedSelectedValues(facetsOrderList, validSelections, filterKey)}
              />
            </div>
            )
          }
        </div>
        <div
          className={
            classnames(
              'filter-dropdown',
              {
                'instore-filter': filterKey === IN_STORE,
                invisible: !this.props.selectedFilter,
                'open-left': this.props.drawerDirection === 1,
              }
            )
          }
          onMouseLeave={() => { this.handleFilterSubmit(filterKey); }}
        >
          {renderFilterOnSelectedFlag()}
        </div>
      </li>
    );
  }
}

export default FilterDropDown;
