import omit from 'lodash/omit';
import {
  RESET_ABTEST,
  REJECTED_ABTEST,
  SET_TEST_FROM_COOKIE,
  SET_TEST_FROM_RESPONSE,
} from 'shared/actions/actions-page';

export const defaultState = {
  responses: [],
  cpPersonalization: false,
  eveningWearReturnsFeeExperience: false,
  isPlpAspectRatio2To3: false,
  aemAbTestUsergroup: '',
  tntVals: '',
  abTestResults: {},
  finalSaleExperience: false,
  showTrueFitGSAWidget: false,
  simplifiedMiniCartExperience: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESET_ABTEST: {
      return defaultState;
    }
    case REJECTED_ABTEST: {
      return defaultState;
    }
    case SET_TEST_FROM_RESPONSE:
    case SET_TEST_FROM_COOKIE: {
      const content = { ...state.abTestResults, [action.payload.abTestName]: omit(action.payload, 'abTestName') };
      return {
        ...state,
        abTestResults: content,
      };
    }
    default:
      return state;
  }
};
