/* eslint-disable camelcase */
import get from 'lodash/get';
import { ecmCanPersonalize } from 'ecm/utilities';
import log from 'server/utilities/logger';
import { getSelectedGender, getGenderPlacement, getValidGender } from 'client-utils/utilities-gender';
import {
  APP_SET_USER,
  APP_SET_SESSION,
  APP_LOSE_FOCUS,
  APP_PUT_IN_FOCUS,
  APP_SET_LOCALE,
  APP_REQUEST_RECEIVED,
  APP_SET_REVISIT_USER,
  APP_PLA_COOKIE_RECEIVED,
  APP_COOKIES_RECEIVED,
  APP_PARSE_PERSONALIZE,
  APP_AB_TEST_SESSION_DATA_RECEIVED,
  APP_SET_HEADER_STATE,
  SET_GENDER,
  SET_TYPEAHEAD_UTAG,
} from './app-action-types';

const defaultState = {
  componentInFocus: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOSE_FOCUS: {
      const newState = (state.componentInFocus === action.currentComponentInFocus) ? '' : state.componentInFocus;
      return {
        ...state,
        componentInFocus: newState,
      };
    }
    case APP_PUT_IN_FOCUS: {
      return {
        ...state,
        componentInFocus: action.componentInFocus,
      };
    }
    default:
      return state;
  }
};

const initialUserState = {
  ucid: '',
  rid: '',
  revisitUser: false,
};

export const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case APP_SET_USER:
      return {
        ...state,
        ...action.user,
      };
    case APP_SET_REVISIT_USER:
      return {
        ...state,
        revisitUser: action.revisitUser,
      };
    default:
      return state;
  }
};

const defaultCookieState = { enableStubService: false, enablePromoPreview: false };
export const cookiesReducer = (state = defaultCookieState, { type, cookies, abTestIds = [] }) => {
  if (type === APP_COOKIES_RECEIVED) {
    const abTestsWithCookies = {};
    for (let i = 0; i < abTestIds.length; i++) {
      if (cookies[abTestIds[i].abTestCookieName]) {
        abTestsWithCookies[abTestIds[i].abTestCookieName] = cookies[abTestIds[i].abTestCookieName];
      }
    }
    return {
      enableStubService: cookies.ENABLE_STUB_SERVICE || false,
      enablePromoPreview: cookies.PR_PR === 'true' || cookies.PR_PR === true || false,
      ...abTestsWithCookies,
    };
  }

  return state;
};

export const togglesReducer = (state = {}) => state;

export const serviceTogglesReducer = (state = {}) => state;

const initialSessionState = {
  JSESSIONID: '',
  referer: '',
  dt_gender: getSelectedGender(),
  dt_gender_placement: getGenderPlacement(),
};

export const sessionReducer = (state = initialSessionState, action) => {
  switch (action.type) {
    case APP_SET_SESSION:
      return {
        ...state,
        ...action.session,
      };
    case APP_PARSE_PERSONALIZE: {
      const personalize = ecmCanPersonalize(action.payload);
      return {
        ...state,
        personalize,
      };
    }
    case APP_REQUEST_RECEIVED: {
      const { request: { headers } } = action.payload;
      const callMetadataService = get(headers, 'showproductdata') === 'true';
      return {
        ...state,
        callMetadataService,
      };
    }
    case SET_GENDER: {
      const newState = {
        ...state,
        dt_gender: getValidGender(action.payload.gender),
      };

      if (action.payload.placement) {
        newState.dt_gender_placement = action.payload.placement;
      }

      return newState;
    }
    case SET_TYPEAHEAD_UTAG: {
      const {
        previous_view_search_submit,
        previous_view_spr_submit,
        search_function,
        search_type_ahead,
        facet_selection,
        facet_type,
      } = action.payload;
      return {
        ...state,
        previous_view_search_submit,
        previous_view_spr_submit,
        search_function,
        search_type_ahead,
        facet_selection,
        facet_type,
      };
    }
    default:
      return state;
  }
};

const initialLocaleState = {
  countryCode: 'US',
  currencyCode: 'USD',
  localeUrl: '/en-us/',
  selectedCountryCode: '',
  selectedCurrencyCode: '',
  selectedLocaleUrl: '',
};

export const countrySelectorReducer = (state = initialLocaleState, action) => {
  switch (action.type) {
    case APP_SET_LOCALE:
      return {
        ...state,
        ...action.locale,
      };
    default:
      return state;
  }
};


const initialStrategyState = {
  enabled: false,
  iplaValue: '',
};

export const productListingAdReducer = (state = initialStrategyState, action) => {
  switch (action.type) {
    case APP_PLA_COOKIE_RECEIVED: {
      return {
        ...state,
        enabled: (action.payload.ipla && action.payload.ipla.startsWith('DTT')),
        iplaValue: action.payload.ipla,
      };
    }
    default:
      return state;
  }
};

const initialABTESTState = {
  session: '',
  tntId: '',
  mboxData: '',
};

export const abTestSessionReducer = (state = initialABTESTState, action) => {
  switch (action.type) {
    case APP_AB_TEST_SESSION_DATA_RECEIVED:
      return {
        ...state,
        mboxData: action.mboxCookie,
      };
    default:
      return state;
  }
};

const initialHeaderState = {
  isStickyActive: true,
  isSearchExposed: true,
  isTop: true,
  addFocus: false,
  hasOpenedSearch: false,
  tickerHeight: 50,
  isBottom: false,
  screenSizeDesktopInMobile: true,
};

export const headerReducer = (state = initialHeaderState, action) => {
  switch (action.type) {
    case APP_SET_HEADER_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
