import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { unescape } from 'he';
import { countryCurrencyData } from 'storefront/components/CountrySelector/CountryChooser/countryCurrencyData';
import { overridenCountryCurrencyData } from 'storefront/components/CountrySelector/CountryChooser/overridenCountryCurrencyData';
import { UNITED_STATES, ABTEST_BF_SALE, ABTEST_SALE, ABTEST_GIFT } from 'storefront/components/constants';
import { normalize } from './utilities-url';
import { isCountry } from './utilities-country';

const defaultSeparator = '|';

export function getTitle(prefix = '', separator = defaultSeparator) {
  return `${prefix.trim()} ${separator} Neiman Marcus`.trim();
}

function getDesignerName(product) {
  return unescape(get(product, 'designer.name', ''));
}

function getProductName(product) {
  return unescape(get(product, 'name', ''));
}

export function getProductTitle(product) {
  const designerName = getDesignerName(product);
  const productName = getProductName(product);
  return `${designerName} ${productName}`.trim();
}

export function getGroupPDPPageTitle(groupProduct) {
  let childProduct;
  const isEditorial = get(groupProduct, 'productFlags.isEditorial', false);
  if (!isEditorial) {
    childProduct = get(groupProduct, 'childProducts[0]', false);
  }
  const productName = getProductName(groupProduct);
  const designerName = childProduct ? getDesignerName(childProduct) : '';
  return `${designerName} ${productName}`.trim();
}

export function getPDPPageTitle(product = {}) {
  const productTitle = (!product.isGroup)
    ? getProductTitle(product)
    : `${getGroupPDPPageTitle(product)} & Matching Items`;
  return getTitle(productTitle);
}

export function getMetaDescriptionGroupPDP(groupProduct) {
  const title = getGroupPDPPageTitle(groupProduct);
  return `Get free shipping on ${title} & matching items at Neiman Marcus. Shop the latest luxury fashions from top designers.`;
}

export function getMetaDescriptionPDP(product) {
  const designerName = getDesignerName(product);
  const productName = getProductName(product);
  const content = `${designerName} ${productName}`.trim();
  return `Get free shipping on ${content} at Neiman Marcus. Shop the latest luxury fashions from top designers.`;
}

export function getMetaInfoPDP(product) {
  return get(product, 'metadata.metaInfo', '');
}

export function getCanonicalUrlPDP(product) {
  const url = get(product, 'details.canonicalUrl', '');
  return normalize(url);
}

export function getMetaTitle(metaObject) {
  return getTitle(get(metaObject, 'metaTitle', ''));
}

export function getMetaDescription(metaObject) {
  return get(metaObject, 'metaDescription', '').trim();
}

export function getMetaKeywords(metaObject) {
  return get(metaObject, 'metaKeywords', '').trim();
}

export function getMetaTags(metaObject) {
  return get(metaObject, 'metaTags', []);
}

export function getRelLinks(metaObject) {
  return get(metaObject, 'relLinks', []);
}

export function normalizeCanonicalUrl(canonicalUrl, brand) {
  const url = canonicalUrl && canonicalUrl.trim();
  return normalize(url, brand);
}

export function isMobile() {
  return typeof window !== 'undefined' && window.matchMedia('(max-width: 767px)').matches;
}

export function isDesktop() {
  return typeof window !== 'undefined' && window.matchMedia('(min-width: 1201px)').matches;
}

export function isDeviceTablet() {
  return typeof window !== 'undefined' && window.matchMedia('(min-width: 768px)').matches && window.matchMedia('(max-width: 1200px)').matches;
}

export function redirect(url) {
  window.location.href = url;
}

export const getLocaleUrl = (country) => {
  const countryDetail = find(countryCurrencyData, { countryCode: country });
  return countryDetail ? countryDetail.url : '';
};

export function findAndReplaceLocaleContextInHref(locationPathName,
  localeContextPath,
  countryCode,
  localeUrl) {
  if (isCountry(countryCode, UNITED_STATES)) {
    return locationPathName.replace(localeContextPath, '');
  }
  return localeUrl + locationPathName.replace(localeContextPath, '');
}

export const getCurrencyCode = (country) => {
  const countryDetail = find(
    overridenCountryCurrencyData,
    { countryCode: country }
  ) || find(countryCurrencyData, { countryCode: country });
  return countryDetail ? countryDetail.currencyCode : '';
};

export const isTouchDevice = () => {
  if (typeof window !== 'undefined') {
    return 'ontouchstart' in window || navigator.msMaxTouchPoints;
  }
  return false;
};

export const getAbTestSilosParam = (isDomestic, state) => {
  const isSilo = (siloType) => get(state, `abTestsOpt.${siloType}.variation`, '') === 'b';
  const isSaleSilo = isSilo(ABTEST_SALE);
  const isBFSaleSilo = isSilo(ABTEST_BF_SALE);
  const isGiftSilo = isSilo(ABTEST_GIFT);
  if (isDomestic) {
    if ((isSaleSilo || isBFSaleSilo) && !isGiftSilo) return '&saleSiloAbtParam=b';
    if (isGiftSilo && !(isSaleSilo || isBFSaleSilo)) return '&giftSiloAbtParam=b';
  }
  return '';
};

export const isGWPFeatureEnabled = (state) => {
  return  get(state, 'locale.countryCode', 'US') === 'US' && get(state, 'templates.templateDetails.id', '') ===  get(state, 'giftWithPurchaseCategoryId', '')
  &&  (get(state, 'toggles.GWP_PLP', false) || (get(state, 'toggles.ABTEST_GWP_FEATURE', false) === true
      && get(state, `abTestsOpt.tl141.variation`, 'a') === 'b'))
};

export const getTypeOfSalePrice = (retailPrice, original, promotionalPrice) => {
  if ((retailPrice && original) && !promotionalPrice) return 'C';
  if ((retailPrice && promotionalPrice) && !original) return 'RP';
  if (original && retailPrice && promotionalPrice) return 'CP';
  return 'R';
};

export const concatSaleType = (itemSaleType, typeOfSalePrice) => {
  return isEmpty(itemSaleType) ? typeOfSalePrice : itemSaleType + ', ' + typeOfSalePrice;
}

export const getBrowserInfo = (userAgent) => {
  // Regular expressions to match different browsers and versions
  const browserRegex = {
      Chrome: /(?:Chrome|CriOS)\/([\d.]+)/,
      Firefox: /Firefox\/([\d.]+)/,
      Safari: /Version\/([\d.]+).*Safari/,
      Edge: /Edge\/([\d.]+)/,
      IE: /MSIE\s([\d.]+)/,
      Opera: /Opera\/([\d.]+)/,
  };
  console.log(userAgent);

  // Iterate over each browser regex and try to match it with the user agent
  for (const [browser, regex] of Object.entries(browserRegex)) {
      const match = userAgent.match(regex);
      if (match) {
          return {
              browserName: browser,
              version: match[1]
          };
      }
  }

  // If no match is found, return unknown
  return {
      browserName: 'Unknown',
      version: 'Unknown'
  };
}