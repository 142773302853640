import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import IndexGrid from './components/IndexGrid/indexGrid';
import ContentGrid from './components/ContentGrid/contentGrid';
import FilterButtons from '../FilterButtons/filterButtons';
import DesignerFilterSelections from './components/DesignerFilterSelections/designerFilterSelections';

import './designerFilter.scss';

export default class DesignerFilter extends Component {
  constructor() {
    super();
    this.contentHeaderOffset = {};
    this.state = {
      selectedAlphabet: '',
    };
    this.indexClickHandler = this.indexClickHandler.bind(this);
  }

  getKey(firstChar) {
    return /[A-Z|a-z]/.test(firstChar) ? firstChar.toUpperCase() : '#';
  }

  mapFacetsToGrid(data) {
    const facetValueGrid = {
      indexGrid: {},
      contentGrid: {
        // eslint-disable-next-line object-property-newline
        A: [], B: [], C: [], D: [], E: [], F: [], G: [], H: [],
        // eslint-disable-next-line object-property-newline
        I: [], J: [], K: [], L: [], M: [], N: [], O: [], P: [],
        // eslint-disable-next-line object-property-newline
        Q: [], R: [], S: [], T: [], U: [], V: [], W: [], X: [],
        // eslint-disable-next-line object-property-newline
        Y: [], Z: [], '#': [],
      },
    };

    Object.keys(data).forEach((facet) => {
      const [firstChar] = facet;
      const contentValues = { label: facet, value: data[facet] };
      const key = this.getKey(firstChar);
      facetValueGrid.contentGrid[key].push(contentValues);
    });

    const keys = Object.keys(facetValueGrid.contentGrid);
    keys.forEach((key) => {
      if (isEmpty(facetValueGrid.contentGrid[key])) {
        delete facetValueGrid.contentGrid[key];
      } else {
        facetValueGrid.indexGrid[key] = true;
      }
    });
    return facetValueGrid;
  }

  indexClickHandler(event) {
    this.setState({ selectedAlphabet: event.target.textContent });
    const header = this.contentHeaderOffset[event.target.textContent];
    this.props.indexClickHandler(this.scrollContainer, header);
  }

  handleSelectionPluralization(values) {
    let currentSelectionHeading = 'Current Selection:';
    (values) && (values.length > 1) && (currentSelectionHeading = 'Current Selections:');
    return currentSelectionHeading;
  }

  render() {
    const facetValueGrid = this.mapFacetsToGrid(this.props.facets);
    const {
      selectedValues,
      filterKey,
      onClear,
      onSubmit,
      onSelectAll,
      onFilterValueClick,
      onCurrentSelectionCancelClick,
    } = this.props;

    return (
      <section className="designer-filter-expand">
        <div className="designer-grid-container">
          <div className="designer-grid--left-panel">
            <IndexGrid
              indexValues={facetValueGrid.indexGrid}
              clickHandler={this.indexClickHandler}
              selectedIndexAlphabet={this.state.selectedAlphabet}
            />
            <div className="current-selections">
              <div className="current-selections__heading">
                {this.handleSelectionPluralization(selectedValues)}
              </div>
              <DesignerFilterSelections
                facets={this.props.facets}
                selectedValues={selectedValues}
                onCurrentSelectionCancelClick={onCurrentSelectionCancelClick}
              />
            </div>
          </div>
          <div className="designer-grid--right-panel">
            <div className="actions-container">
              <FilterButtons
                onSubmit={onSubmit}
                onClear={onClear}
                onSelectAll={onSelectAll}
                filterKey={filterKey}
                selectedValues={selectedValues}
              />
            </div>
            <div
              className="content-grid"
              ref={(input) => {
                this.scrollContainer = input;
              }}
            >
              <ContentGrid
                content={facetValueGrid.contentGrid}
                filterKey={filterKey}
                headerObjects={this.contentHeaderOffset}
                onFilterValueClick={onFilterValueClick}
                selectedValues={selectedValues}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
