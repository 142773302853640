import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { buildCookieString } from 'universal/utilities-cookies';
import httpWithLogging from 'universal/http-client';
import { findAndReplaceLocaleContextInHref, getAbTestSilosParam } from 'client-utils/utilities-page';
import {
  UNITED_STATES, DESKTOP_DEVICE_TYPE, MOBILE_DEVICE_TYPE, GENDER_MAP, ABTEST_SALE
} from 'storefront/components/constants';
import { CMSParams } from 'client/cms/constants';
import logger from 'server/utilities/logger';
import { getGenderWithOverride , getSelectedGender } from 'client-utils/utilities-gender';
import navigationMen from './fallback-men.json';
import navigationWomen from './fallback-women.json';
import hcNavigation from 'clientHorchow/storefront/components/Navigation/fallback.json';

export const types = {
  LOADING_NAVIGATION: 'LOADING_NAVIGATION',
  RESOLVED_NAVIGATION: 'RESOLVED_NAVIGATION',
  REJECTED_NAVIGATION: 'REJECTED_NAVIGATION',
  LOADING_NAVIGATION_MOBILE: 'LOADING_NAVIGATION_MOBILE',
  RESOLVED_NAVIGATION_MOBILE: 'RESOLVED_NAVIGATION_MOBILE',
  REJECTED_NAVIGATION_MOBILE: 'REJECTED_NAVIGATION_MOBILE',
  UPDATE_NAV_BAR: 'UPDATE_NAV_BAR',
  TOGGLE_NAV_SLIDER: 'TOGGLE_NAV_SLIDER',
  LOADING_BREADCRUMB_CONTENT: 'LOADING_BREADCRUMB_CONTENT',
  RESOLVED_BREADCRUMB_CONTENT: 'RESOLVED_BREADCRUMB_CONTENT',
  REJECTED_BREADCRUMB_CONTENT: 'REJECTED_BREADCRUMB_CONTENT',
  RESOLVED_EDITORIAL_BREADCRUMB_CONTENT: 'RESOLVED_EDITORIAL_BREADCRUMB_CONTENT',
  RESOLVED_EDITORIAL_CLASS: 'RESOLVED_EDITORIAL_CLASS',
  SET_VISUAL_NAV: 'SET_VISUAL_NAV',
};

export const newDesignerIndexSilo = {
  catmanId: 'cat000730',
  id: 'designersIndexId',
  level: 0,
  name: 'Designers',
  url: '/c/designers-cat000730?navpath=cat000000_cat000730',
  attributes: {
    tags: [
      'HC',
    ],
  },
  categories: [
    {
      catmanId: 'loadNewDI',
      id: 'loadNewDI',
      level: 0,
      name: 'Loading new desinger index',
      url: '#',
      attributes: {},
      categories: [],
      children: 0,
    },
  ],
};
export const getConfig = ({
  JSESSIONID = '', DYN_USER_ID = '', TLTSID = '', W2A = '',
}) => {
  return {
    headers: {
      Cookie: buildCookieString({
        JSESSIONID,
        DYN_USER_ID,
        TLTSID,
        W2A,
      }),
    },
  };
};

export const getCountryCodeAndLocaleUrl = (state) => {
  const countryCode = (state.locale && state.locale.countryCode)
    ? state.locale.countryCode : UNITED_STATES;
  const localeUrl = (state.locale && state.locale.localeUrl)
    ? state.locale.localeUrl : '';
  return { countryCode, localeUrl };
};

export function getSilosDesktop() {
  return (dispatch, getState) => {
    dispatch({ type: types.LOADING_NAVIGATION });
    const state = getState();
    const { session = {} } = state;
    const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const NAV_API_TIMEOUT = get(apiTimeouts, 'NAVIGATION_API_TIMEOUT', NODE_CLIENT_DEFAULT_TIMEOUT);
    const requestApi = httpWithLogging(state, NAV_API_TIMEOUT);
    const { countryCode, localeUrl } = getCountryCodeAndLocaleUrl(state);
    const enableDynamicNavigation = get(state, 'toggles.ACN_DYNAMIC_NAVIGATION', false);
    const usePreviewLambda = get(state, 'toggles.PREVIEW_CONTENT_API', false);
    const usePreview = state?.api?.requestContext?.[CMSParams.CMS_PREVIEW]
      && (state?.api?.requestContext?.isInternalIp
        || isEmpty(state?.api?.requestContext?.TRUE_CLIENT_IP));
    const useApiKey = get(state, 'toggles.CONTENT_LAMBDA_API_KEY', false);
    const brand = state.brand_name?.env;
    const config = getConfig(session);
    if (usePreview && usePreviewLambda) {
      config.headers['use-preview-lambda'] = 'true';
    }
    if (!usePreview && useApiKey) {
      config.headers['use-api-key'] = 'true';
    }
    const globalSiloAssetsToggle = get(state, 'toggles.GLOBAL_SILO_ASSETS', false);
    const genderNavigationFallback = ( getGenderWithOverride(state) === 'M' || getSelectedGender() === 'M') ?  navigationMen?.silos : navigationWomen?.silos;
    if (globalSiloAssetsToggle) {
      config.headers.cmsSiloGlobalAssets = true;
    }
    return requestApi.get(getSilosApiUri(state, DESKTOP_DEVICE_TYPE), config)
      .then((successResponse) => {
        if (!enableDynamicNavigation && isEmpty(successResponse.data.silos)) throw new Error('Silos data is empty');
        dispatch({
          type: types.RESOLVED_NAVIGATION,
          payload: transformSilosByCountryCode(successResponse.data.silos,
            countryCode,
            localeUrl),
        });
      })
      .catch(() => {
        logger.info('An error occurred in Navigation Service API and static json is being rendered.');
        brand === 'HC' ?
          dispatch({
            type: types.REJECTED_NAVIGATION,
            payload: hcNavigation.silos,
          })
          : dispatch({
            type: types.REJECTED_NAVIGATION,
            payload: genderNavigationFallback,
          });
      });
  };
}

export function transformSilosByCountryCode(silos, countryCode, localeUrl) {
  if (!countryCode || !localeUrl) return silos;
  const silosCopy = cloneDeep(silos);
  recursivelyTransformSilos(silosCopy, countryCode, localeUrl);
  return silosCopy;
}

function recursivelyTransformSilos(silos, countryCode, localeUrl) {
  if (isEmpty(silos)) return;
  silos.forEach((silo) => {
    if (silo.url) {
      silo.url = findAndReplaceLocaleContextInHref(silo.url, localeUrl, countryCode, localeUrl);
    }
    recursivelyTransformSilos(silo.categories, countryCode, localeUrl);
  });
}

export function getSilosMobileInitial() {
  return (dispatch, getState) => {
    dispatch({ type: types.LOADING_NAVIGATION_MOBILE });
    const state = getState();
    const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const NAV_API_TIMEOUT = get(apiTimeouts, 'NAVIGATION_API_TIMEOUT', NODE_CLIENT_DEFAULT_TIMEOUT);
    const requestApi = httpWithLogging(state, NAV_API_TIMEOUT);
    const { countryCode, localeUrl } = getCountryCodeAndLocaleUrl(state);
    const config = getConfig({ ...state.session, ...state.user });
    const genderNavigationFallback = ( getGenderWithOverride(state) === 'M' || getSelectedGender() === 'M') ?  navigationMen : navigationWomen;
    return requestApi.get(getMobileInitialUri(state), config)
      .then((successResponse) => {
        const mobileSilos = loadNewDesignerIndexSilo(successResponse.data.silos);

        dispatch({
          type: types.RESOLVED_NAVIGATION_MOBILE,
          payload: {
            silos: transformSilosByCountryCode(mobileSilos,
              countryCode,
              localeUrl),
          },
        });
      })
      .catch(() => dispatch({
        type: types.REJECTED_NAVIGATION_MOBILE,
        payload: genderNavigationFallback,
      }));
  };
}

// is not being used
function getMobileInitialUri(state) {
  const navKeyGroup = get(state, 'abTestsOpt.topnav.variation', '');
  const abtGrpSaleSilo = get(state, `abTestsOpt.${ABTEST_SALE}.variation`, false);

  const countryCode = getCountryCodeAndLocaleUrl(state).countryCode;
  const isDomestic = countryCode === 'US';

  const saleSiloAbtParam = (isDomestic && abtGrpSaleSilo === 'b') ?  `${navKeyGroup ? '&' : '?'}saleSiloAbtParam=${abtGrpSaleSilo}` : '';

  if (navKeyGroup !== '') {
    return `${NMConfig.API_SILOS}/${countryCode}/initial?navKeyGroup=${navKeyGroup}${saleSiloAbtParam}`;
  }
  return `${NMConfig.API_SILOS}/${countryCode}/initial${saleSiloAbtParam}`;
}

export function updateNavLeftRight(navBar) {
  return (dispatch) => dispatch({ type: types.UPDATE_NAV_BAR, navBar });
}

export function toggleNavSliderMenu() {
  return (dispatch) => dispatch({ type: types.TOGGLE_NAV_SLIDER });
}

export function toggleNavSliderAndLoadSilos() {
  return (dispatch, getState) => {
    dispatch({ type: types.TOGGLE_NAV_SLIDER });
    dispatch({ type: types.LOADING_NAVIGATION_MOBILE });
    const state = getState();
    const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const NAV_API_TIMEOUT = get(apiTimeouts, 'NAVIGATION_API_TIMEOUT', NODE_CLIENT_DEFAULT_TIMEOUT);
    const requestApi = httpWithLogging(state, NAV_API_TIMEOUT);
    const { countryCode, localeUrl } = getCountryCodeAndLocaleUrl(state);
    const usePreviewLambda = get(state, 'toggles.PREVIEW_CONTENT_API', false);
    const usePreview = state?.api?.requestContext?.[CMSParams.CMS_PREVIEW]
      && (state?.api?.requestContext?.isInternalIp
        || isEmpty(state?.api?.requestContext?.TRUE_CLIENT_IP));
    const useApiKey = get(state, 'toggles.CONTENT_LAMBDA_API_KEY', false);
    const brand = state.brand_name?.env;
    const genderNavigationFallback = ( getGenderWithOverride(state) === 'M' || getSelectedGender() === 'M') ?  navigationMen : navigationWomen;
    const headers = {};
    if (usePreview && usePreviewLambda) {
      headers['use-preview-lambda'] = 'true';
    }
    if (!usePreview && useApiKey) {
      headers['use-api-key'] = 'true';
    }

    return requestApi.get(getSilosApiUri(state, MOBILE_DEVICE_TYPE), { headers })
      .then((successResponse) => dispatch({
        type: types.RESOLVED_NAVIGATION_MOBILE,
        payload: {
          silos: transformSilosByCountryCode(successResponse.data.silos,
            countryCode,
            localeUrl),
        },
      }))
      .catch(() => dispatch({
        type: types.REJECTED_NAVIGATION_MOBILE,
        payload: brand === 'HC' ? hcNavigation : genderNavigationFallback
      }));
  };
}

export function fetchSilosForNewMobileNav(selectedGender) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOADING_NAVIGATION_MOBILE });
    const state = getState();
    const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const NAV_API_TIMEOUT = get(apiTimeouts, 'NAVIGATION_API_TIMEOUT', NODE_CLIENT_DEFAULT_TIMEOUT);
    const requestApi = httpWithLogging(state, NAV_API_TIMEOUT, true);
    const enableDynamicNavigation = get(state, 'toggles.ACN_DYNAMIC_NAVIGATION', false) && !state.device.isWebCrawler;
    const { countryCode, localeUrl } = getCountryCodeAndLocaleUrl(state);
    const usePreviewLambda = get(state, 'toggles.PREVIEW_CONTENT_API', false);
    const usePreview = state?.api?.requestContext?.[CMSParams.CMS_PREVIEW]
      && (state?.api?.requestContext?.isInternalIp
        || isEmpty(state?.api?.requestContext?.TRUE_CLIENT_IP));
    const useApiKey = get(state, 'toggles.CONTENT_LAMBDA_API_KEY', false);
    const brand = state.brand_name?.env;
    const genderNavigationFallback = ( selectedGender === 'M' || getSelectedGender() === 'M') ?  navigationMen : navigationWomen;
    const headers = {};
    if (usePreview && usePreviewLambda) {
      headers['use-preview-lambda'] = 'true';
    }
    if (!usePreview && useApiKey) {
      headers['use-api-key'] = 'true';
    }

    return requestApi.get(getSilosApiUri(state, MOBILE_DEVICE_TYPE, selectedGender), { headers })
      .then((successResponse) => {
        const mobileSilos = loadNewDesignerIndexSilo(successResponse.data.silos);
        dispatch({
          type: types.RESOLVED_NAVIGATION_MOBILE,
          payload: {
            silos: transformSilosByCountryCode(mobileSilos,
              countryCode,
              localeUrl),
            serverNavigation: enableDynamicNavigation && state.device.isServer,
          },
        });
      }).catch(() => {
        brand === 'HC' ?
          dispatch({
            type: types.REJECTED_NAVIGATION_MOBILE,
            payload: hcNavigation,
          })
          : dispatch({
            type: types.REJECTED_NAVIGATION_MOBILE,
            payload: genderNavigationFallback,
          })
      });
  };
}

export function loadNewDesignerIndexSilo(silos) {
  if (!isEmpty(silos)) {
    const diIndex = silos.findIndex((silo) => silo.catmanId === 'cat000730');
    silos[diIndex] = newDesignerIndexSilo;
  }
  return silos;
}

export function updateSilos(silos) {
  return (dispatch) => {
    dispatch({ type: types.RESOLVED_NAVIGATION_MOBILE, payload: { silos } });
  };
}

function getSilosApiUri(state, deviceType, selectedGender) {
  const countryCode = getCountryCodeAndLocaleUrl(state).countryCode;
  const isDomestic = countryCode === 'US';
  let gender = '';
  if (selectedGender) {
    gender = selectedGender
  } else {
    gender = getGenderWithOverride(state);
  }
  const genderTgl = get(state, 'toggles.HP_GENDER', false) && isDomestic;
  const genderParam = genderTgl ? `?gender=${GENDER_MAP[gender]}` : '';

  const silosAbTestParam = getAbTestSilosParam(isDomestic, state);

  const url = `${NMConfig.API_NAVIGATION_SILOS}/${countryCode}/${deviceType}${genderParam}${silosAbTestParam}`;
  return url;
}

export function getBreadcrumbList(categoryIds, source) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOADING_BREADCRUMB_CONTENT });
    const state = getState();
    const NODE_CLIENT_DEFAULT_TIMEOUT = 3000;
    const apiTimeouts = get(state, 'apiTimeouts', {});
    const NAV_API_TIMEOUT = get(apiTimeouts, 'NAVIGATION_API_TIMEOUT', NODE_CLIENT_DEFAULT_TIMEOUT);
    const requestApi = httpWithLogging(state, NAV_API_TIMEOUT);
    const navKeyGroup = get(state, 'abTestsOpt.topnav.variation', '');
    let breadCrumpAPIUrl = `${NMConfig.API_BREADCRUMBS}?categoryIds=${categoryIds}&source=${source}`;
    if (navKeyGroup !== '') {
      breadCrumpAPIUrl = `${breadCrumpAPIUrl}&navKeyGroup=${navKeyGroup}`;
    }
    const gender = getGenderWithOverride(state);
    const genderTgl = get(state, 'toggles.HP_GENDER', false);
    const genderParam = genderTgl ? `&gender=${gender}` : '';
    breadCrumpAPIUrl = `${breadCrumpAPIUrl}${genderParam}`;
    return requestApi.get(breadCrumpAPIUrl)
      .then((successResponse) => {
        return dispatch({ type: types.RESOLVED_BREADCRUMB_CONTENT, payload: successResponse.data });
      })
      .catch(() => dispatch({ type: types.REJECTED_BREADCRUMB_CONTENT }));
  };
}

export function dispatchPreFetchedBreadcrumbData(data) {
  return (dispatch) => {
    return dispatch({
      type: types.RESOLVED_BREADCRUMB_CONTENT,
      payload: data,
    });
  };
}
