import axios from 'axios'
import Auth from '@aws-amplify/auth';
import isEmpty from 'lodash/isEmpty';
import * as Scroll from 'react-scroll';
import httpWithLogging from 'universal/http-client';
import get from 'lodash/get';
import window from 'window-or-global';
import { APP_PUT_IN_FOCUS } from 'shared/components/App/app-action-types';
import {
  filterGuestUser,
  filterLocalCognito,
  filterUserAttributes,
} from 'shared/components/YourNeimans/components/utilities';
import { getGuestTokens, isGuestValid } from '@nmg/auth';
import { internationalMiniCartHtml } from './internationalMiniCart';

export const types = {
  LOADING_MINI_CART: 'LOADING_MINI_CART',
  RESOLVED_MINI_CART: 'RESOLVED_MINI_CART',
  REJECTED_MINI_CART: 'REJECTED_MINI_CART',
  UPDATE_MINI_CART_ITEM_COUNT: 'UPDATE_MINI_CART_ITEM_COUNT',
  SET_CLOSE_TIMER: 'SET_CLOSE_TIMER',
  ANIMATE_CART_CLOSED: 'ANIMATE_CART_CLOSED',
  SET_RECENTLY_ADDED_ITEM_COUNT: 'SET_RECENTLY_ADDED_ITEM_COUNT',
};

const MINI_CART_CONTAINER_ID = '#miniCartContainer';

const startCloseTimer = (dispatch) => {
  return setTimeout(() => {
    dispatch({ type: types.ANIMATE_CART_CLOSED });
  }, 10000);
};

export function showMiniCart() {
  return (dispatch, getState) => {
    const timerId = startCloseTimer(dispatch);
    dispatch({ type: types.LOADING_MINI_CART, timerId });
    const state = getState();
    const requestApi = httpWithLogging(state);
    const smcEnabled = get(state, 'abTestsOpt.simplifiedminicart.variation', 'smc_off') === 'smc_on';
    const smcToggle = get(state, 'toggles.SIMPLIFIED_MINI_CART', false);
    return requestApi.post(NMConfig.API_MINI_CART, { smcEnabled, smcToggle })
      .then((successResponse) => {
        dispatch({
          type: APP_PUT_IN_FOCUS,
          componentInFocus: MINI_CART_CONTAINER_ID,
        });
        dispatch({
          type: types.RESOLVED_MINI_CART,
          payload: isEmpty(successResponse) ? '' : successResponse.data.MiniCartResp.html,
        });
        window.affirm && window.affirm.jsReady() && window.affirm.ui.refresh();
      }).catch(() => {
        dispatch({
          type: APP_PUT_IN_FOCUS,
          componentInFocus: MINI_CART_CONTAINER_ID,
        });
        dispatch({ type: types.REJECTED_MINI_CART });
      });
  };
}

export function showMiniCartV2() {
  return async (dispatch, getState) => {
    const timerId = startCloseTimer(dispatch);
    dispatch({ type: types.LOADING_MINI_CART, timerId });
    const state = getState();
    const requestApi = httpWithLogging(state);
    let token;
    let sub;
    let isValidUser = false;

    try {
      const data = await Auth.currentSession();
      token = data.getIdToken().getJwtToken();
      isValidUser = true;
    } catch (e) {
      await isGuestValid(state?.['env_name']?.env, NMConfig.BRAND_NAME);
    }

    if (!token) {
      const { AccessToken, Sub } = getGuestTokens(['AccessToken', 'Sub']);
      token = AccessToken;
      sub = Sub;
    }

    const userData = filterLocalCognito('userData');

    const webProfileId = isValidUser
      ? filterUserAttributes(userData, 'preferred_username')
      : sub;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return requestApi
      .post(`/dt/api/getCart/${webProfileId}`, {}, {
        headers,
      })
      .then(({ data = {} }) => {
        dispatch({
          type: APP_PUT_IN_FOCUS,
          componentInFocus: MINI_CART_CONTAINER_ID,
        });
        Scroll.animateScroll.scrollToTop();
        dispatch({
          type: types.RESOLVED_MINI_CART,
          payload: data,
        });
        window.affirm && window.affirm.jsReady() && window.affirm.ui.refresh();
      })
      .catch(() => {
        dispatch({
          type: APP_PUT_IN_FOCUS,
          componentInFocus: MINI_CART_CONTAINER_ID,
        });
        dispatch({ type: types.REJECTED_MINI_CART });
      });
  };
}

export function getCartCount() {
  return async (dispatch, getState) => {
    const state = getState();
    let token;
    let sub;
    let isValidUser = false;

    try {
      const data = await Auth.currentSession();
      token = data.getIdToken().getJwtToken();
      isValidUser = true;
    } catch (e) {
      await isGuestValid(state?.['env_name']?.env, NMConfig.BRAND_NAME);
    }

    if (!token) {
      const { AccessToken, Sub } = getGuestTokens(['AccessToken', 'Sub']);
      token = AccessToken;
      sub = Sub;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const userData = filterLocalCognito('userData');

    const webProfileId = isValidUser
      ? filterUserAttributes(userData, 'preferred_username')
      : sub;

    return axios.get(
      `/cart/api/count/${webProfileId}`, { headers }
    ).then(({ data = 0 }) => {
      updateMiniCartItemCount(data)(dispatch);
    }).catch(() => {
      updateMiniCartItemCount(0)(dispatch);
    });
  };
}

export function showInternationalMiniCart() {
  return (dispatch, getState) => {
    const state = getState();
    const timerId = startCloseTimer(dispatch);
    dispatch({ type: types.LOADING_MINI_CART, timerId });
    dispatch({
      type: APP_PUT_IN_FOCUS,
      componentInFocus: MINI_CART_CONTAINER_ID,
    });
    Scroll.animateScroll.scrollToTop();
    const countryCode = get(state, 'locale.countryCode', 'US');
    const currencyCode = get(state, 'locale.currencyCode', 'USD');
    const intlCartName = `internationalCart_${countryCode}_${currencyCode}`;
    dispatch({
      type: types.RESOLVED_MINI_CART,
      payload: internationalMiniCartHtml(intlCartName, countryCode),
    });
  };
}

export function setRecentlyAddedItemCount(recentlyAddedItemCount) {
  return (dispatch) => dispatch({
    type: types.SET_RECENTLY_ADDED_ITEM_COUNT,
    recentlyAddedItemCount,
  });
}
export function updateMiniCartItemCount(noOfItemsInCart) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_MINI_CART_ITEM_COUNT,
      noOfItemsInCart,
    });
    if (IS_CLIENT) {
     window?.minicart?.setCount(noOfItemsInCart);
     const miniCartElement = document?.getElementById('miniCartContainer');
     const updateMiniCartItemCountEvent = new Event(
       'updateMiniCartItemCountEvent',
     );
     updateMiniCartItemCountEvent.detail = { noOfItemsInCart };
     miniCartElement?.dispatchEvent(updateMiniCartItemCountEvent);
   }
 }
}


export const startTimer = () => {
  return (dispatch) => {
    const timerId = startCloseTimer(dispatch);
    dispatch({
      type: types.SET_CLOSE_TIMER,
      timerId,
    });
  };
};

export const startMiniCartCloseAnimation = () => {
  return (dispatch) => dispatch({ type: types.ANIMATE_CART_CLOSED });
};
