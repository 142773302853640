/* eslint import/no-cycle: 0 */
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shouldLoad } from 'universal/http-client';
import { concatTrackTagsObjects, getTrackingTags } from 'cms/components/utils';
import { PRODUCT_GRID } from '../../constants';
import { getCategoryProducts, getCmsProducts } from '../../actions';
import RenderContentItem from '../renderContentItem';
import ProductTile from './ProductTile';
import './ProductTile.scss';

export class ProductGrid extends Component {
  state = {
    numOfDisplayedProducts: 8,
  };

  // eslint-disable-next-line react/sort-comp
  UNSAFE_componentWillMount() {
    const {
      cmsProductsApi, cmsProductGridApi, idsToSearch, contentId, numberOfAssets, promoTiles,
      productsCacheToggle,
    } = this.props;
    if (idsToSearch.length === 1 && shouldLoad(cmsProductsApi)) {
      const numOfProducts = numberOfAssets
        ? parseInt(numberOfAssets, 10) - promoTiles.length
        : PRODUCT_GRID.DEFAULT_NUMBER_OF_PRODUCTS - promoTiles.length;
      this.props.dispatch(getCategoryProducts(idsToSearch, numOfProducts));
    } else if (shouldLoad(cmsProductGridApi)) {
      this.props.dispatch(getCmsProducts(idsToSearch, contentId, productsCacheToggle));
    }
  }

  combineProducts(cmsProducts, childProducts) {
    return cmsProducts.map((product) => (
      /* eslint-disable max-len */
      product.isGroup && product.childProductIds ? childProducts.find((childProduct) => childProduct.id === product.childProductIds[0]) || product : product));
  }

  shiftProductItems(products, promoTileMap) {
    const productsCopy = products.slice();
    Object.keys(promoTileMap).forEach((key) => {
      productsCopy.splice(key, 0, promoTileMap);
    });
    return productsCopy;
  }

  // eslint-disable-next-line react/sort-comp
  loadMoreProducts = () => {
    const { numOfDisplayedProducts } = this.state;
    this.setState({
      numOfDisplayedProducts: numOfDisplayedProducts + 8,
    });
  }

  renderProducts(
    products,
    promoTiles,
    is2x2,
    isMobilePhone,
    trackTags,
    displayOptions
  ) {
    const { numOfDisplayedProducts } = this.state;
    const promoTileMap = keyBy(promoTiles, (promoTile) => promoTile.fields.position - 1);
    const joinedProducts = this.shiftProductItems(products, promoTileMap);
    const productsToDisplay = joinedProducts.map((product, index) => {
      if (index <= numOfDisplayedProducts - 1) {
        if (index in promoTileMap) {
          const { content, trackingTags: ownTrackTags = [] } = promoTileMap[index]?.fields;
          const combinedTrackTags = concatTrackTagsObjects(trackTags, getTrackingTags(ownTrackTags));
          const styles = (is2x2 || isMobilePhone)
            ? classNames('product-thumbnail-list promo-tile grid-50 tablet-grid-50 mobile-grid-50')
            : classNames('product-thumbnail-list promo-tile grid-25 tablet-grid-25 mobile-grid-50');
          return (
            <button className={styles}>
              <RenderContentItem trackTags={combinedTrackTags} cmsContentItem={content} />
            </button>
          );
        }
        return (
          <ProductTile
            id={product.id}
            designer={product.designerName}
            name={product.name}
            price={product.price}
            imageUrl={product.imageUrl}
            url={product.url}
            promotions={product.promotions}
            displayable={product.displayable}
            is2x2={is2x2}
            isMobilePhone={isMobilePhone}
            displayOptions={displayOptions}
          />
        );
      }
      return null;
    });
    return {
      totalProductsNum: joinedProducts.length,
      productsToDisplay,
    };
  }

  render() {
    const {
      is2x2 = false,
      isMobilePhone = false,
      productsByCategory,
      cmsProducts,
      childProducts,
      promoTiles,
      trackTags: propsTrackTags = {},
      displayOptions,
      numberOfAssets = PRODUCT_GRID.DEFAULT_NUMBER_OF_PRODUCTS,
    } = this.props;
    const { numOfDisplayedProducts } = this.state;
    let filteredProducts = [];
    let products = [];
    let productsNum = 0;
    if (!isEmpty(childProducts)) {
      filteredProducts = this.combineProducts(cmsProducts, childProducts);
    }
    const combinedProducts = !isEmpty(filteredProducts) ? filteredProducts : cmsProducts;
    if (productsByCategory && productsByCategory.length) {
      const renderedProducts = this.renderProducts(
        productsByCategory,
        promoTiles,
        is2x2,
        isMobilePhone,
        propsTrackTags,
        displayOptions
      );
      products = renderedProducts.productsToDisplay;
      productsNum = renderedProducts.totalProductsNum;
    } else if (combinedProducts && combinedProducts.length) {
      const renderedProducts = this.renderProducts(
        combinedProducts,
        promoTiles,
        is2x2,
        isMobilePhone,
        propsTrackTags,
        displayOptions
      );
      products = renderedProducts.productsToDisplay;
      productsNum = renderedProducts.totalProductsNum;
    }
    const productsToDisplayNum = productsNum < numberOfAssets ? productsNum : numberOfAssets;

    return (
      <div className="product-grid">
        {products.length && products}
        {(numOfDisplayedProducts < productsToDisplayNum) && (
          <button
            className="load-more"
            onClick={this.loadMoreProducts}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
  cmsProductsApi: state.api[`cms_currated_products_${ownProps.idsToSearch}`.toLowerCase()],
  productsByCategory: state.cms.productsByCategory?.[ownProps.idsToSearch],
  cmsProductGridApi: state.api[`cms_products_${ownProps.contentId}`.toLowerCase()],
  cmsProducts: state.cms.products?.[ownProps.contentId],
  childProducts: state.cms.childProducts?.[ownProps.contentId],
  productsCacheToggle: get(state, 'toggles.CF_CACHE_PRDS', false),
});

export default connect(mapStateToProps)(ProductGrid);
