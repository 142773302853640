/* eslint-disable import/order */
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import reject from 'lodash/reject';
import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import join from 'lodash/join';
import { X_ABTEST_INFO, X_DEVICE_TYPE, X_FEATURE_TOGGLES_HEADER_NAME } from 'universal/custom-http-headers';
import httpWithLogging from 'universal/http-client';
import { buildCookieString } from 'universal/utilities-cookies';
import { ECMSlots } from 'ecm/constants';
import { stringify } from 'query-string';
import { setPlaceholderValue, setSearchTermToEmpty } from 'storefront/components/Header/Search/search-actions';
import { showSpinner, hideSpinner } from 'shared/components/Spinner/spinner-actions';
import { getABTestAssignments, ABTEST_GET_IT_FAST } from 'shared/actions/actions-page';
import { UTAG_VALUES, SRC_VALUE_FOR_UTAG, GEO_LOCATION_SERVICE_ERROR } from 'srp/constants';
import includes from 'lodash/includes';
import { INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE, IN_STORE, FAST_DELIVERY, FREE_PICKUP } from 'plp/constants';
import { NO_RESULTS_FOR_FILTER } from 'plp/components/ProductListPage/actions';
import { openModal } from 'shared/components/Modal/actions';
import { isEmptyInStoreFilterValue } from 'plpFilters/components/InStoreFilter/inStoreFilter';
import { RESOLVED_SRP_ECM_CONTENT } from 'client/ecm/actions/actions-ecmcontent';
import { getCloudinaryImageWithFixedWidth } from 'client/utilities/utilities-cloudinary';

export const LOADING_SEARCH_LIST = 'LOADING_SEARCH_LIST';
export const RESOLVED_SEARCH_LIST = 'RESOLVED_SEARCH_LIST';
export const REJECTED_SEARCH_LIST = 'REJECTED_SEARCH_LIST';
export const SET_SEARCH_LIST_DATA_TO_UTAG = 'SET_SEARCH_LIST_DATA_TO_UTAG';
export const SET_SEARCH_CATEGORY_CRUMB = 'SET_SEARCH_CATEGORY_CRUMB';
export const LOADING_NULL_SEARCH_LIST = 'LOADING_NULL_SEARCH_LIST';
export const RESOLVED_NULL_SEARCH_LIST = 'RESOLVED_NULL_SEARCH_LIST';
export const REJECTED_NULL_SEARCH_LIST = 'REJECTED_NULL_SEARCH_LIST';
export const LOADING_NULL_SEARCH_FOOTER = 'LOADING_NULL_SEARCH_FOOTER';
export const RESOLVED_NULL_SEARCH_FOOTER = 'RESOLVED_NULL_SEARCH_FOOTER';
export const REJECTED_NULL_SEARCH_FOOTER = 'REJECTED_NULL_SEARCH_FOOTER';
export const LOADING_SEARCH_GRAPHIC_HEADER = 'LOADING_SEARCH_GRAPHIC_HEADER';
export const RESOLVED_SEARCH_GRAPHIC_HEADER = 'RESOLVED_SEARCH_GRAPHIC_HEADER';
export const REJECTED_SEARCH_GRAPHIC_HEADER = 'REJECTED_SEARCH_GRAPHIC_HEADER';
export const SET_LEFT_NAV_ANALYTICS_FLAG = 'SET_LEFT_NAV_ANALYTICS_FLAG';
export const CLEAR_QR_FROM_UTAG = 'CLEAR_QR_FROM_UTAG';
export const SELECT_FILTER_OPTION_SRP = 'SELECT_FILTER_OPTION_SRP';
export const CLEAR_ALL_FILTER_OPTION = 'CLEAR_ALL_FILTER_OPTION';
export const SELECT_FILTER_BY_ROUTE_PARAM_SRP = 'SELECT_FILTER_BY_ROUTE_PARAM_SRP';
export const LOADING_TOP_PRODUCTS = 'LOADING_TOP_PRODUCTS';
export const RESOLVED_TOP_PRODUCTS = 'RESOLVED_TOP_PRODUCTS';
export const REJECTED_TOP_PRODUCTS = 'REJECTED_TOP_PRODUCTS';
export const TYPEAHEAD_IMAGE_WIDTH = 155;
export const SET_GENDER_TA = 'SET_GENDER_TA';

export const types = {
  SET_SRP_PAGE: 'SET_SRP_PAGE',
  SET_SRP_SORT_BY: 'SET_SRP_SORT_BY',
  SET_SRP_FILTER_OPTIONS: 'SET_SRP_FILTER_OPTIONS',
};

export function setPlaceholderToTextBoxValueAndClearTextBox(searchTerm) {
  return (dispatch) => {
    setPlaceholderValue(searchTerm)(dispatch);
    setSearchTermToEmpty()(dispatch);
  };
}

export function clearQRFromUtag() {
  return (dispatch) => {
    dispatch({ type: CLEAR_QR_FROM_UTAG });
  };
}

export function updateTopProducts(body, cacheKey) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_TOP_PRODUCTS });
    const state = getState();
    const requestApi = httpWithLogging(state);
    const dprToggle = state?.toggles?.CLOUDINARY_DPR || false;
    return requestApi
      .post(`${NMConfig.API_TOP_PRODUCTS}?cacheKey=${cacheKey}`, body)
      .then(((response) => {
        let products = response.data.products
          .reduce((filtered, product) => {
            if (product.displayable === true) {
              const nextProduct = {
                id: product.id,
                designer: product.designer,
                main: getCloudinaryImageWithFixedWidth(product.main, TYPEAHEAD_IMAGE_WIDTH, '', dprToggle),
                price: product.price,
                url: product.details.canonicalUrl,
              };
              filtered.push(nextProduct);
            }
            return filtered;
          }, []);
        if (isEmpty(products)) {
          products = ['NO PRODUCTS AVAILABLE'];
        }
        dispatch({
          type: RESOLVED_TOP_PRODUCTS,
          payload: products,
        });
      }))
      .catch(() => {
        dispatch({
          type: REJECTED_TOP_PRODUCTS,
          payload: ['NO PRODUCTS AVAILABLE'],
        });
      });
  };
}

export const setGenderTA = (gender, withUtagAnalytics = false) => {
  return {
    type: SET_GENDER_TA,
    payload: { gender, withUtagAnalytics },
  };
};


const traverseCategories = (nav = []) => {
  let activeCategoryCrumb = '';
  if (nav.length === 1) {
    if (nav[0].categories) {
      if (nav[0].categories.length === 1) {
        activeCategoryCrumb = traverseCategories(nav[0].categories);
      } else {
        activeCategoryCrumb = nav[0].crumb;
      }
    } else {
      activeCategoryCrumb = nav[0].crumb;
    }
  }
  return activeCategoryCrumb;
};

export const getActiveCategory = (filterOptions = {}, leftNav = []) => {
  let activeCategoryCrumb;
  const existingFilters = isEmpty(filterOptions)
    ? {}
    : JSON.parse(filterOptions);
  const { Category: existingCategoryFilter = [] } = existingFilters;
  if (isEmpty(existingCategoryFilter)) {
    if (leftNav.length === 1) {
      if (leftNav[0].categories) {
        if (leftNav[0].categories.length === 1) {
          activeCategoryCrumb = traverseCategories(leftNav[0].categories);
        } else {
          activeCategoryCrumb = leftNav[0].crumb;
        }
      } else {
        activeCategoryCrumb = leftNav[0].crumb;
      }
    } else {
      activeCategoryCrumb = '';
    }
  } else {
    activeCategoryCrumb = `/${existingCategoryFilter.join('/')}`;
  }
  return activeCategoryCrumb;
};

export function getSearchOptions(requestOptions = {}) {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(getSearchResults(requestOptions))
      .then(resolve)
      .catch((err) => {
        if (err.type === NO_RESULTS_FOR_FILTER) {
          dispatch(
            openModal({
              type: 'ModalWithoutHeader',
              message: err.message,
              className: 'modal-without-header',
            })
          );
        } else if (err.type === GEO_LOCATION_SERVICE_ERROR) {
          dispatch(openModal({ type: 'ProductListErrorModal' }));
        }
        reject();
      });
  });
}

export function setLeftNavAnalyticsFlag(value) {
  return (dispatch) => {
    dispatch({ type: SET_LEFT_NAV_ANALYTICS_FLAG, payload: value });
  };
}

export function getABTestAndSearchResults(requestOptions = {}, MBOX_IDS) {
  return (dispatch) => dispatch(getABTestAssignments(MBOX_IDS.join(','), true))
    .then(() => {
      dispatch(getSearchResults(requestOptions));
    })
    .catch(() => {
      dispatch(getSearchResults(requestOptions));
    });
}

export function getSearchResults(requestOptions = {}) {
  return (dispatch, getState) => {
    dispatch(showSpinner());
    dispatch({ type: LOADING_SEARCH_LIST });
    const state = getState();
    const currentPage = get(state, 'srp.search.currentPage', 1);
    const { leftNavAnalyticsFlag } = state.srp ? state.srp.search : false;
    const isSlsSrpToggleOn = get(state, 'toggles.SRP_SLS', false);
    const brand = get(state, 'brand_name.env', 'NM');

    const {
      page = currentPage,
      sortBy = '',
      filterOptions,
      keyword,
      src = '',
    } = requestOptions;
    const fetchSize = getFetchSize(state);
    const { session } = state;
    const context = state.api && state.api.requestContext;
    let deviceType;
    if (
      get(session, 'deviceType.isMobile', false)
      && !get(session, 'deviceType.isTablet', false)
    ) {
      deviceType = 'M';
    } else if (get(session, 'deviceType.isTablet', false)) {
      deviceType = 'T';
    } else {
      deviceType = 'D';
    }
    const headersWithoutUCID = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        TLTSID: get(session, 'TLTSID', ''),
        AGA: get(session, 'AGA', ''),
      }),
      url: get(state.session, 'url', '').split('?')[0],
      br_uid: get(context, '_br_uid_2', ''),
      securityStatus: get(state.user, 'securityStatus', ''),
      [X_ABTEST_INFO]: join(
        get(state, 'abTests.responses', []).map(
          (i) => `${i.mboxId}:${get(i, 'assignment.value.experienceId', '')}`
        ),
        '~'
      ),
      [X_DEVICE_TYPE]: deviceType,
      gender: '',
    };
    const userId = get(state.user, 'ucid', '');
    const headers = userId
      ? { ...headersWithoutUCID, user_id: userId }
      : headersWithoutUCID;
    const isBSI = get(state, 'toggles.SRP_SLS', false);
    if (isBSI) {
      headers[X_FEATURE_TOGGLES_HEADER_NAME] = JSON.stringify({
        USE_CLOUDINARY_IMAGES: true,
        PLP_SRP_CUS_SEG: get(state, 'toggles.PLP_SRP_CUS_SEG', false),
        SYSFACET_POSITION: get(state, 'toggles.SYSFACET_POSITION', false),
        SRP_GRAPHIC_HEADER: get(state, 'toggles.SRP_GRAPHIC_HEADER', false),
        SRP_AUTO_CORRECT: get(state, 'toggles.SRP_AUTO_CORRECT', false),
        SRP_QUERY_RELAXATION: get(state, 'toggles.SRP_QUERY_RELAXATION', false),
        DISABLE_FAVORITES_SEARCH: get(state, 'toggles.DISABLE_FAVORITES_SEARCH', false),
        SRP_BOTTOM_BANNER: get(state, 'toggles.SRP_BOTTOM_BANNER', false),
      });
    }
    const SRP_SVC_API_TIMEOUT = get(
      state,
      'apiTimeouts.SRP_SVC_API_TIMEOUT',
      5000
    );
    const requestApi = httpWithLogging(state, SRP_SVC_API_TIMEOUT);
    function getSortByParams(sortBy) {
      const sortByParams = sortBy ? `&sortBy=${escape(sortBy)}` : '';
      return sortByParams;
    }
    const sortByParams = getSortByParams(sortBy);
    const getUtagFiltersData = (filterOptions) => {
      const inStoreFilter = 'In Store';
      const getItFastSplitFacetToggle= get(state, `abTestsOpt.${ABTEST_GET_IT_FAST}.variation`, 'a') === 'b';

      const renameKeyForUtagFilterAnalysis = (key, omniArray) => {
        if (key === 'In Store') {
          if (getItFastSplitFacetToggle) {
            if (!isEmpty(omniArray[2]) || !isEmpty(omniArray[3])) {
              return FAST_DELIVERY;
            }
            if (!isEmpty(omniArray[1])) {
              return FREE_PICKUP;
            }
            return '';
          }
          return 'Get It Fast';
        }
        return key;
      };

      const filterOmniSelection = (omniArray) => {
        const sddSelection = getItFastSplitFacetToggle ? "Today" : 'delivery today';
        const nddSelection = getItFastSplitFacetToggle ? "Tomorrow" : 'delivery tomorrow';
        const cspSelection = getItFastSplitFacetToggle ? "Today" : 'curbside today';

        if (!isEmpty(omniArray[2]) && !isEmpty(omniArray[3])) {
          return [sddSelection, nddSelection];
        }
        if (!isEmpty(omniArray[2])) {
          return [sddSelection];
        }
        if (!isEmpty(omniArray[3])) {
          return [nddSelection];
        }
        if (!isEmpty(omniArray[1])) {
          return [cspSelection];
        }
        return [];
      };

      const filterOptionsWithoutInstoreZipCode = {
        ...filterOptions,
        [inStoreFilter]: filterOmniSelection(
          get(filterOptions, inStoreFilter, [])
        ),
      };

      return {
        filterSelection: flatten(
          reject(filterOptionsWithoutInstoreZipCode, isEmpty)
        ),
        filterType: flatMap(
          Object.keys(filterOptionsWithoutInstoreZipCode),
          (key) => Array(filterOptionsWithoutInstoreZipCode[key].length).fill(
            renameKeyForUtagFilterAnalysis(key, get(filterOptions, inStoreFilter, []))
          )
        ),
      };
    };

    function isSortOptionChanged(sortBy) {
      const previousSortOption = get(state, 'srp.selectedSortBy');
      return (
        !isEmpty(sortBy)
        && !isEmpty(previousSortOption)
        && previousSortOption !== sortBy
      );
    }

    function isFilterOptionsChanged(selectedFilters) {
      const previousFilterOptions = get(state, 'srp.selectedFilterOptions');
      return (
        !isEmpty(selectedFilters)
        && !isEmpty(previousFilterOptions)
        && !isEqual(previousFilterOptions, selectedFilters)
      );
    }

    function shouldShowInStoreFilterMessage(data) {
      return (
        isEmpty(data.products)
        && !isEmpty(filterOptions)
        && includes(filterOptions, IN_STORE)
        && !isEmptyInStoreFilterValue(JSON.parse(filterOptions)[IN_STORE])
        && !data.geoLocationServiceError
      );
    }

    function shouldShowInStoreErrorModal(data) {
      return (
        isEmpty(data.products)
        && !isEmpty(filterOptions)
        && includes(filterOptions, IN_STORE)
        && !isEmptyInStoreFilterValue(JSON.parse(filterOptions)[IN_STORE])
        && data.geoLocationServiceError
      );
    }

    function getFetchSize(state) {
      const lazyLoadToggle = get(state, 'toggles.SRP_IMAGE_LAZY_LOAD', false);
      if (!lazyLoadToggle) {
        return get(state, 'lazyLoadDisabledConfig.pagination.fetchSize', 60);
      }
      const isMobileAndNotTablet = get(state, 'session.deviceType.isMobile', false)
        && !get(state, 'session.deviceType.isTablet', false);
      const load120Toggle = get(state, 'toggles.SRP_120', false);
      if (load120Toggle && !isMobileAndNotTablet) {
        return 120;
      }
      const fetchSizeFromConfig = get(
        state,
        'mobileConfig.pagination.fetchSize',
        null
      );

      if (isMobileAndNotTablet) {
        return fetchSizeFromConfig;
      }
      return null;
    }

    function setQueryRelaxData(
      successResponse = {},
      requestOptions = {},
      IS_CLIENT
    ) {
      const data = successResponse.data;
      const { page, sortBy, filterOptions } = requestOptions;
      let payload = '';

      if (data.auto) {
        payload = 'step2';
      } else if (data.rlx) {
        payload = 'step3';
      }

      if (page > 1 || sortBy !== '' || filterOptions || IS_CLIENT) {
        payload = '';
      }

      return payload;
    }
    function searchServicePromise(isSlsSrpToggleOn, href, headers) {
      if (isSlsSrpToggleOn) {
        return requestApi.post(href, {}, {
          headers: {
            ...headers,
            'Content-Type': 'application/json',
            'sls-call': 'true',
          },
        });
      }
      return requestApi.get(href, { headers });
    }

    let ecmPreviewParam = '';
    if (context) {
      ecmPreviewParam = isEmpty(context.ECMPreview)
        ? ''
        : `&ECMPreview=${context.ECMPreview}`;
    }

    return new Promise((resolve, reject) => {
      let params = { filterOptions, keyword, page };
      if (fetchSize != null) {
        params = { ...params, fetchSize };
      }

      const apiSearchBaseUrl = isSlsSrpToggleOn
        ? NMConfig.API_SLS_SEARCH
        : NMConfig.API_SEARCH;

      let href = `${apiSearchBaseUrl}/keywordsearch?${stringify(
        params
      )}${sortByParams}${ecmPreviewParam}`;

      href = `${apiSearchBaseUrl}/keywordsearch?${stringify(
        params
      )}${sortByParams}${ecmPreviewParam}&visualNav=true`;

      const delay = (ms) => new Promise((res) => setTimeout(res, ms));

      const selectedFilters = isEmpty(filterOptions)
        ? {}
        : JSON.parse(filterOptions);

      searchServicePromise(isSlsSrpToggleOn, href, headers)
        .then(async (successResponse) => {
          if (shouldShowInStoreErrorModal(successResponse.data)) {
            dispatch(setLeftNavAnalyticsFlag(false));
            return Promise.reject({ type: GEO_LOCATION_SERVICE_ERROR });
          }
          if (shouldShowInStoreFilterMessage(successResponse.data)) {
            dispatch(setLeftNavAnalyticsFlag(false));
            return Promise.reject({
              type: NO_RESULTS_FOR_FILTER,
              message: INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE,
            });
          }
          dispatch({
            type: RESOLVED_SEARCH_LIST,
            payload: successResponse.data,
            params: requestOptions,
            href,
          });
          const { ecmContent } = successResponse.data;
          if (!isEmpty(ecmContent)) {
            const { promotiles, categoryHeader, bottomBanner } = ecmContent;
            if (!isEmpty(promotiles)) {
              const { promotile } = promotiles;
              if (!isEmpty(promotile)) {
                const srpEmcContent = promotile.reduce((acc, curObj, index) => {
                  const objectIndex = `srppromotile${index}`;
                  dispatch({
                    type: `RESOLVED_ECM${
                      ECMSlots[ECMSlots.SRP_PROMO_TILES.slots[index]].contentId
                    }`,
                    payload: curObj,
                    contentId: `${
                      ECMSlots[ECMSlots.SRP_PROMO_TILES.slots[index]].contentId
                    }`,
                    headers,
                  });
                  acc[objectIndex] = curObj;
                  return acc;
                }, {});
                dispatch({
                  type: RESOLVED_SRP_ECM_CONTENT,
                  payload: {
                    srpPromoTileSize: Object.keys(srpEmcContent).length,
                  },
                });
              }
            }
            if (!isEmpty(categoryHeader)) {
              const {
                SRP_BANNER: { contentId },
              } = ECMSlots;
              dispatch({
                type: `RESOLVED_ECM${contentId}`,
                payload: categoryHeader,
                contentId,
                headers,
              });
            }
            if (!isEmpty(bottomBanner)) {
              const {
                SRP_BOTTOM_BANNER: { contentId },
              } = ECMSlots;
              dispatch({
                type: `RESOLVED_ECM${contentId}`,
                payload: bottomBanner,
                contentId,
                headers,
              });
            }
          }
          dispatch({ type: types.SET_SRP_PAGE, page });
          const previousViewFilterValue = leftNavAnalyticsFlag
            ? false
            : isFilterOptionsChanged(selectedFilters);

          dispatch({
            type: SET_SEARCH_LIST_DATA_TO_UTAG,
            payload: {
              page_definition_id: brand === 'HC' ? 'ElasticSearch' : UTAG_VALUES.PAGE_DEFINITION_ID,
              page_name: UTAG_VALUES.PAGE_NAME,
              page_type: UTAG_VALUES.PAGE_TYPE,
              page_version: 'test',
              search_type: brand === 'HC' ? 'ElasticSearch' : UTAG_VALUES.SEARCH_TYPE,
              total: successResponse.data.total,
              sortBy: successResponse.data.selectedSortOption,
              page,
              filterOptions: { filterSelection: [], filterType: [] },
              page_template: UTAG_VALUES.PAGE_TEMPLATE,
              previousViewFilterSubmit: false,
              previousViewSortSubmit: isSortOptionChanged(sortBy),
              internal_search_term: IS_CLIENT ? '' : keyword,
              previousViewSearchSubmit: IS_CLIENT ? '' : true,
              productsMetadata: map(successResponse.data.products, 'metadata'),
              search_type_ahead: IS_CLIENT ? false : src === SRC_VALUE_FOR_UTAG,
              query_relax: setQueryRelaxData(
                successResponse,
                requestOptions,
                IS_CLIENT
              ),
              facetOptions: getUtagFiltersData(selectedFilters),
              previousViewFacetSubmit: previousViewFilterValue,
              searchFunction: [undefined],
              recently_searched: get(getState(), 'page.location.query.recent', 'false'),
            },
          });
          dispatch({
            type: types.SET_SRP_FILTER_OPTIONS,
            selectedFilterOptions: isEmpty(filterOptions)
              ? 'No filter options selected'
              : JSON.parse(filterOptions),
          });
          dispatch({
            type: types.SET_SRP_SORT_BY,
            sortBy: successResponse.data.selectedSortOption,
          });
          const categoryCrumb = getActiveCategory(
            filterOptions,
            successResponse.data.leftNav
          );
          dispatch({ type: SET_SEARCH_CATEGORY_CRUMB, payload: categoryCrumb });
          dispatch(setLeftNavAnalyticsFlag(false));
          dispatch(hideSpinner());
          return resolve();
        })
        .catch((err) => {
          if (
            err
            && err.type
            && err.type !== GEO_LOCATION_SERVICE_ERROR
            && err.type !== NO_RESULTS_FOR_FILTER
          ) {
            dispatch({
              type: REJECTED_SEARCH_LIST,
              payload: {},
              params: requestOptions,
              href,
            });
          }
          dispatch(setLeftNavAnalyticsFlag(false));
          dispatch(hideSpinner());
          reject(err);
        });
    });
  };
}

export function getRecommendationsForNullSearchPage(keyword = '') {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_NULL_SEARCH_LIST });
    const state = getState();
    const RECOMMENDATION_API_TIMEOUT = get(
      state,
      'apiTimeouts.RECOMMENDATION_API_TIMEOUT'
    );
    const { user = {} } = state;
    const headers = {
      Cookie: buildCookieString({
        rid: get(user, 'rid', 'US'),
      }),
      PlacementType: 'nullsearch',
    };
    const requestApi = httpWithLogging(state, RECOMMENDATION_API_TIMEOUT);
    return requestApi
      .get(`${NMConfig.API_RECOMMENDATIONS}?searchTerm=${keyword}`, { headers })
      .then((successResponse) => dispatch({
        type: RESOLVED_NULL_SEARCH_LIST,
        payload: successResponse.data,
      }))
      .catch(() => dispatch({ type: REJECTED_NULL_SEARCH_LIST }));
  };
}
