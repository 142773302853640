import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { MyAccountLeftNavFavIcon } from './MyAccountLeftNavSvgs';
import { normalizeString } from '../OrderStatusLineItem/orderStatusUtils';
import './MyAccountLeftNav.scss';

const MyAccountLeftNav = ({
  name, active, orderHistoryToggle, specialOffersToggle,myStylePrefToggle
}) => {
  return (
    <div className="nav-links">
      <ul className="nav">
        {name ? (
          <li>
            <span className="myGlobalSignedIn" tabIndex="0">
              {'Hello '}
              {normalizeString(name)}
              &nbsp;
            </span>
          </li>
        ) : (
          <li>
            <a href="/login">
              <span className="myGlobalSignedIn" tabIndex="0">
                Sign In / Register
              </span>
            </a>
          </li>
        )}
        <li className="myfavorites-border">
          <a className="changeOver myBrand" href="/myNM.jsp?itemId=mynm">MyNM Homepage</a>
        </li>
        <li className="myFav">
          <div id="myNMFavorites" tabIndex="0">
            <span>My Favorites</span>
            &nbsp;
            {MyAccountLeftNavFavIcon}
          </div>
        </li>
        <li className="myFav">
          <a
            id="myFavNavFI"
            className={active === 'myFavNavFI' ? 'active' : 'changeOver'}
            href="/myfavorites/myFavoriteItems.jsp?view=favItems&forOmniLN=1&favin=1"
          >
            Favorite Items
          </a>
        </li>
        {myStylePrefToggle ? (
          <li className="myFav">
            <a id="myFavNavMSP" href="/my/preferences/stylepreferences" className={active === 'myFavNavMSP' ? 'active' : 'changeOver'}>My Style Preferences</a>
          </li>
        ) :
          (<li className="myFav">
            <a id="myFavNavFD" className="changeOver" href="/myfavorites/myFavoriteDesigners.jsp?view=favd&forOmniLN=1">Favorite Designers</a>
          </li>)}
        <li className="myFavStore">
          <a id="myFavNavFS" className="changeOver" href="/myfavorites/myFavorites.jsp?view=favs&forOmniLN=1">Favorite Store</a>
        </li>
        {!myStylePrefToggle && (<li className="myFavSizes myfavorites-border">
          <a id="myFavNavFSI" className="changeOver" href="/myfavorites/myFavoritesSizes.jsp?view=favsizes">Favorite Sizes</a>
        </li>)}
        <li className="myFav">
          <div id="myNMFavAcct" tabIndex="0">
            <span>My Account</span>
          </div>
        </li>
        <li className="myFav">
          <a
            id="myAcctNavAO"
            className={active === 'myAcctNavAO' ? 'active' : 'changeOver'}
            href={active === 'myAcctNavAO' ? '/accountoverview' : `${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav`}
          >
            Account Overview
          </a>
        </li>
        <li className="myFav">
          <a
            id="myAcctNavOH"
            className={active === 'myAcctNavOH' ? 'active' : 'changeOver'}
            href={active === 'myAcctNavOH' || orderHistoryToggle ? '/order-history' : `${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav&view=history&list=default&forOmniLN=1`}
          >
            Order History
          </a>
        </li>
        <li className="myFav">
          <a id="myAcctNavAB" className="changeOver" href={`${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav&view=address&forOmniLN=1`}>Address Book</a>
        </li>
        <li className="myFav">
          <a id="myAcctNavPI" className="changeOver" href={`${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav&view=pay&forOmniLN=1`}>Payment Information</a>
        </li>
        {
          specialOffersToggle
          && (
          <li className="myFav">
            <a id="myNMSpecialOffers" className={active === 'myNMSpecialOffers' ? 'active' : 'changeOver'} href="/specialoffers">Special Offers</a>
          </li>
          )
        }
        <li className="myFav">
          <a id="myNMCreditCard" className="changeOver" href="/c/NM/NM-Credit-Card-cat18480743">NM Credit Card</a>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orderHistoryToggle: get(state, 'toggles.DT_ORDER_HISTORY', false),
    specialOffersToggle: get(state, 'toggles.WKY_LINK_TO_SPECIAL_OFFERS_PAGE', false),
    myStylePrefToggle: get(state, 'toggles.MY_STYLE_PREFERENCES', false)
  };
};

export default connect(mapStateToProps)(MyAccountLeftNav);
