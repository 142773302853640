import React from 'react';
import { unescape } from 'he';

const DesignerFilterSelections = ({ selectedValues, facets, onCurrentSelectionCancelClick }) => {
  const currentSelections = selectedValues
    .filter((option, idx) => idx < 5 && facets[option])
    .map((option, index) => {
      const filterOption = unescape(option);

      return (
        <div className="selected-filter-option" key={index}>
          <button
            aria-label={`Remove ${option} from current selection`}
            className="cancel-button hide-on-mobile"
            onClick={() => onCurrentSelectionCancelClick(option)}
          >
            &times;
          </button>
          <span className="selected-filter-name hide-on-mobile">
            {`${filterOption} (${facets[option]})`}
          </span>
          <div
            className="hide-on-desktop hide-on-tablet"
            key={index}
            onClick={(event) => {
              event.preventDefault();
              onCurrentSelectionCancelClick(option);
            }}
            onKeyDown={(event) => {
              if (event.keyCode === 13 || event.keyCode === 32) {
                event.preventDefault();
                onCurrentSelectionCancelClick(option);
              }
            }}
            tabIndex="0"
            role="checkbox"
            aria-checked
          >
            <input checked id={index} readOnly type="checkbox" />
            <label htmlFor={index}>
              <span className="facet-label-text">
                {filterOption}
                {' '}
                <i>
                  {'('}
                  {facets[option]}
                  {')'}
                </i>
              </span>
            </label>
          </div>
        </div>
      );
    });
  if (selectedValues.length > 5) {
    currentSelections.push(<span className="more-button" key="more-button">More...</span>);
  }
  return <div className="selected-filter-container">{currentSelections}</div>;
};

export default DesignerFilterSelections;
