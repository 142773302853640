import React, { useState } from 'react';
import axios from 'axios';
import Auth from '@aws-amplify/auth';
import MyAccountLeftNav from 'profile/components/MyAccountLeftNav/MyAccountLeftNav';
import NoFavorites from './NoFavorites';
import FavoriteItem from './FavoriteItem/FavoriteItem';
import { getAuthApiData, configureAmplify, getFastlyHost } from '../../../utilities/amplifyUtils';
import { getGuestTokens } from '@nmg/auth';
import './MyFavorites.scss';

export const mapProductIds = (products) => products.map(({ id }) => id);

const calculatePreviousPrice = (adornments, retailPrice) => {
  if (adornments) {
    const original = adornments.find((p) => p.label === 'Original')?.price;
    return original === retailPrice ? null : original;
  }
  return null;
};

export const mapFavoriteItems = (details, products) => {
  const {
    id,
    title,
    designerName,
    price: { retailPrice, adornments },
    media: {
      main: {
        medium: {
          url,
        },
      },
    },
  } = details;

  const previousPrice = calculatePreviousPrice(adornments, retailPrice);

  const { ts } = products.find((val) => val.id === id);
  return {
    title,
    ts,
    designerName,
    retailPrice,
    previousPrice,
    url,
    id,
  };
};

export const sortItems = (products, criteria) => {
  const sortingMap = {
    newest: (a, b) => +b.ts - +a.ts,
    lowtohigh: (a, b) => +a.retailPrice - +b.retailPrice,
    hightolow: (a, b) => +b.retailPrice - +a.retailPrice,
  };

  return products.sort(sortingMap[criteria]);
};

export const getRequestParams = async (isGuest) => {
  try {
    const { Sub: guestId } = getGuestTokens(['Sub']);
    const reqParams = {
      lambdaHost: window.sessionStorage.getItem('lambdaHost'),
      fastlyHost: getFastlyHost(),
      id: guestId,
      headers: {},
    };
    if (isGuest) return reqParams;
    const { headers, profileId } = await getAuthApiData();
    reqParams.id = profileId;
    reqParams.headers = headers;
    return reqParams;
  } catch (err) {
    return err;
  }
};

const fetchProductDetails = (products) => (
  axios.get(`/dt/api/composite/minifiedproductdetails?productIds=${
    mapProductIds(products).join(',')
  }`)
);

export const getFavorites = async (session, setFavoriteItems, setLoading, isGuest) => {
  try {
    const { fastlyHost, id, headers } = await getRequestParams(isGuest, session);
    const url = `${fastlyHost}/uca-favorites/v1/${isGuest ? 'guests' : 'accounts'}/${id}/products`;
    const { data: { products } } = await axios.get(url, { headers }).catch(() => setLoading(false));
    let productCalls;
    if (products?.length) {
      productCalls = products.reduce((acc, curr, i) => {
        const arrIndex = Math.floor(i / 10);
        const isNewArr = i % 10 === 0;
        if (isNewArr) {
          acc[arrIndex] = [curr];
        } else {
          acc[arrIndex].push(curr);
        }
        return acc;
      }, []);

      const productDetails = await Promise.all(productCalls.map(fetchProductDetails));
      const combinedProductDetails = productDetails.reduce(
        (acc, { data: { minifiedProductInfo } }) => {
          return [...acc, ...minifiedProductInfo];
        }, []
      );
      const mappedProductDetails = combinedProductDetails.map(
        (details) => mapFavoriteItems(details, products)
      );
      setFavoriteItems(mappedProductDetails);
      return mappedProductDetails;
    }
    return products;
  } catch (e) {
    return e;
  } finally {
    setLoading(false);
  }
};

const MyFavorites = ({
  name,
  session,
}) => {
  const [favoriteItems, setFavoriteItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortCriteria, setSortCriteria] = useState('newest');
  const [isGuest, setIsGuest] = useState(true);

  React.useEffect(() => {
    configureAmplify(() => {
      Auth.currentAuthenticatedUser({})
        .then(() => {
          getFavorites(session, setFavoriteItems, setLoading);
          setIsGuest(false);
        })
        .catch(() => {
          getFavorites(session, setFavoriteItems, setLoading, true);
          setIsGuest(true);
        });
    });
  }, []);

  const productOrNoFavoriteTemplate = favoriteItems.length ? (
    <>
      <div className="my_favorites__sort">
        <h1>SORT BY:</h1>
        <select onChange={(e) => setSortCriteria(e.target.value)}>
          <option value="newest">Newest First</option>
          <option value="lowtohigh">Price: Low to High</option>
          <option value="hightolow">Price: High to Low</option>
        </select>
        <h1>
          {favoriteItems.length}
          {' '}
items
        </h1>
      </div>
      <div className="my_favorites__items">
        {
          sortItems(favoriteItems, sortCriteria)
            .map((item) => (
              <FavoriteItem
                key={item.id}
                item={item}
                isGuest={isGuest}
                getRequestParams={getRequestParams}
                session={session}
              />
            ))
        }
      </div>
    </>
  ) : <NoFavorites />;

  return (
    <div className="grid-container full-width-container">
      <div className="grid-parent">
        <div id="myfavorites" className="my-account-left-nav grid-20 tablet-grid-20 hide-on-mobile">
          <MyAccountLeftNav
            name={name}
            active="myFavNavFI"
          />
        </div>
      </div>
      <div className="my_favorites__container">
        <span className="top_label">
          Favorite Items
        </span>
        {loading ? 'Loading...' : productOrNoFavoriteTemplate}
      </div>
    </div>
  );
};

export default MyFavorites;
