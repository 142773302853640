export const X_FEATURE_TOGGLES_HEADER_NAME = 'x-feature-toggles';
export const X_ABTEST_INFO = 'x-abt';
export const X_DEVICE_TYPE = 'x-device';
export const BR_SEGMENTS = 'brsegments';
export const X_CACHE = 'x-cache';
export const AGE = 'age';
export const ABTEST_OPT_ANALYTICS = 'abTestOptAnalytics';
export const VISITORID = 'visitorid';
export const BREAD_CRUMB_PATH = 'breadcrumbpath';
export const USERID = 'userid';
