export const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESOLVED_NM_RECENTLY_VIEWED_PRODUCTS':
      return action.payload;
    case 'REJECTED_NM_RECENTLY_VIEWED_PRODUCTS':
      return defaultState;
    default:
      return state;
  }
};
