import React from 'react';
import './CustomArrow.scss';

const CustomArrow = (props) => {
  const {
    className, arrowTop, onClick, style, ariaLabel
  } = props;

  let newStyle = {
    ...style,
  };

  if (arrowTop) {
    newStyle = {
      ...style,
      top: `${arrowTop}px`,
    };
  }

  return (
    <button className={className} onClick={onClick} style={newStyle} aria-label={ariaLabel} role={"button"}/>
  );
};

export default CustomArrow;
