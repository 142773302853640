/* eslint import/no-cycle: 0 */
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import getUrl from 'url';
import get from 'lodash/get';
import { concatTrackTagsObjects, getTrackingTags } from 'cms/components/utils';
import { keysToExclude } from 'client-utils/utilities-router';
import RichText from '../richText/richText';
import ImageAsset from '../image/imageAsset';
import IWTHelper from './imageWithTextHelper';
import './imageWithText.scss';


export function DumbImageWithText(props) {
  const contentItemsFields = get(props.cmsContentItem, 'fields', {});
  const contentIdImage = get(contentItemsFields.image, 'sys.id');
  const contentIdText = get(contentItemsFields.text, 'sys.id');
  const ownTrackTags = get(contentItemsFields, 'trackingTags', []);
  const propsTrackTags = get(props, 'trackTags', {});
  const trackTags = concatTrackTagsObjects(propsTrackTags, getTrackingTags(ownTrackTags));
  const linkTo = contentItemsFields.image?.fields?.linkTo;
  const { pageUrl, parentType = '', neverLazyload = false, abtButtonNavToggle, boutiqueChild, boutiqueFlag, location } = props;

  if (!contentItemsFields.image) {
    return null;
  }

  let isSelected = false;
  const isParentNewVisNav = parentType === 'NewVisualNav';
  const { pathname, query } = location;
  
  if (
    isParentNewVisNav
    && typeof pageUrl === 'string'
    && typeof linkTo === 'string'
    && pathname
  ) {
    const linkToObj = getUrl.parse(linkTo, true);
    let objectKeyArr = [...keysToExclude, 'get-it-fast','filterOptions'];
    let booleanCheck = false;
    for(const item in query){
      if(!objectKeyArr.includes(item)){
         if(query[item] === linkToObj?.query[item]){
           booleanCheck = true;
         }
      }
    } 
    if (
      pathname === linkToObj.pathname
      && Boolean(query.filterOptions ? query.filterOptions  : booleanCheck)
      && query.filterOptions === linkToObj.query.filterOptions
      && query.type === linkToObj.query.type
      && query.priorityProdId === linkToObj.query.priorityProdId
    ) {
      isSelected = true;
    }
  }

  const boutiquePage = (boutiqueChild || boutiqueFlag) ? false : true;

  const iwtStyles = IWTHelper(contentItemsFields);
  const iwtClasses = 'imagewithtext'.concat(props.isMobilePhone
    ? iwtStyles.iwtClassesMobile
    : classNames(iwtStyles.iwtClassesDesktop, iwtStyles.iwtClassesTablet));
  const imageClasses = props.isMobilePhone
    ? iwtStyles.imageClassesMobile
    : classNames(iwtStyles.imageClassesDesktop, iwtStyles.imageClassesTablet);
  const textClasses = props.isMobilePhone
    ? iwtStyles.textClassesMobile // eslint-disable-next-line max-len
    : classNames(iwtStyles.textClassesDesktop, iwtStyles.textClassesTablet);
  return (
    ((abtButtonNavToggle && isParentNewVisNav) && boutiquePage) ? (
      <RichText
        cmsContentItem={contentItemsFields.text}
        key={contentIdText}
        trackTags={trackTags}
        abtButtonNavToggle={abtButtonNavToggle}
        isSelected={isSelected}
      />
    ) :
    <div className={iwtClasses}>
      <div className={`${imageClasses} slick-image-container`}>
        <ImageAsset
          trackTags={trackTags}
          cmsContentItem={contentItemsFields.image}
          key={contentIdImage}
          neverLazyload={neverLazyload}
        />
      </div>
      <div className={classNames(textClasses, 'iwt-text', { 'visual-nav': isParentNewVisNav }, { selected: isSelected })}>
        <RichText
          cmsContentItem={contentItemsFields.text}
          key={contentIdText}
          trackTags={trackTags}
        />
      </div>
      {// eslint-disable-next-line max-len
      props.isMobilePhone
        ? iwtStyles.mobileImageTextStyle()
        : iwtStyles.desktopTabletImageTextStyle()}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
  pageUrl: get(state, 'session.url', null),
  abtButtonNavToggle: get(state, 'toggles.ABTEST_BUTTON_NAV', false) && get(state, `abTestsOpt.nplp6.variation`, 'a') === 'b',
  boutiqueChild: get(state, 'templates.templateDetails.boutiqueChild', false)
  || get(state, 'productListPage.products.boutiqueChild', false),
  boutiqueFlag: get(state, 'utag.productListPage.page_type') === 'boutique',
  location: get(state, 'page.location', {})
});

export default connect(mapStateToProps)(DumbImageWithText);
