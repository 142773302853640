import React from 'react';
import './NoFavorites.scss';

const NoFavorites = () => {
  return (
    <div className="no_favorites">
      <span className="body_text">
        You currently have no Favorited Items. You can add favorites from any product page.
      </span>
    </div>
  );
};

export default NoFavorites;
