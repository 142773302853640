import get from 'lodash/get';
import {
  getLinkedDataPDP,
  getLinkedDataForAllProductsAndSKUs,
} from 'client-utils/utilities-linked-data';
import {
  RESET_QUICK_LOOK_PRODUCT,
  RESOLVED_QUICK_LOOK_PRODUCT,
} from 'plp/components/ProductListPage/actions';
import { getCloudinaryImageSetIfEnabled } from 'client/utilities/utilities-cloudinary';
import {
  RESOLVED_PRODUCT_PLA,
  REJECTED_PRODUCT_PLA,
  RESOLVED_PRODUCT,
  RESOLVED_GROUP,
  REJECTED_PRODUCT,
  RESET_PRODUCT,
  INCREASE_PRODUCT_QUANTITY,
  DECREASE_PRODUCT_QUANTITY,
  UPDATE_PRODUCT_QUANTITY,
  SET_DELIVERY_DATE,
  ZOOM_PRODUCT_IMAGE,
  ZOOM_PRODUCT_IMAGE_QL,
  SET_ACTIVE_MEDIA_INDEX,
  ADD_PRODUCT_TO_BAG,
  UNSET_PRODUCT_VIDEO,
  SET_PRODUCT_VIDEO,
  SET_REPLENISH_INTERVAL,
  SET_BOPS_ERROR_FOR_REPLENISHMENT,
  BOPS_NO_SELECTION_ERR_MSG_FOR_REPLENISHMENT,
  TOGGLE_FAVORITE,
  SET_PERSONALIZATION,
  SET_SELECTED_CUSTOMIZATIONS,
  SET_SELECTED_CUSTOMIZATIONS_ALL_TYPES,
  SET_SELECTED_CUSTOMIZATION_OBJECT,
  SET_SELECTED_CUSTOMIZATIONS_TOUCHED_TRUE,
  SET_SELECTED_CUSTOMIZATIONS_SAVEDVALUES,
  SET_SELECTED_CUSTOMIZATIONS_SELECTEDVALUES_FROM_SAVEDVALUES,
  SET_DYNAMIC_IMAGE_URL,
  QUICK_LOOK_ADD_PRODUCT_TO_BAG,
  SET_ACTIVE_PDP_TAB,
} from '../actions';
import skuReducer from './skuReducer';
import errorReducer from './errorReducer';

const isChanel = (product) => {
  const designerName = get(product, 'designer.name', '');
  return designerName.toLowerCase() === 'chanel';
};

export const isZeroDollarProduct = (product) => parseFloat(get(product, 'price.retailPrice', '0')) <= 0;

const convertMediaToCloudinaryMedia = (media) => {
  if (media) {
    const convertedMedia = {
      main: getCloudinaryImageSetIfEnabled(media.main),
      alternate: {},
      video: getCloudinaryImageSetIfEnabled(media.video),
    };
    const alternates = Object.keys(media.alternate || []);
    alternates.forEach((altType) => {
      // eslint-disable-next-line
      convertedMedia.alternate[altType] = getCloudinaryImageSetIfEnabled(media.alternate[altType]);;
    });
    return convertedMedia;
  }
  return media;
};

function updateProductMediaToCloudinary(product) {
  const updatedProduct = { ...product };
  updatedProduct.media = convertMediaToCloudinaryMedia(product.media);
  const productOptions = get(updatedProduct, 'options.productOptions');
  if (productOptions) {
    const colorIdx = productOptions.findIndex((opt) => opt?.label === 'color');
    if (colorIdx !== -1) {
      const colorMedia = productOptions[colorIdx].values.map((opt) => ({
        ...opt,
        media: convertMediaToCloudinaryMedia(opt?.media),
      }));
      productOptions[colorIdx].values = colorMedia;
    }
  }
  return updatedProduct;
}

export function updateProductState(state, product, toggles) {
  let updatedProduct = product;
  if (toggles && toggles.useCloudImgs) {
    updatedProduct = updateProductMediaToCloudinary(product);
  }
  return {
    ...state,
    ...updatedProduct,
    quantity: 1,
    linkedData: getLinkedDataPDP(updatedProduct),
    linkedDataWithAllProdsAndSKUs: getLinkedDataForAllProductsAndSKUs(product),
    options: {
      ...state.options,
      ...updatedProduct.options,
    },
    deliveryDate: '',
    isChanel: isChanel(updatedProduct),
    isZeroDollarProduct: isZeroDollarProduct(updatedProduct),
  };
}

export const defaultState = {
  quantity: 1,
  activeMediaIndex: 0,
  linkedData: {},
  linkedDataWithAllProdsAndSKUs: {},
  videoActive: false,
  activePDPTab: 0,
  deliveryDate: '',
  vendorRestrictedDates: [],
  bopsErrorForReplenishment: false,
  favAddRemoveStatus: '',
  isPersonalizationSelected: false,
  addToBagError: '',
  bopsError: '',
  isChanel: false,
  isZeroDollarProduct: false,
  isGroup: false,
};

function updateSelectedCustomizationObject(
  selectedCustomizationsAllTypes,
  payload,
) {
  return selectedCustomizationsAllTypes.map((customization) => {
    if (customization.id !== payload.id) {
      return customization;
    }

    return {
      ...customization,
      isValid: payload.isValid,
      selectedValues: payload.selectedValues,
      touched: payload.touched,
    };
  });
}

function setSelectedCustomizationTouchedTrue(selectedCustomizationsAllTypes) {
  return selectedCustomizationsAllTypes.map((customization) => ({
    ...customization,
    touched: true,
  }));
}

function setSelectedCustomizationSavedValues(selectedCustomizationsAllTypes) {
  return selectedCustomizationsAllTypes.map((customization) => ({
    ...customization,
    savedValues: customization.selectedValues,
  }));
}

function setSelectedCustomizationSelectedValuesFromSavedValues(
  selectedCustomizationsAllTypes,
) {
  return selectedCustomizationsAllTypes.map((customization) => ({
    ...customization,
    selectedValues: customization.savedValues,
    isValid: true,
  }));
}

export function getSelectedCustomizationsAllTypesById(state, props) {
  const isGroup = get(state, 'productCatalog.product.isGroup', false);
  const productCatalog = get(state, 'productCatalog');
  const productId = get(props, 'productId', '');

  return isGroup
    // eslint-disable-next-line max-len
    ? productCatalog.group.childProducts[productId].customization.selectedCustomizationsAllTypes
      .find((selectedCustomization) => (
        selectedCustomization.id === props.option.id
      ))
    : productCatalog.product.customization.selectedCustomizationsAllTypes
      .find((selectedCustomization) => (
        selectedCustomization.id === props.option.id
      ));
}

export function getSelectedCustomizationsAllTypes(state, productId) {
  const isGroup = get(state, 'productCatalog.product.isGroup', false);
  const productCatalog = get(state, 'productCatalog');

  return isGroup
    // eslint-disable-next-line max-len
    ? productCatalog.group.childProducts[productId].customization.selectedCustomizationsAllTypes
    : productCatalog.product.customization.selectedCustomizationsAllTypes;
}

// Common actions used for group and single product
export function productInteractionReducer(state = defaultState, action) {
  let quantity;
  let deliveryDate;

  switch (action.type) {
    case ZOOM_PRODUCT_IMAGE:
      return {
        ...state,
        zoomedImage: {
          ...action.payload,
        },
      };
    case ZOOM_PRODUCT_IMAGE_QL:
      return {
        ...state,
        zoomedImageQl: {
          ...action.payload,
        },
      };
    case SET_ACTIVE_MEDIA_INDEX:
      return {
        ...state,
        activeMediaIndex: action.payload,
      };
    case SET_PRODUCT_VIDEO:
      return {
        ...state,
        videoActive: true,
      };
    case UNSET_PRODUCT_VIDEO:
      return {
        ...state,
        videoActive: false,
      };
    case SET_ACTIVE_PDP_TAB:
      return {
        ...state,
        activePDPTab: action.payload,
      };
    case ADD_PRODUCT_TO_BAG:
      return {
        ...state,
        quantity: 1,
        addToBagError: '',
      };
    case QUICK_LOOK_ADD_PRODUCT_TO_BAG:
      return {
        ...state,
        quantity: 0,
        addToBagError: '',
      };
    case INCREASE_PRODUCT_QUANTITY:
      quantity = state.quantity < 999 ? action.payload + 1 : 999;
      return {
        ...state,
        quantity,
      };
    case DECREASE_PRODUCT_QUANTITY:
      quantity = state.quantity > 1 ? action.payload - 1 : 1;
      return {
        ...state,
        quantity,
      };
    case UPDATE_PRODUCT_QUANTITY:
      quantity = parseInt(action.payload.toString().substr(0, 3), 10);
      return {
        ...state,
        quantity,
      };
    case SET_DELIVERY_DATE:
      deliveryDate = action.payload;
      return {
        ...state,
        deliveryDate,
      };
    case SET_REPLENISH_INTERVAL:
      return {
        ...state,
        replenishInterval: action.payload,
        bopsErrorForReplenishment: false,
        bopsNoSelectionErrorForReplenishment: false,
        addToBagError: '',
      };
    case SET_BOPS_ERROR_FOR_REPLENISHMENT:
      return {
        ...state,
        bopsErrorForReplenishment: true,
      };
    case BOPS_NO_SELECTION_ERR_MSG_FOR_REPLENISHMENT:
      return {
        ...state,
        bopsNoSelectionErrorForReplenishment: true,
      };
    case TOGGLE_FAVORITE:
      return {
        ...state,
        favAddRemoveStatus: action.payload,
      };
    case SET_PERSONALIZATION:
      return {
        ...state,
        isPersonalizationSelected: action.payload,
      };
    case SET_SELECTED_CUSTOMIZATIONS:
      return {
        ...state,
        customization: {
          ...state.customization,
          selectedCustomizations: action.payload,
        },
      };
    case SET_SELECTED_CUSTOMIZATIONS_ALL_TYPES:
      return {
        ...state,
        customization: {
          ...state.customization,
          selectedCustomizationsAllTypes: action.payload,
        },
      };
    case SET_DYNAMIC_IMAGE_URL:
      return {
        ...state,
        customization: {
          ...state.customization,
          dynamicImageURL: action.payload,
        },
      };
    case SET_SELECTED_CUSTOMIZATION_OBJECT: {
      return {
        ...state,
        customization: {
          ...state.customization,
          selectedCustomizationsAllTypes: updateSelectedCustomizationObject(
            state.customization.selectedCustomizationsAllTypes,
            action.payload
          ),
        },
      };
    }
    case SET_SELECTED_CUSTOMIZATIONS_TOUCHED_TRUE:
      return {
        ...state,
        customization: {
          ...state.customization,
          selectedCustomizationsAllTypes: setSelectedCustomizationTouchedTrue(
            state.customization.selectedCustomizationsAllTypes
          ),
        },
      };
    case SET_SELECTED_CUSTOMIZATIONS_SAVEDVALUES:
      return {
        ...state,
        customization: {
          ...state.customization,
          selectedCustomizationsAllTypes: setSelectedCustomizationSavedValues(
            state.customization.selectedCustomizationsAllTypes
          ),
        },
      };
    case SET_SELECTED_CUSTOMIZATIONS_SELECTEDVALUES_FROM_SAVEDVALUES:
      return {
        ...state,
        customization: {
          ...state.customization,
          selectedCustomizationsAllTypes:
            setSelectedCustomizationSelectedValuesFromSavedValues(
              state.customization.selectedCustomizationsAllTypes
            )
          ,
        },
      };
    default:
      return [errorReducer, skuReducer].reduce((stateAcc, reducer) => (
        reducer(stateAcc, action)
      ), state);
  }
}

// High order reducer to avoid unnecessary updates for groups
export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_PRODUCT_PLA:
    case RESOLVED_PRODUCT:
      return updateProductState(state, action.payload, action.ftrTgls);
    case RESOLVED_GROUP:
      return {
        ...state,
        isGroup: true,
      };
    case REJECTED_PRODUCT_PLA:
    case REJECTED_PRODUCT:
      return state;
    case RESET_PRODUCT:
      return defaultState;
    case RESOLVED_QUICK_LOOK_PRODUCT:
      return updateProductState(state, action.payload, action.ftrTgls);
    case RESET_QUICK_LOOK_PRODUCT:
      return defaultState;
    default:
      return !state.isGroup ? productInteractionReducer(state, action) : state;
  }
};
