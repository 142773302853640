import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classnames from 'classnames';
import window from 'window-or-global';
import { referralSourceClicked } from 'shared/actions/actions-page';
// eslint-disable-next-line import/no-cycle
import SimpleText from '../simpleText/simpleText';
import './localNavWrapper.scss';
import { editorialNavlinkClick } from '../../actions';

export class LocalNavWrapper extends Component {
  constructor() {
    super();
    this.state = {
      currentHash: null,
    };

    this.handleOnClick = this.handleOnClick.bind(this);
    this.isItemActive = this.isItemActive.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ currentHash: window?.location?.hash || '#' }), 0);
  }

  handleOnClick(event, item) {
    referralSourceClicked('editorial_nav_click', 'editorial');
    setTimeout(() => this.setState({ currentHash: window?.location?.hash || '#' }), 0);
    if (item.fields.link.toLowerCase().endsWith('-anchorpoint')) {
      event.preventDefault();
      document.getElementById(item.fields.link.replace('#', '')).scrollIntoView({ behavior: 'smooth' });
    }
  }

  isItemActive(item) {
    return (item.fields?.link === this.state.currentHash);
  }

  render() {
    const {
      cmsContentAssets = {},
      isMobilePhone,
    } = this.props;
    return (
      <nav className={classnames('editorial-nav', { 'editorial-nav__mobile': isMobilePhone })}>
        <ul className={classnames('editorial-nav__links', { 'editorial-nav__links__mobile': isMobilePhone })}>
          {
            cmsContentAssets.map((item, index) => {
              return (
                <li
                  key={index}
                  className={classnames('editorial-nav__item', { 'editorial-nav__item__active': this.isItemActive(item) })}
                  onClick={(event) => this.handleOnClick(event, item)}
                >
                  <SimpleText cmsContentItem={item} key={index} />
                </li>
              );
            })
          }
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
});

export default connect(mapStateToProps)(LocalNavWrapper);
