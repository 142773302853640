import React, { Component, Fragment } from 'react';
import get from 'lodash/get';
import { mark, measure } from 'client-utils/utilities-performance';
import { MONOGRAM_HELPLINE, MONOGRAM_ASSISTANCE } from 'pdp/constants';
import Appointments from 'pdp/components/Appointments/Appointments';
import ItemNotAvailable from './itemNotAvailable';
import CheckoutOptionInfo from './checkoutOptionInfo';
import CallForAvailability from './callForAvailability';
import './callForAvailability.scss';
import './monogramHelpline.scss';

const hasMixedTypeCustomizationOptionsIncludingPDP = (product) => {
  let hasMixedType = false;
  const types = [];
  if (product.customization && product.customization.customizationOptions) {
    product.customization.customizationOptions.forEach((element) => {
      types.push(element.type);
      if (element.type !== 'PDP') {
        hasMixedType = true;
      }
    });
  }
  return hasMixedType && types.filter((type) => type === 'PDP').length > 0;
};

export const shouldDisplayMonogramHelpLineText = (product) => {
  const {
    isCustomizable = false,
    customizationSupported = true,
    customization = {},
  } = product;

  const behaviorCode = get(customization, 'behaviorCode', '');
  return isCustomizable
    && (!customizationSupported
      || hasMixedTypeCustomizationOptionsIncludingPDP(product)
    )
    && behaviorCode !== 'N';
};

export const renderCheckoutOrHelplineText = (
  product,
  defaultCheckoutInfo,
  itemNotAvailableMessage,
  showAppointments,
) => {
  const {
    displayable = false,
  } = product;

  const suppressCheckout = get(product, 'details.suppressCheckout', false);

  const MonogramHelpline = (
    <div className="monogramHelpline">
        {(product?.details?.suppressCheckout && product?.details?.monogramAssistance)
            ? MONOGRAM_ASSISTANCE 
            : MONOGRAM_HELPLINE}
      {showAppointments && (
        <div style={{ 'margin-top': '10px' }}>
          <Appointments />
        </div>
      )}
    </div>
  );

  const suppressCheckoutSection = () => {
    if(suppressCheckout && product?.productFlags?.spotlightOn ){
      return  <div className="monogramHelpline"> {MONOGRAM_ASSISTANCE} </div>
    }else if(suppressCheckout){
      return  <Fragment>
      <CallForAvailability />
      {showAppointments && (
        <div style={{ 'margin-left': '10px' }}>
          <Appointments />
        </div>
      )}
    </Fragment>
    }else {
      return defaultCheckoutInfo;
    }
  }

  const getCheckoutInfo = () => (
    shouldDisplayMonogramHelpLineText(product)
      ? MonogramHelpline
      : suppressCheckoutSection()
  );

  return (displayable
    ? getCheckoutInfo()
    : itemNotAvailableMessage
  );
};

class CheckoutOptionRenderer extends Component {
  constructor() {
    super();
    mark('product options (construct)');
  }

  componentDidMount() {
    measure('product options (TTI)');
    measure('product options (self render time)', 'product options (construct)');
  }

  render() {
    const {
      product,
      boss,
      showAppointments = false,
      isGroupPDP = false,
    } = this.props;
    const {
      metadata = {},
    } = product;

    const itemNotAvailableMessage = (
      <ItemNotAvailable>
        <span className="undisplayable__code">{`${metadata.cmosCatalogId}_${metadata.cmosItem}`}</span>
      </ItemNotAvailable>
    );
    const defaultCheckoutInfo = (
      <CheckoutOptionInfo
        product={product}
        boss={boss}
        showAppointments={showAppointments}
        isGroupPDP={isGroupPDP}
      />
    );

    return renderCheckoutOrHelplineText(
      product,
      defaultCheckoutInfo,
      itemNotAvailableMessage,
      showAppointments,
    );
  }
}

export default CheckoutOptionRenderer;
