import React from 'react';
import Slider from 'react-slick';
// eslint-disable-next-line import/no-cycle
import RenderContentItem from '../renderContentItem';
import './carousel.scss';

const CN = 'carousel';

function Carousel(props) {
  const { cmsContentItems, addSettings = {}, trackTags } = props;

  const defaultSettings = {
    autoplaySpeed: 3000,
    autoplay: true,
    className: CN,
    dotsClass: `${CN}__indicators-group`,
    dots: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
        },
      },
    ],
    speed: 300,
  };

  const settings = { ...defaultSettings, ...addSettings };

  return (
    <Slider {...settings}>
      {cmsContentItems.map((contentItem, index) => (
        <RenderContentItem trackTags={trackTags} key={index} cmsContentItem={contentItem} />
      ))}
    </Slider>
  );
}

export default Carousel;
