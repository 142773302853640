/* eslint import/no-cycle: 0 */
import React from 'react';
import classNames from 'classnames';
import './buttonsGrid.scss';
import isEmpty from 'lodash/isEmpty';
import RenderContentItem from 'cms/components/renderContentItem';
import { concatTrackTagsObjects, getTrackingTags } from 'cms/components/utils';
import { EXP_HOURS_FOR_TWILIO_PROACTIVE } from 'plp/constants';
import { openProactiveChat } from 'client/utilities/twilioUtils';

const ButtonsGrid = ({ cmsContentItem: { fields }, trackTags: propsTrackTags = {} }) => {
  const {
    numberOfColumns,
    buttonsWidth,
    buttonsHeight,
    buttons,
    trackingTags: ownTrackTags = [],
  } = fields;
  const btnsCopy = [...buttons];
  const combinedTrackTags = concatTrackTagsObjects(propsTrackTags, getTrackingTags(ownTrackTags));
  const getLayout = (buttons) => {
    const isCTALandingPage = !isEmpty(buttons.find((item) => {
      return item.fields.name === "CTA-SA-LP";
    }));
    const openChat = (e) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
        openProactiveChat({
          eventType: 'WomensDressesCategoryEventType',
          product: 'WomensDressesCategory',
          page: 'contentful',
          expHoursForTwilioProactive: EXP_HOURS_FOR_TWILIO_PROACTIVE,
        });
      }
    }

    return (
      <div onClick={isCTALandingPage ? openChat : null}>
        {buttons.map((item, index) => (
          <div
            style={{ width: buttonsWidth, height: buttonsHeight }}
            className={classNames(
              'button--wrapper',
              isCTALandingPage ? `cta-sa-lp ${item.fields.buttonBackgroundColor}` : null,
            )}
            key={index}
          >
            <RenderContentItem trackTags={combinedTrackTags} cmsContentItem={item} />
          </div>
        ))}
      </div>
    );
  };

  const bpArray = new Array(Math.ceil(btnsCopy.length / numberOfColumns)).fill(
    numberOfColumns,
  );
  const layout = [];
  for (let i = 0; i < bpArray.length; i++) {
    if (btnsCopy.length === 0) break;
    layout.push(getLayout(btnsCopy.splice(0, bpArray[i])));
  }

  return <div className="buttons-grid--wrapper">{layout}</div>;
};

export default ButtonsGrid;
