/* eslint-disable camelcase */
import window from 'window-or-global';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import {
  ADD_PRODUCT_TO_BAG,
  SET_PRODUCT_DETAIL_FAVORITE_UTAG_DATA,
  PLA_ADD_PRODUCT_TO_BAG,
  QUICK_LOOK_ADD_PRODUCT_TO_BAG,
  RESOLVED_QUICK_LOOK_PRODUCT_PDP,
  RESET_QUICK_LOOK_PRODUCT_PDP,
  OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG,
} from 'pdp/pages/ProductPage/actions';
import {
  SET_FAVORITE_UTAG_DATA,
  SET_PRODUCT_LIST_DATA_TO_UTAG,
  ADD_RECENT_SIZES_TO_UTAG_DATA,
  RESOLVED_QUICK_LOOK_PRODUCT,
  RESET_QUICK_LOOK_PRODUCT,
  SET_ALT_IMAGE_UTAG,
  SET_MVP_FILTER_UTAG,
  REMOVE_MVP_FILTER_UTAG,
} from 'plp/components/ProductListPage/actions';
import {
  SET_SEARCH_LIST_DATA_TO_UTAG,
  CLEAR_QR_FROM_UTAG,
  SET_GENDER_TA,
} from 'srp/actions/actions';
import {
  RESOLVED_SEND_EMAIL,
  LOGIN_PAGES_UTAG_DATA,
  REGISTER_PAGE_UTAG_DATA,
  RESET_PW_PAGELOAD_UTAG_DATA,
  RESOLVED_RESET_PASSWORD,
  RESOLVED_CCPA_FORM,
  CCPA_UTAG_DATA,
  GUEST_HISTORY_UTAG,
  GUEST_DETAILS_UTAG,
  ORDER_HISTORY_UTAG,
  DT_LOGIN_FAILURE,
} from 'profile/actions/actions';
import { EDITORIAL_NAVLINK_CLICK, CLOSE_PRODUCT_PANEL } from 'cms/actions';
import { APP_SET_UEM_PARAM } from 'shared/actions/actions-page';
import { types as categoryTemplateTypes } from 'templates/dux/templateActions';
import { OPEN_MODAL, CLOSE_MODAL } from 'client/common/components/Modal/actions';
import { getQueryParam } from 'client-utils/utilities-url';
import {
  setGenderPlacementCookie,
  setSelectedGender,
} from 'client-utils/utilities-gender';
import groupUtagData from 'server/components/Utag/reducers/selector/getGroupUtagData';
import { SET_GENDER, SET_TYPEAHEAD_UTAG } from 'shared/components/App/app-action-types';
import { updateUtagData } from 'shared/components/YourNeimans/components/utilities';
import { GENDER_PLACEMENT } from 'universal/constants/genderPlacement';
import {
  productUtag,
  favoriteUtagData,
  productListUtag,
  facetFlagUtag,
  searchListUtag,
  selectedFilterUtag,
  selectedFacetUtag,
  recentSizesUtag,
  quickLookProductUtagDataKeys,
  quickLookProductUtagData,
  categoryTemplatesUtag,
  ctpFavoriteDesignerUtag,
  addToBagUtag,
  addToBagDataKeys,
  productPanelKeys,
} from './utagData';
import { ALTERNATE_IMAGE, BEST_MATCH, MVP_FILTER } from '../../plp/constants';
import { FAVORITE_PRODUCT_PANEL, LOAD_PRODUCTS_IN_PANEL } from 'client/cms/actions/index';

/* eslint-disable no-unused-expressions */
const sendUtagViewData = (addlProps) => new Promise((resolve) => {
  window?.utag?.view?.({ ...window.utag_data_dt, ...addlProps }, resolve);
});
const sendUtagLinkData = (addlProps) => new Promise((resolve) => {
  window?.utag?.link?.({ ...window.utag_data_dt, ...addlProps }, resolve);
});
/* eslint-enable no-unused-expressions */

let pageName;
let productCmosCatalogId;
let productCmosItem;
let promoTileFlag;
let pageDefinitionId;
let pageTemplate;
let pageType;

export const updatePlpTypeForQuicklookAndFavoritesClick = () => {
  window.utag_data_dt = {
    ...window.utag_data_dt,
    plp_type: '',
  };
};

const setCommonDataElements = (
  [page_name, event_name, page_definition_id, page_template, page_type]
) => (
  {
    page_name, event_name, page_definition_id, page_template, page_type,
  }
);

export default () => (next) => (action) => {
  const { type, payload = {} } = action;
  let disableTealium = '';
  try {
    disableTealium = getQueryParam('limitTMSOnPage');
  } catch (e) {
    disableTealium = '';
  }
  if (disableTealium === 'ALL') {
    return next(action);
  }

  if (type === SET_GENDER) {
    setSelectedGender(payload.gender);

    if (payload.placement === GENDER_PLACEMENT.MOBILE_NAV) {
      sendUtagLinkData({
        genderClickFlag: 'true',
        genderSelection: payload.gender === 'M' ? 'men' : 'women',
        genderPlacement: payload.placement,
      });
    } else if (payload.placement === GENDER_PLACEMENT.HOME && payload.component === 'mobile_scrollmenu') {
      sendUtagViewData({
        genderClickFlag: 'true',
        genderSelection: payload.gender === 'M' ? 'men' : 'women',
        genderPlacement: payload.placement,
      });
    } else if (payload.placement) {
      setGenderPlacementCookie(payload.placement);
    }
  }

  if (type === SET_GENDER_TA && payload.withUtagAnalytics) {
    setGenderPlacementCookie(payload.placement || GENDER_PLACEMENT.TYPE_AHEAD);
    sendUtagLinkData({
      genderClickFlag: 'true',
      genderSelection: payload.gender === 'M' ? 'men' : 'women',
      genderPlacement: GENDER_PLACEMENT.TYPE_AHEAD,
    });
  }

  if (type === SET_TYPEAHEAD_UTAG) {
    const {
      previous_view_search_submit,
      previous_view_spr_submit,
      search_function,
      search_type_ahead,
      facet_selection,
      facet_type,
    } = payload;
    updateUtagData({
      previous_view_search_submit,
      previous_view_spr_submit,
      search_function,
      search_type_ahead,
      facet_selection,
      facet_type,
    });
  }

  if (type === OPEN_MODAL && payload.type === 'ForgotPasswordModalDT') {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, ['login_bt_click', 'logged_in_previous_page_flag']),
      ...setCommonDataElements(['forgot password', 'forgot password step 1', 'account', 'account', 'account']),
    };
    sendUtagLinkData();
  }
  if (type === RESOLVED_SEND_EMAIL) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...setCommonDataElements(['forgot password', 'forgot password step 2', 'account', 'account', 'account']),
    };
    sendUtagLinkData();
  }
  if (type === RESOLVED_RESET_PASSWORD) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...setCommonDataElements(['password updated', 'pageLoad', 'reset complete', 'account', 'account']),
    };
    sendUtagViewData();
  }
  if (type === RESOLVED_CCPA_FORM) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...setCommonDataElements(['CCPA Submission', 'pageLoad', 'ccpa', 'ccpa', 'webform']),
    };
    sendUtagViewData();
  }
  if (type === CCPA_UTAG_DATA) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...setCommonDataElements(['CCPA Form', 'pageLoad', 'ccpa', 'ccpa', 'webform']),
    };
    sendUtagViewData();
  }
  if (type === LOGIN_PAGES_UTAG_DATA) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...setCommonDataElements(['login', 'pageLoad', 'account', 'account', 'account']),
      site_abbreviation: 'nm',
    };
    sendUtagViewData();
  }
  if (type === RESET_PW_PAGELOAD_UTAG_DATA) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...setCommonDataElements(['change password', 'pageLoad', 'reset', 'account', 'account']),
      site_abbreviation: 'nm',
    };
    sendUtagViewData();
  }
  if (type === OPEN_MODAL && payload.type === 'BopsModalOptimized') {
    sendUtagLinkData({
      ...productUtag(payload.props.product),
      event_name: 'find_in_store',
    });
  } else if (type === RESOLVED_QUICK_LOOK_PRODUCT
    || type === RESOLVED_QUICK_LOOK_PRODUCT_PDP) {
    pageName = window.utag_data_dt.page_name;
    productCmosCatalogId = window.utag_data_dt.product_cmos_catalog_id;
    productCmosItem = window.utag_data_dt.product_cmos_item;

    if (payload.displayAsGroups) {
      updatePlpTypeForQuicklookAndFavoritesClick();
    }

    if (payload.isGroup) {
      window.utag_data_dt = {
        ...window.utag_data_dt,
        ...groupUtagData(payload),
        page_name: 'product detail',
        event_name: 'Quicklook',
      };
    } else {
      window.utag_data_dt = {
        ...window.utag_data_dt,
        ...quickLookProductUtagData(payload),
      };
    }
    if (type === RESOLVED_QUICK_LOOK_PRODUCT_PDP) {
      window.utag_data_dt = {
        ...window.utag_data_dt,
        outfitting_product: 'click',
      };
    }
    sendUtagViewData();
  } else if (type === QUICK_LOOK_ADD_PRODUCT_TO_BAG) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...addToBagUtag(payload),
    };
    sendUtagLinkData();
  } else if (type === CLOSE_MODAL && payload === 'AddToBagQLModal') {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, addToBagDataKeys),
      event_name: 'pageLoad',
    };
  } else if (type === CLOSE_MODAL && payload === 'ForgotPasswordModalDT') {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      page_name: 'login',
      event_name: 'pageLoad',
    };
  } else if (type === RESET_QUICK_LOOK_PRODUCT) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, quickLookProductUtagDataKeys),
      page_name: pageName,
      event_name: 'pageLoad',
      product_cmos_catalog_id: productCmosCatalogId,
      product_cmos_item: productCmosItem,
    };
  } else if (type === RESET_QUICK_LOOK_PRODUCT_PDP) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, quickLookProductUtagDataKeys),
      ...productUtag(payload),
      page_name: pageName,
      event_name: 'pageLoad',
      product_cmos_catalog_id: productCmosCatalogId,
      product_cmos_item: productCmosItem,
      outfitting_product: '',
    };
  } else if (type === ADD_PRODUCT_TO_BAG
    || type === PLA_ADD_PRODUCT_TO_BAG) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...addToBagUtag(payload),
      trufit_multiplesize_modal: String(
        payload.trufit_multiplesize_modal
      ),
    };
    sendUtagLinkData();
  } else if (type === SET_FAVORITE_UTAG_DATA) {
    if (payload.displayAsGroups) {
      updatePlpTypeForQuicklookAndFavoritesClick();
    }
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...favoriteUtagData(payload),
    };
    sendUtagLinkData();
  } else if (type === SET_PRODUCT_DETAIL_FAVORITE_UTAG_DATA) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...favoriteUtagData(payload),
    };
    sendUtagLinkData();
  } else if (type === SET_PRODUCT_LIST_DATA_TO_UTAG) {
    let utagData = {
      ...window.utag_data_dt,
      ...productListUtag(payload),
      ...facetFlagUtag(payload),
    };
    if (parseInt(payload.page, 10) !== 1) {
      utagData = {
        ...omit(utagData, 'promo_tile_flag'),
      };
    } else if (!isNil(promoTileFlag)) {
      utagData.promo_tile_flag = promoTileFlag;
    }
    if (!isEmpty(payload.filterOptions)) {
      utagData = {
        ...utagData,
        ...selectedFilterUtag(payload),
      };
    }
    if (!isEmpty(payload.facetOptions)) {
      utagData = {
        ...utagData,
        ...selectedFacetUtag(payload),
      };
    }
    if (!isEmpty(payload.sortBy)
      && payload.sortBy === BEST_MATCH) {
      utagData = {
        ...utagData,
      };
    }
    window.utag_data_dt = utagData;
    sendUtagViewData();
  } else if (type === SET_SEARCH_LIST_DATA_TO_UTAG) {
    let utagData = {
      ...window.utag_data_dt,
      ...searchListUtag(payload),
    };
    if (!isEmpty(payload.filterOptions)) {
      utagData = {
        ...utagData,
        ...selectedFilterUtag(payload),
      };
    }
    if (!isEmpty(payload.facetOptions)) {
      utagData = {
        ...utagData,
        ...selectedFacetUtag(payload),
      };
    }
    window.utag_data_dt = utagData;
    sendUtagViewData();
  } else if (type === ADD_RECENT_SIZES_TO_UTAG_DATA) {
    const utagData = {
      ...window.utag_data_dt,
      ...recentSizesUtag(payload),
    };
    window.utag_data_dt = utagData;
  } else if (
    type === categoryTemplateTypes.SET_CATEGORY_TEMPLATES_DATA_TO_UTAG
  ) {
    const utagData = {
      ...window.utag_data_dt,
      ...categoryTemplatesUtag(payload),
    };
    window.utag_data_dt = utagData;
  } else if (
    type === categoryTemplateTypes.SET_FAVORITE_DESIGNER_INDEX_TO_UTAG
  ) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...ctpFavoriteDesignerUtag(payload),
    };
    sendUtagLinkData();
  } else if (type === OUTFITTING_QUICK_LOOK_ADD_PRODUCT_TO_BAG) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...addToBagUtag(payload),
      outfitting_product: '',
    };
    sendUtagLinkData();
  } else if (type === CLEAR_QR_FROM_UTAG) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      query_relax: '',
    };
  } else if (type === APP_SET_UEM_PARAM) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      url_email_decoded: action.email,
    };
  } else if (type === REGISTER_PAGE_UTAG_DATA) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...setCommonDataElements(['register', 'pageLoad', 'registration', 'account', 'account']),
      site_abbreviation: 'nm',
    };
    sendUtagViewData();
  } else if (type === GUEST_HISTORY_UTAG) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...setCommonDataElements(['guest: order history', 'pageLoad', 'account', 'OH2', 'account']),
      site_abbreviation: 'nm',
      customer_email: '',
      profile_type: 'customer',
    };
    sendUtagViewData();
  } else if (type === GUEST_DETAILS_UTAG) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      page_name: 'guest: order details',
      page_template: 'OD2',
    };
    sendUtagViewData();
  } else if (type === ORDER_HISTORY_UTAG) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...setCommonDataElements(['my account: order history', 'pageLoad', 'account', 'OH1', 'account']),
      site_abbreviation: 'nm',
      profile_type: 'customer',
      logged_in_status: 'true',
    };
    sendUtagViewData();
  } else if (type === DT_LOGIN_FAILURE) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      login_bt_click: 'true',
      logged_in_previous_page_flag: 'false',
    };
    sendUtagLinkData();
  } else if (type === EDITORIAL_NAVLINK_CLICK) {
    window.utag_data_dt = {
      ...window.utag_data_dt,
      editorial_nav_click: 'true',
      editorial_class: payload,
    };
    sendUtagViewData();
  } else if(type === SET_ALT_IMAGE_UTAG){
    window.utag_data_dt = {
      ...window.utag_data_dt,
      plp_interaction: ALTERNATE_IMAGE
    };
    sendUtagLinkData();
  } else if (type === LOAD_PRODUCTS_IN_PANEL) {
    const product = { childProducts: payload };
    window.utag_data_dt = {
      ...window.utag_data_dt,
      ...groupUtagData(product)
    };
    payload?.length > 1 ? window.utag_data_dt.product_type = ['group'] : window.utag_data_dt.product_type = ['non-group'];
    sendUtagViewData();
  } else if (type === CLOSE_PRODUCT_PANEL) {
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, productPanelKeys),
      event_name: 'pageLoad',
      page_name: pageName,
      page_definition_id: pageDefinitionId,
      page_template: pageTemplate,
      page_type: pageType,
    };
  } else if(type === SET_MVP_FILTER_UTAG){
    window.utag_data_dt = {
      ...window.utag_data_dt,
      plp_interaction: MVP_FILTER
    };
  }  else if(type === REMOVE_MVP_FILTER_UTAG){
    window.utag_data_dt = {
      ...omit(window.utag_data_dt, ['plp_interaction']),
    };
  } else if (type === FAVORITE_PRODUCT_PANEL) {
    const { favoriteStatus, cmosCatalogId, cmosItem } = payload;
    window.utag_data_dt = {
      ...window.utag_data_dt,
      event_name: 'FavoriteItem',
      item_type: 'product',
      favorite_item_status: favoriteStatus,
      product_cmos_catalog_id: [cmosCatalogId],
      product_favorited_catalog: [cmosCatalogId],
      product_cmos_item: [cmosItem],
      product_favorited_item: [cmosItem],
    };
    sendUtagLinkData();
  }

  return next(action);
};
