import httpWithLogging from 'universal/http-client';

export const statusMap = {
  BO: 'Backordered',
  CB: 'In Process',
  CX: 'Cancelled',
  DS: 'In Process',
  MI: 'In Process',
  PD: 'In Process',
  PL: 'In Process',
  PT: 'In Process',
  QU: 'In Process',
  RB: 'In Process',
  RD: 'In Process',
  RI: 'In Process',
  RN: 'Returned',
  RP: 'In Process',
  RT: 'Returned',
  RX: 'Returned',
  VS: 'Shipped',
  A: 'In Process',
  X: 'Cancel Pending',
  PX: 'Cancel Pending',
  RW: 'Returned',
};

export const getOrderStatus = (status, deliveryDate = '') => {
  if ((status === 'VS' || status === 'CT') && deliveryDate) {
    return 'Delivered';
  }

  return statusMap[status] || 'n/a';
};

export const getCancellableStatus = (status) => {
  return ['RP', 'MI', 'RD', 'BO', 'QU', 'RI', 'FT'].includes(status);
};

export function cancelRegisteredOrderItem(state, payload, jwtToken, dynUserId) {
  const {
    orderId,
    itemId,
    reason,
  } = payload;

  const requestApi = httpWithLogging(state, 3000, true);

  const body = {
    itemId,
    reason,
  };
  const headers = {
    Authorization: `Bearer ${jwtToken}`,
    'Content-Type': 'application/json',
  };
  const url = `${NMConfig.API_DT_ORDER_HISTORY}/${dynUserId}/orders/${orderId}/cancel`;

  requestApi.post(url, body, { headers });
}

export const cancelGuestOrderItem = (state, body) => {
  const requestApi = httpWithLogging(state, 3000, true);

  const headers = {
    'Content-Type': 'application/json',
  };

  const url = `${NMConfig.API_DT_ORDER_HISTORY}/guest/cancel`;

  requestApi.post(url, body, { headers });
};

export const normalizeString = (str = '') => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const formatSizeText = (size) => {
  if (!size || typeof size !== 'string') return '';
  const isSizeOnlyLetters = /^[a-z ]+$/i.test(size);
  return isSizeOnlyLetters
    ? size.split(' ').map(normalizeString).join(' ')
    : size;
};

export const formatColorText = (colorText, description = '') => {
  if (!colorText) return null;
  if (colorText.indexOf('/') === -1) return normalizeString(colorText);

  const colorIndex = description.toLowerCase().indexOf(colorText.toLowerCase());
  if (colorIndex > -1) {
    return description.slice(colorIndex, colorIndex + colorText.length);
  }
  return colorText;
};

export const normalizeShipping = (shippingAddress) => {
  const shippingKeys = Object.keys(shippingAddress);
  const normalizedAddress = shippingKeys.map((key) => {
    if (key !== 'stateCode') return [key, shippingAddress[key].split(' ').map(normalizeString).join(' ')];
    return [key, shippingAddress[key]];
  }).reduce((acc, curr) => {
    acc[curr[0]] = curr[1];
    return acc;
  }, {});

  return normalizedAddress;
};
