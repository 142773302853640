export const UTAG_VALUES = {
  PAGE_DEFINITION_ID: 'BloomreachSearch',
  PAGE_NAME: 'Search',
  PAGE_TYPE: 'search',
  SEARCH_TYPE: 'Bloomreach',
  PAGE_TEMPLATE: 'search',
};

export const PAGE_SIZE_SRP = 60;
export const SRC_VALUE_FOR_UTAG = 'suggest';
export const GEO_LOCATION_SERVICE_ERROR = 'GEO_LOCATION_SERVICE_ERROR';
export const SRP_STARTING_BREADCRUMB_NAME = 'search';
export const SHOT_TYPE_BH_UNDERSCORE = '_bh';
export const SHOT_TYPE_MK_UNDERSCORE = '_mk';
export const SHOT_TYPE_BH_PATH = '/bh/';
export const SHOT_TYPE_MK_PATH = '/mk/';
