import { SET_PRODUCT_LIST_DATA_TO_UTAG, REJECTED_PRODUCT_LIST, SET_ALT_IMAGE_UTAG, SET_MVP_FILTER_UTAG, REMOVE_MVP_FILTER_UTAG } from 'plp/components/ProductListPage/actions';
import { types as NavTypes } from 'storefront/components/Navigation/actions-navigation';
import toString from 'lodash/toString';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { ALTERNATE_IMAGE, MVP_FILTER } from 'client/plp/constants';
import { omit } from 'lodash';

const ProductListUtagKeys = {
  categoryResults: 'category_results',
  pageNumber: 'page_number',
  pageType: 'page_type',
  pageTemplate: 'page_template',
  sortMethod: 'sort_method',
  page_definition_id: 'page_definition_id',
  previous_view_filter_submit: 'previous_view_filter_submit',
  previous_view_facet_submit: 'previous_view_facet_submit',
  previous_view_sort_submit: 'previous_view_sort_submit',
  filter_selection: 'filter_selection',
  filter_type: 'filter_type',
  facet_selection: 'facet_selection',
  facet_type: 'facet_type',
  page_name: 'page_name',
  product_cmos_catalog_id: 'product_cmos_catalog_id',
  product_cmos_item: 'product_cmos_item',
  facet_flag: 'facet_flag',
  facet_eligible: 'facet_eligible',
  plpType: 'plp_type',
  pcs_enabled: 'pcs_enabled',
  csp_toggle: 'csp_toggle',
  product_id: 'product_id',
  plp_interaction: 'plp_interaction',
  attribution_token: 'attribution_token', 
  facet_grs: 'facet_grs',
  sort_method_grs: 'sort_method_grs',
  product_price: 'product_price',
  product_price_original: 'product_price_original'
};

function constructObjectWithNotNullProperties(state, action) {
  if (action.payload.facetFlag) {
    return {
      ...state,
      [ProductListUtagKeys.categoryResults]: toString(action.payload.total),
      [ProductListUtagKeys.pageTemplate]: action.payload.templateType,
      [ProductListUtagKeys.pageNumber]: toString(action.payload.page),
      [ProductListUtagKeys.sortMethod]: bestSelllerSortOptions(action.payload.sortBy),
      [ProductListUtagKeys.page_definition_id]: action.payload.templateType,
      [ProductListUtagKeys.previous_view_facet_submit]:
        toString(action.payload.previousViewFacetSubmit),
      [ProductListUtagKeys.previous_view_sort_submit]:
        toString(action.payload.previousViewSortSubmit),
      [ProductListUtagKeys.facet_selection]:
        action.payload.facetOptions.filterSelection,
      [ProductListUtagKeys.facet_type]: action.payload.facetOptions.filterType,
      [ProductListUtagKeys.product_cmos_catalog_id]: action.payload.cmosCatalog,
      [ProductListUtagKeys.product_cmos_item]: action.payload.cmosItem,
      [ProductListUtagKeys.facet_flag]: toString(action.payload.facetFlag),
      [ProductListUtagKeys.facet_eligible]:
        toString(action.payload.facetEligible),
      [ProductListUtagKeys.plpType]: toString(action.payload.plpType),
      [ProductListUtagKeys.pcs_enabled]: toString(action.payload.pcsEnabled),
      [ProductListUtagKeys.csp_toggle]: toString(action.payload.csp_toggle),
      [ProductListUtagKeys.product_id]: action.payload.product_id,
      [ProductListUtagKeys.attribution_token]: action.payload.attribution_token,
      [ProductListUtagKeys.facet_grs]: action.payload.facet_grs,
      [ProductListUtagKeys.sort_method_grs]: action.payload.sort_method_grs,
      [ProductListUtagKeys.product_price]: action.payload.product_price,
      [ProductListUtagKeys.product_price_original]: action.payload.product_price_original,
    };
  }
  return {
    ...state,
    [ProductListUtagKeys.categoryResults]: toString(action.payload.total),
    [ProductListUtagKeys.pageTemplate]: action.payload.templateType,
    [ProductListUtagKeys.pageNumber]: toString(action.payload.page),
    [ProductListUtagKeys.sortMethod]: bestSelllerSortOptions(action.payload.sortBy),
    [ProductListUtagKeys.page_definition_id]: action.payload.templateType,
    [ProductListUtagKeys.previous_view_filter_submit]:
        toString(action.payload.previousViewFilterSubmit),
    [ProductListUtagKeys.previous_view_sort_submit]:
        toString(action.payload.previousViewSortSubmit),
    [ProductListUtagKeys.filter_selection]:
      action.payload.filterOptions.filterSelection,
    [ProductListUtagKeys.filter_type]: action.payload.filterOptions.filterType,
    [ProductListUtagKeys.product_cmos_catalog_id]: action.payload.cmosCatalog,
    [ProductListUtagKeys.product_cmos_item]: action.payload.cmosItem,
    [ProductListUtagKeys.facet_eligible]:
      toString(action.payload.facetEligible),
    [ProductListUtagKeys.plpType]: toString(action.payload.plpType),
    [ProductListUtagKeys.pcs_enabled]: toString(action.payload.pcsEnabled),
    [ProductListUtagKeys.csp_toggle]: toString(action.payload.csp_toggle),
    [ProductListUtagKeys.product_id]: action.payload.product_id,
    [ProductListUtagKeys.attribution_token]: action.payload.attribution_token,
    [ProductListUtagKeys.facet_grs]: action.payload.facet_grs,
    [ProductListUtagKeys.sort_method_grs]: action.payload.sort_method_grs,
    [ProductListUtagKeys.product_price]: action.payload.product_price,
    [ProductListUtagKeys.product_price_original]: action.payload.product_price_original,
  };
}

function getPageTypeFromPayload(payload) {
  if (isEmpty(payload.breadcrumbs)) {
    return '';
  }
  if (payload.breadcrumbs[0].name === 'Designers' || (NMConfig.BRAND_NAME === 'HC' && payload.breadcrumbs[0].name.includes('All Brands'))) {
    return 'boutique';
  }
  return (payload.breadcrumbs.length > 2) ? 'subcategory' : 'category';
}

export default (state = {}, action) => {
  switch (action.type) {
    case SET_PRODUCT_LIST_DATA_TO_UTAG:
      return constructObjectWithNotNullProperties(state, action);
    case NavTypes.RESOLVED_BREADCRUMB_CONTENT:
      return {
        ...state,
        [ProductListUtagKeys.page_name]: map(action.payload.breadcrumbs, 'name').join(':'),
        [ProductListUtagKeys.pageType]: getPageTypeFromPayload(action.payload),
      };
    case NavTypes.REJECTED_BREADCRUMB_CONTENT:
      return {
        ...state,
        [ProductListUtagKeys.page_name]: '',
        [ProductListUtagKeys.pageType]: '',
      };
    case REJECTED_PRODUCT_LIST:
      return {
      };

    case SET_ALT_IMAGE_UTAG:
      return {
        ...state,
        [ProductListUtagKeys.plp_interaction]: ALTERNATE_IMAGE
      }

    case SET_MVP_FILTER_UTAG:
      return {
        ...state,
        [ProductListUtagKeys.plp_interaction]: MVP_FILTER
      }
    
    case REMOVE_MVP_FILTER_UTAG: 
      return {
        ...omit(state, [ProductListUtagKeys.plp_interaction])
      }

    default:
      return state;
  }
};

function bestSelllerSortOptions (sortBy)  {
  return sortBy === "BEST_SELLER" ? "best_seller_attribute desc" : sortBy
};
