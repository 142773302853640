function removeDraftsFromSubLayouts(layout, level = 0, isComponent) {
  if (level === 6 && !isComponent) { return; }

  if (!layout.fields) { return; }

  const subLayoutString = (level !== 1 ? `l${level + 1}Layouts` : `l${level + 1}Layout`);

  if (layout.fields[subLayoutString]) {
    const draftlessSubLayout = layout.fields[subLayoutString].map((subLayout) => {
      return removeDraftsFromSubLayouts(subLayout, level + 1);
    });
    // eslint-disable-next-line
    return {
      ...layout,
      fields: {
        ...layout.fields,
        [subLayoutString]: draftlessSubLayout,
      },
    };
  }

  if (layout.fields.components) {
    if (!isComponent) {
      const draftlessComponents = layout.fields.components.map((subComponent) => {
        return removeDraftsFromSubLayouts(subComponent, level + 1, true);
      });
      // eslint-disable-next-line
      return {
        ...layout,
        fields: {
          ...layout.fields,
          components: draftlessComponents,
        },
      };
    }
    // eslint-disable-next-line
    return {
      ...layout,
      fields: {
        ...layout.fields,
        components: layout.fields.components.filter((component) => component.fields),
      },
    };
  }

  // eslint-disable-next-line
  return layout;
}

export function removeDraftsFromLayout(contentLayout) {
  return contentLayout.map((item) => removeDraftsFromSubLayouts(item));
}
