import {
  RESOLVED_ORDERHISTORY_DATA,
  REJECTED_ORDERHISTORY_DATA,
  NO_ORDERHISTORY_DATA,
  NO_ORDERHISTORY_DATA_AVAILABLE,
  RESOLVED_COMBINED_ORDER_HISTORY,
  SET_GUEST_ORDER_HISTORY,
  LOADING_REPLENISHMENT_STATUS,
  RESOLVED_REPLENISHMENT_STATUS,
  REJECTED_REPLENISHMENT_STATUS,
  MAP_OH_ITEM_ADDRESSES,
  LOADING_ORDER_HISTORY_DATA,
  SET_RAW_OH_DATA,
  SET_FETCHED_ALL_OH,
  LOADING_CANCELLED_ORDERS,
  SET_CANCELLED_ORDER_HISTORY,
  REJECTED_ORDER_HISTORY,
  REJECTED_CANCELLED_ORDER_HISTORY,
  SET_SELECTED_REPLENISHABLE,
  SET_OH_SEARCH_TEXT,
  START_SEARCHING_OH,
} from 'profile/actions/actions';

const defaultState = {
  orderHistory: [],
  orderHistoryDetails: {
    isOrderHistoryAvail: false,
  },
  combinedOrderHistory: [],
  guestOrderHistory: {},
  hasCancelled: false,
  replenishmentStatus: {
    hasReplenishment: false,
    loading: false,
    error: '',
  },
  pagination: {
    loadingOrderHistory: false,
    rawOrderHistoryData: [],
    fetchedAll: false,
    error: false,
    searchText: '',
  },
  dtReplenishable: {
    selectedItem: {},
  },
  cancelledOrders: [],
  loadingCancelled: false,
  cancelledOrdersError: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_ORDERHISTORY_DATA: {
      return {
        ...state,
        orderHistory: action.payload,
      };
    }
    case REJECTED_ORDERHISTORY_DATA: {
      return {
        ...state,
        orderHistory: [],
      };
    }
    case NO_ORDERHISTORY_DATA:
      return {
        ...state,
        orderHistoryDetails: {
          ...state.orderHistoryDetails,
          isOrderHistoryAvail: false,
        },
      };
    case NO_ORDERHISTORY_DATA_AVAILABLE:
      return {
        ...state,
        orderHistoryDetails: {
          ...state.orderHistoryDetails,
          isOrderHistoryAvail: false,
        },
      };

    case RESOLVED_COMBINED_ORDER_HISTORY: {
      return {
        ...state,
        combinedOrderHistory: action.payload.orders,
        hasCancelled: action.payload.hasCancelled,
        pagination: {
          ...state.pagination,
          loadingOrderHistory: false,
        },
      };
    }
    case SET_GUEST_ORDER_HISTORY: {
      return {
        ...state,
        guestOrderHistory: action.payload,
      };
    }
    case LOADING_REPLENISHMENT_STATUS: {
      return {
        ...state,
        replenishmentStatus: {
          ...state.replenishmentStatus,
          loading: true,
        },
      };
    }
    case RESOLVED_REPLENISHMENT_STATUS: {
      return {
        ...state,
        replenishmentStatus: {
          ...state.replenishmentStatus,
          hasReplenishment: action.payload,
          loading: false,
        },
      };
    }
    case REJECTED_REPLENISHMENT_STATUS: {
      return {
        ...state,
        replenishmentStatus: {
          ...state.replenishmentStatus,
          hasReplenishment: false,
          error: action.payload,
          loading: false,
        },
      };
    }
    case MAP_OH_ITEM_ADDRESSES: {
      return {
        ...state,
        combinedOrderHistory: action.payload,
      };
    }
    case LOADING_ORDER_HISTORY_DATA: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          loadingOrderHistory: action.payload,
        },
      };
    }
    case SET_RAW_OH_DATA: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          rawOrderHistoryData: [...action.payload],
        },
      };
    }
    case SET_FETCHED_ALL_OH: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          fetchedAll: action.payload,
        },
      };
    }
    case LOADING_CANCELLED_ORDERS: {
      return {
        ...state,
        loadingCancelled: action.payload,
      };
    }
    case SET_CANCELLED_ORDER_HISTORY: {
      return {
        ...state,
        cancelledOrders: action.payload,
        loadingCancelled: false,
      };
    }
    case REJECTED_ORDER_HISTORY: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          loadingOrderHistory: false,
          error: true,
          fetchedAll: true,
        },
      };
    }
    case REJECTED_CANCELLED_ORDER_HISTORY: {
      return {
        ...state,
        loadingCancelled: false,
        cancelledOrdersError: true,
      };
    }
    case SET_SELECTED_REPLENISHABLE: {
      return {
        ...state,
        dtReplenishable: {
          ...state.dtReplenishable,
          selectedItem: action.payload,
        },
      };
    }
    case SET_OH_SEARCH_TEXT: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          searchText: action.payload,
        },
      };
    }
    case START_SEARCHING_OH: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          rawOrderHistoryData: [],
          fetchedAll: false,
          error: false,
        },
        combinedOrderHistory: [],
      };
    }
    default: {
      return state;
    }
  }
};
