/* eslint import/no-cycle: 0 */
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { concatTrackTagsObjects, getTrackingTags } from 'cms/components/utils';
import classNames from 'classnames';
import Carousel from '../carousel/carousel';
import SlideShow from '../slideShow/slideShow';

const CarouselComponent = (props) => {
  const { cmsContentItem, trackTags: propsTrackTags, siteTickerUpdate } = props;
  const cmsContentFields = get(cmsContentItem, 'fields', {});
  const ownTrackTags = get(cmsContentFields, 'trackingTags', []);
  const combinedTrackTags = concatTrackTagsObjects(propsTrackTags, getTrackingTags(ownTrackTags));
  const cmsContentAssets = get(cmsContentFields, 'components', []);
  const carouselType = cmsContentFields.type;

  switch (carouselType) {
    case 'Carousel': {
      return (
        <Carousel trackTags={combinedTrackTags} cmsContentItems={cmsContentAssets} />
      );
    }
    case 'Site Ticker': {
      return (
        <SlideShow
          trackTags={combinedTrackTags}
          cmsContentItems={cmsContentAssets}
          type={classNames('site-ticker', {
            'site-ticker-new': siteTickerUpdate
          })}
        />
      );
    }
    case 'Carousel Animation Slow':
    case 'Carousel Animation Fast': {
      return (
        <SlideShow
          trackTags={combinedTrackTags}
          cmsContentItems={cmsContentAssets}
          type="carousel-animation"
          speedType={carouselType}
        />
      );
    }
    case 'Carousel Animation Custom': {
      const transitionTime = get(cmsContentFields, 'transitionTime', []);

      return (
        <SlideShow
          trackTags={combinedTrackTags}
          cmsContentItems={cmsContentAssets}
          type="carousel-animation"
          speedType={carouselType}
          animationSpeed={750}
          transitionTime={transitionTime}
        />
      );
    }
    default: {
      return (<div />);
    }
  }
};

const mapStateToProps = (state) => ({
  siteTickerUpdate: get(state, 'toggles.NMO_ADA_HEADER', false),
});

export default connect(mapStateToProps)(CarouselComponent);
