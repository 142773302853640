import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { setStoryClassification } from 'cms/actions';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import { CMSSiloLayoutTypes } from 'cms/constants';
// eslint-disable-next-line import/no-cycle
import RenderLayout from 'cms/components/renderLayout';

const layoutPattern = [1, 2, 1, 3];
const STORIES_PER_BLOCK = 7;

const getStoryFromSummary = (story, index) => {
  const getL1Layouts = get(story, 'fields.l1Layouts', []);
  const storySummary = getL1Layouts.find((elem) => elem.fields.placement === 'Editorial-story-summary');
  const getStorySummaryL2Layouts = get(storySummary, 'fields.l2Layout', []);
  const storyLayoutType = CMSSiloLayoutTypes[index % CMSSiloLayoutTypes.length];
  return getStorySummaryL2Layouts.find(
    (elem) => elem.fields.desktopWidth === storyLayoutType.type,
  );
};

const getLayout = (items, isFullBleedLayout) => {
  const changedContentTypeItems = items.map((item) => {
    return {
      ...item,
      contentType: 'l3Layout',
    };
  });

  return {
    contentType: 'l2Layout',
    fields: {
      isFullBleedLayout,
      l3Layout: changedContentTypeItems,
    },
  };
};

export class EditorialSilo extends Component {
  componentDidMount() {
    this.dispatchClassification();
    window.addEventListener('hashchange', this.dispatchClassification);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.dispatchClassification);
  }

  dispatchClassification = () => {
    this.props.dispatch(setStoryClassification(window.location.hash.replace('#', '')));
  };

  render() {
    const { frames, classification } = this.props;
    if (!frames.length) return null;
    const filteredStories = () => frames.filter((item) => {
      const storyClassification = get(item, 'fields.metaData.fields.classifications', []);
      return storyClassification.includes(classification);
    });

    const storiesToDisplay = classification ? filteredStories() : frames;
    // eslint-disable-next-line max-len
    const storiesLayout = storiesToDisplay.map((item, index) => getStoryFromSummary(item, index)).filter(Boolean);
    const bpArray = new Array(Math.ceil(storiesLayout.length / STORIES_PER_BLOCK))
      .fill(layoutPattern);
    const flatBpArray = flatten(bpArray);
    const layout = [];
    for (let i = 0; i < flatBpArray.length; i++) {
      if (storiesLayout.length === 0) break;
      layout.push(getLayout(storiesLayout.splice(0, flatBpArray[i]), !(i % 2)));
    }

    return (
      <>
        {layout.map((item) => (
          <RenderLayout
            key={uuid()}
            cmsLayout={item}
          />
        ))}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    classification: state.cms.classification,
    frames: get(state, 'cms.stories', []),
  };
};

export default connect(mapStateToProps)(EditorialSilo);
