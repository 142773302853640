/* eslint import/no-cycle: 0 */
import React from 'react';
import VideoAsset from '../video/videoAsset';
import ImageWithText from '../imageWithText/imageWithText';
import { concatTrackTagsObjects, getTrackingTags } from '../utils';
import './VideoBackground.scss';

function VideoBackground({
  cmsContentItem = {},
  trackTags: propsTrackTags = {},
}) {
  const {
    video,
    textWithImage = [],
    trackingTags: ownTrackTags = [],
  } = cmsContentItem?.fields;
  const combinedTrackTags = concatTrackTagsObjects(propsTrackTags, getTrackingTags(ownTrackTags));

  return (
    <div className="cms-video-background">
      <div className="cms-video-container">
        <VideoAsset cmsContentItem={video} />
      </div>
      <div className="cms-iwt-container">
        {textWithImage.map((item, index) => (
          <div
            key={index}
            className="cms-iwt-item"
          >
            <ImageWithText
              cmsContentItem={item}
              trackTags={combinedTrackTags}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default VideoBackground;
