/* eslint react/no-danger: 0 */
import React from 'react';
import classnames from 'classnames';
import { renderFormattedPrice } from 'client-utils/utilities-price';
import './promotions.scss';

const getValidHtml = (itemHTML) => itemHTML || '';

const innerHTML = (itemHTML, promotionalPrice, currencyCode) => {
  const validHtmlText = getValidHtml(itemHTML);
  return promotionalPrice
    ? `${validHtmlText} ${renderFormattedPrice(promotionalPrice, currencyCode)}`
    : validHtmlText;
};

const getStyle = (promoColor, promotionalPrice, plpGroupsRefreshToggle) => ({ color: promoColor && promotionalPrice ? `#${promoColor}` : undefined, display: plpGroupsRefreshToggle ? 'block': undefined });

const getClassNames = (promotionalPrice, newProductThumbStyle, promoInRed, promoPriority) => classnames('product-thumbnail__promotions__element', {
  'product-thumbnail__promotions__element--priced': promotionalPrice,
}, {
  enhanced: true,
  badge: newProductThumbStyle,
  red: promoInRed,
  'badge__priority-promo': promoPriority
});

const innerHTMLForPromoWithPrice = (promoText, promotionalPrice) => {
  const validHtmlText = getValidHtml(promoText);
  return promotionalPrice
    ? `${validHtmlText} ${promotionalPrice}`
    : validHtmlText;
};

const Promotions = ({
  promotions = [],
  currencyCode,
  promotionalPrice,
  isPLP,
  advertisePromos = [],
  promoColor,
  promoText,
  plpSRPPricingToggle,
  isUIPRICTest,
  newProductThumbStyle = false,
  plpGroupsRefreshToggle =false,
  promoPriority = false,
  promoInRed = false
}) => {
  const plpPromoRender = (
    <>
      {(promoText || promotionalPrice) && (
        <div
          style={getStyle(promoColor, promotionalPrice, plpGroupsRefreshToggle)}
          className={getClassNames(
            promotionalPrice,
            newProductThumbStyle,
            promoInRed
          )}
          dangerouslySetInnerHTML={{
            __html: innerHTMLForPromoWithPrice(promoText, promotionalPrice),
          }}
        />
      )}
      {advertisePromos.map((advPromoText, index) => (
        <div
          key={index}
          style={getStyle(promoColor, promotionalPrice)}
          className={getClassNames(
            promotionalPrice,
            newProductThumbStyle,
            promoInRed,
            promoPriority
          )}
          dangerouslySetInnerHTML={{
            __html: innerHTMLForPromoWithPrice(advPromoText, promotionalPrice),
          }}
        />
      ))}
    </>
  );

  const srpPromoRender = (
    <>
      {promotions.map((promotion, index) => {
        const { templateHTML, thumbnailPromoColor } = promotion;
        const style = {
          color:
            thumbnailPromoColor && promotionalPrice
              ? `#${thumbnailPromoColor}`
              : undefined,
        };
        const className = classnames(
          'product-thumbnail__promotions__element',
          {
            'product-thumbnail__promotions__element--priced': promotionalPrice,
          },
          {
            enhanced: true,
          }
        );
        return (
          <div
            key={index}
            style={style}
            className={className}
            dangerouslySetInnerHTML={{
              __html: innerHTML(templateHTML, promotionalPrice, currencyCode),
            }}
          />
        );
      })}
    </>
  );


  return isPLP
    ? (
      newProductThumbStyle
        ? (
          <>
            {plpPromoRender}
          </>
        )
        : (
          <div className={classnames('product-thumbnail__promotions', { 'price-enhancement': isUIPRICTest || plpSRPPricingToggle })}>
            {plpPromoRender}
          </div>
        )
    )
    : (
      newProductThumbStyle
        ? (
          <>
            {srpPromoRender}
          </>
        )
        : (
          <div className={classnames('product-thumbnail__promotions', { 'price-enhancement': isUIPRICTest || plpSRPPricingToggle })}>
            {srpPromoRender}
          </div>
        ));
};

export default Promotions;
