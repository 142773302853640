/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["config"] }] */

import isEmpty from 'lodash/isEmpty';
import { create } from 'axios';
import uuid from 'uuid/v4';
import { X_FEATURE_TOGGLES_HEADER_NAME } from 'universal/custom-http-headers';
import { extractLogData } from 'universal/xhrLogFormatter';
import logger, { X_NMO_TRACE_ID_NAME } from 'server/utilities/logger';
import get from 'lodash/get';

const createApi = (context = {}, timeout = 3000, toggles = {}) => {
  let startTime = null;
  const api = create({ timeout });

  api.interceptors.request.use(
    (config) => {
      if (!context.isHeadersPredefined) {
        const cookies = get(config.headers, 'Cookie', '');
        const isSlsCall = get(config.headers, 'sls-call', false);

        const whitelistedCookies = !isEmpty(NMConfig.WHITE_LISTED_COOKIE_NAMES) && toggles.WHITELIST_COOKIES ? cookies.split(';').filter((current) => {
          const [name, value] = current.trim().split('=');
          return (!isEmpty(name) && !isEmpty(value)
            && NMConfig.WHITE_LISTED_COOKIE_NAMES.indexOf(name) !== -1);
        }) : [];

        if (toggles && toggles.NO_TOGGLES_HEADER) {
          if (!IS_CLIENT) {
            config.headers = {
              ...config.headers,
              Cookie: toggles.WHITELIST_COOKIES ? whitelistedCookies.join('; ') : cookies,
              [X_NMO_TRACE_ID_NAME]: context[X_NMO_TRACE_ID_NAME] || uuid(),
              [X_FEATURE_TOGGLES_HEADER_NAME]:
              config.headers[X_FEATURE_TOGGLES_HEADER_NAME]
              || JSON.stringify(toggles),
            };
          } else {
            config.headers = {
              ...config.headers,
              Cookie: toggles.WHITELIST_COOKIES ? whitelistedCookies.join('; ') : cookies,
              [X_NMO_TRACE_ID_NAME]: context[X_NMO_TRACE_ID_NAME] || uuid(),
            };
          }
        } else if (isSlsCall !== 'true') {
          config.headers = {
            ...config.headers,
            Cookie: toggles.WHITELIST_COOKIES ? whitelistedCookies.join('; ') : cookies,
            [X_NMO_TRACE_ID_NAME]: context[X_NMO_TRACE_ID_NAME] || uuid(),
            [X_FEATURE_TOGGLES_HEADER_NAME]:
              config.headers[X_FEATURE_TOGGLES_HEADER_NAME]
              || JSON.stringify(toggles),
          };
        }
      }
      startTime = new Date();
      logger.debug('HTTP Request (internal)', extractLogData(context, config));
      return config;
    },
    (error) => {
      logger.error(
        `HTTP Request Error (internal): ${error.message}`,
        extractLogData(context, error.config, error.request, error.response),
      );
      return Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (response) => {
      logger.info('HTTP Response (internal)', {
        ...extractLogData(
          context,
          response.config,
          response.request,
          response,
          startTime,
        ),
        TemplateType: get(response.data, 'templateType', '-'),
      });
      return Promise.resolve(response);
    },
    (error) => {
      logger.error(
        `HTTP Response Error (internal): ${error.message}`,
        extractLogData(context, error.config, error.request, error.response),
      );
      return Promise.reject(error);
    },
  );
  return api;
};

const httpWithLogging = (
  state,
  timeout = 3000,
  isHeadersPredefined = false,
  route
) => {
  let toggles = state.toggles;
  let isProductListingAPI = false;
  if (route?.length) {
    const [routeName = ''] = route.split && route.split('/').reverse() || [];
    isProductListingAPI = routeName?.toLowerCase() === "productlisting";
  }
  if(state?.toggles?.NO_TOGGLES_HEADER || isProductListingAPI){
    toggles = state.serviceToggles;
  }
  return createApi(
  {
    ...state.user,
    ...state.session,
    isHeadersPredefined,
  },
  timeout,
  toggles
)};

export default httpWithLogging;

export function apiForHttpRequest(timeout = 3000) {
  return createApi({}, timeout);
}

export const getSessionInfo = (cookies = {}) => ({
  user: {},
  session: {
    JSESSIONID: cookies.JSESSIONID || '',
    DYN_USER_ID: cookies.DYN_USER_ID || '',
    TLTSID: cookies.TLTSID || '',
    W2A: cookies.W2A || '',
  },
});

export function shouldLoad(status = {}) {
  return (
    isEmpty(status) || (!status.loading && !status.resolved && !status.rejected)
  );
}
