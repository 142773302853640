import get from 'lodash/get';
import { formatFilterKey } from 'client-utils/utilities-router';

export const seoSelectedFilter = (filterData, queryString = '') => {
  const seoSelections = {};
  const applicableFilters = get(filterData, 'applicableFilters', []);
  const nextParams = queryString?.split('&');
  const seoFilterKeyMap = {};
  applicableFilters.map((fo) => {
    const formatDisplayKey = formatFilterKey(fo.displayText).toLowerCase();
    seoFilterKeyMap[formatDisplayKey] = fo.filterKey;
    seoSelections[fo.filterKey] = [];
    if (fo.filterKey === 'In Store') {
      seoFilterKeyMap['get-it-fast'] = 'In Store';
      seoSelections[fo.filterKey] = ['', '', '', ''];
    }
  });
  if (queryString) {
    // Parse url and re-assign selectedFilters object
    const nextParamData = nextParams?.filter((p) => p?.indexOf('='));
    nextParamData.map((e) => {
      const [k, v] = e?.split('=');
      if (seoFilterKeyMap[k]) {
        seoSelections[seoFilterKeyMap[k]] = decodeURIComponent(v)?.split('|');
      }
    });
  }
  return seoSelections;
};
