import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export const booleanToString = (value) => Boolean(value).toString();

export const isSwatchProduct = (options) => {
  const hasSwatch = (options.productOptions || [])
    .some((productOption) => productOption.label === 'color' && !isEmpty(productOption.values[0].url));
  return booleanToString(hasSwatch);
};

export const isExclusiveProduct = (productFlags) => {
  const hasProductFlag = productFlags ? productFlags.isOnlyAtNM : [];
  return booleanToString(hasProductFlag);
};

export const getSaleType = (state) => {
  if (state.details.clearanceItem) return 'Clearance';
  if (state.price.adornments) return 'Sale';
  return 'Regular';
};

export const getEDWPricingType = (state) => {
  if (!isEmpty(state.promotions) && state.price.promotionalPrice && state.price.adornments) return 'CP';
  if (state.price.adornments) return 'C';
  if (state.promotions && state.price.promotionalPrice) return 'RP';
  return 'R';
};

export const getDropShipFlag = (state) => (
  state.skus.some((sku) => sku.dropShip));

export const getTotalSkuCount = (state) => state.skus.length;

export const getAvailableSkuCount = (state) => state.skus.map((sku) => booleanToString(sku.sellable)).filter((availableSku) => availableSku === 'true').length;

export const getReviewCount = (state) => (
  get(state, 'ratingAndReviews.reviewCount', 0).toString()
);

export const getReviewRating = (state) => (
  get(state, 'ratingAndReviews.averageRating', false).toString()
);

export default (state) => ({
  product_type: ['non-group'],
  product_brand: state.designer && state.designer.name,
  product_id: [state.id],
  product_name: [state.name],
  product_cmos_catalog_id: [state.metadata.cmosCatalogId],
  product_cmos_item: [state.metadata.cmosItem],
  product_price: [state.price.promotionalPrice
    ? state.price.promotionalPrice : state.price.retailPrice],
  product_pricing_adornment_flag: [booleanToString(state.price.adornments)],
  product_swatch: isSwatchProduct(state.options || { productOptions: [] }),
  product_review_count: getReviewCount(state),
  product_review_rating: getReviewRating(state),
  product_cmos_sku: state.skus.map((sku) => sku.metadata.cmosSkuId),
  product_expected_availability: state.skus.map((sku) => sku.expectedShipDays || ''),
  product_sellable_sku: state.skus.map((sku) => booleanToString(sku.sellable)),
  product_sale_type: [getSaleType(state)],
  product_suppress_checkout_flag:
    booleanToString(state.details.suppressCheckout),
  product_available: [booleanToString(state.displayable)],
  product_dropship_flag: [booleanToString(getDropShipFlag(state))],
  product_nm_exclusive: [isExclusiveProduct(state.productFlags)],
  total_sku_count: getTotalSkuCount(state),
  available_sku_count: getAvailableSkuCount(state),
  edw_pricing_adornment: [getEDWPricingType(state)],
});
