
import {
  SET_REDIRECT_ON_PERSONAL_PROMO_PAGE,
  SET_REDIRECT_ON_ACCOUNT_OVERVIEW_PAGE,
  SET_REDIRECT_ON_RESET_PASSWORD_PAGE,
  RESOLVED_PERSONALIZED_PROMOTIONS,
  REJECTED_PERSONALIZED_PROMOTIONS,
  NO_PERSONALIZED_PROMOTIONS_AVAILABLE,
  RESOLVED_CCPA_FORM,
  REJECTED_CCPA_FORM,
  RESOLVED_RECAPTCHA,
  REJECTED_RECAPTCHA,
  REJECTED_SEND_EMAIL,
  RESOLVED_SEND_EMAIL,
  RESOLVED_RESET_PASSWORD,
  REJECTED_RESET_PASSWORD,
} from 'profile/actions/actions';

const defaultState = {
  promotions: [],
  personalPromoRedirectURL: '',
  personalPromoRedirectType: 302,
  promoAvail: {
    noPromoAvail: false,
  },
};

const defaultAcctOverviewState = {
  acctOverviewRedirectURL: '',
  acctOverviewRedirectType: 302,
};

const defaultResetPasswordState = {
  resetPasswordRedirectURL: '',
  resetPasswordRedirectType: 302,
  resetPasswordSuccessful: false,
  resetPasswordFailed: false,
};

const defaultCcpaFormState = {
  ccpaSubmitSuccesful: false,
  ccpaSubmitFailed: false,
  resolvedReCaptcha: false,
  rejectedReCaptcha: false,
};
const defaultDtLoginState = {
  sendForgotPwEmailFailed: false,
  sendForgotPwEmailSuccess: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESOLVED_PERSONALIZED_PROMOTIONS: {
      return {
        ...state,
        promotions: action.payload,
      };
    }
    case REJECTED_PERSONALIZED_PROMOTIONS: {
      return {
        ...state,
        promotions: [],
      };
    }
    case SET_REDIRECT_ON_PERSONAL_PROMO_PAGE: {
      return {
        ...state,
        personalPromoRedirectURL: action.payload.targetURL,
        personalPromoRedirectType: action.payload.redirectType,
      };
    }
    case NO_PERSONALIZED_PROMOTIONS_AVAILABLE:
      return {
        ...state,
        promoAvail: {
          ...state.promoAvail,
          noPromoAvail: true,
        },
      };
    default: {
      return state;
    }
  }
};

export const acctOverviewReducer = (state = defaultAcctOverviewState, action) => {
  switch (action.type) {
    case SET_REDIRECT_ON_ACCOUNT_OVERVIEW_PAGE:
      return {
        ...state,
        acctOverviewRedirectURL: action.payload.targetURL,
        acctOverviewRedirectType: action.payload.redirectType,
      };
    default:
      return state;
  }
};

export const resetPasswordReducer = (state = defaultResetPasswordState, action) => {
  switch (action.type) {
    case SET_REDIRECT_ON_RESET_PASSWORD_PAGE:
      return {
        ...state,
        resetPasswordRedirectURL: action.payload.targetURL,
        resetPasswordRedirectType: action.payload.redirectType,
      };
    case RESOLVED_RESET_PASSWORD:
      return {
        ...state,
        resetPasswordSuccessful: true,
      };
    case REJECTED_RESET_PASSWORD:
      return {
        ...state,
        resetPasswordFailed: true,
      };
    default:
      return state;
  }
};

export const ccpaFormReducer = (state = defaultCcpaFormState, action) => {
  switch (action.type) {
    case RESOLVED_CCPA_FORM:
      return {
        ...state,
        ccpaSubmitSuccesful: true,
      };
    case REJECTED_CCPA_FORM:
      return {
        ...state,
        ccpaSubmitFailed: true,
      };
    case RESOLVED_RECAPTCHA:
      return {
        ...state,
        resolvedReCaptcha: true,
      };
    case REJECTED_RECAPTCHA:
      return {
        ...state,
        rejectedReCaptcha: true,
      };
    default:
      return state;
  }
};
export const dtLoginReducer = (state = defaultDtLoginState, action) => {
  switch (action.type) {
    case REJECTED_SEND_EMAIL:
      return {
        ...state,
        sendForgotPwEmailFailed: true,
      };
    case RESOLVED_SEND_EMAIL:
      return {
        ...state,
        sendForgotPwEmailSuccess: true,
      };
    default:
      return state;
  }
};
