import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
// eslint-disable-next-line import/no-cycle
import RenderContentSlot from '../renderContentSlot';
import { getEditorialStories } from '../../actions';
import './storyRail.scss';


export class StoryRail extends Component {
  getCurrentStory(editorialStories, storyRailId, param) {
    for (let i = 0; i < editorialStories.length; i++) {
      const metaContentAsset = editorialStories[i].fields.metaContentAsset;
      if (metaContentAsset.fields.identifier === param) {
        const contentSlots = editorialStories[i].fields.contentSlots;
        for (let j = 0; j < contentSlots.length; j++) {
          const contentAssets = contentSlots[j].fields?.contentAssets;
          if (Array.isArray(contentAssets)) {
            for (let k = 0; k < contentAssets.length; k++) {
              if (contentAssets[k].sys.id === storyRailId) {
                return editorialStories[i];
              }
            }
          }
        }
      }
    }
    return {};
  }

  getClassificationStories(editorialStories, classification, currentStory) {
    const storiesArr = editorialStories.filter((item) => {
      const editorialStoryMetaContentAsset = item?.fields?.metaContentAsset;
      /* eslint-disable-next-line max-len */
      const editorialStoryWithClassification = editorialStoryMetaContentAsset?.fields?.classification;
      const isCurrentStory = item?.sys?.id === currentStory?.sys?.id;
      return !isCurrentStory && editorialStoryWithClassification?.includes(classification);
    });
    return storiesArr;
  }

  // eslint-disable-next-line react/sort-comp
  filterDuplicates(moreClassificationStories, classificationStories) {
    return moreClassificationStories.filter((item) => classificationStories.indexOf(item) === -1);
  }

  // eslint-disable-next-line react/sort-comp
  removeStories(editorialStories, currentStory, classificationStories) {
    /* eslint-disable-next-line max-len */
    const storiesWithoutCurrent = editorialStories.filter((item) => item?.sys?.id !== currentStory?.sys?.id);
    /* eslint-disable-next-line max-len */
    const storiesWithoutDuplicates = this.filterDuplicates(storiesWithoutCurrent, classificationStories);
    const stories = classificationStories.concat(storiesWithoutDuplicates);
    return stories.slice(0, 3);
  }

  getStoryRecommendations(editorialStories, classifications, currentStory) {
    /* eslint-disable-next-line max-len */
    let classificationStories = this.getClassificationStories(editorialStories, classifications[0], currentStory);
    if (classificationStories.length < 3 && classifications.length >= 2) {
      const classificationsWithoutFirst = classifications.slice(1);
      for (let i = 0; i < classificationsWithoutFirst.length; i++) {
        /* eslint-disable-next-line max-len */
        const moreClassificationStories = this.getClassificationStories(editorialStories, classificationsWithoutFirst[i], currentStory);
        /* eslint-disable-next-line max-len */
        const storiesWithoutDuplicates = this.filterDuplicates(moreClassificationStories, classificationStories);
        const stories = classificationStories.concat(storiesWithoutDuplicates);
        if (stories.length >= 3) {
          return stories.slice(0, 3);
        }
        if (stories.length < 3) {
          classificationStories = stories;
        }
      }
      if (classificationStories.length < 3) {
        return this.removeStories(editorialStories, currentStory, classificationStories);
      }
    }
    if (classificationStories.length < 3) {
      return this.removeStories(editorialStories, currentStory, classificationStories);
    }
    return classificationStories.slice(0, 3);
  }

  UNSAFE_componentWillMount() {
    const { cmsStoriesApi } = this.props;
    if (shouldLoad(cmsStoriesApi)) {
      this.props.dispatch(getEditorialStories('/editorial'));
    }
  }

  renderStoryRail(storyRecommendations) {
    const layout = storyRecommendations.map((item) => {
      const editorialStoryContentSlots = get(item, 'fields.contentSlots', []);
      const editorialStorySummary = find(editorialStoryContentSlots, { fields: { type: 'Editorial Summary' } });
      const editorialStorySummaryContentSlots = get(editorialStorySummary, 'fields.contentSlots', []);
      const CMSSiloLayoutType = { layout: 'Layout 33/33/33', write: true };
      const editorialStorySummaryContent = find(editorialStorySummaryContentSlots,
        { fields: { type: CMSSiloLayoutType.layout } });
      const editorialStorySummaryContentElement = { ...get(editorialStorySummaryContent, 'fields.contentAssets[0]', {}) };

      if (!this.layout) {
        this.layout = {
          ...editorialStorySummaryContent,
          fields: { ...editorialStorySummaryContent.fields },
        };
        this.layout.fields.contentAssets = [];
      }

      this.layout.fields.contentAssets.push(editorialStorySummaryContentElement);
      return this.layout;
    });
    return (
      <>
        <RenderContentSlot cmsContentSlot={layout?.[0]} />
      </>
    );
  }

  render() {
    const { editorialStories, cmsContentItem, session } = this.props;
    const url = session?.url.split('/editorial')[1];
    const param = ('/editorial').concat(url);
    const storyRailId = cmsContentItem.sys.id;
    const currentStory = this.getCurrentStory(editorialStories, storyRailId, param);
    const classifications = get(currentStory, 'fields.metaContentAsset.fields.classification', []);
    /* eslint-disable-next-line max-len */
    const storyRecommendations = this.getStoryRecommendations(editorialStories, classifications, currentStory);

    return (
      <div className="story-rail-container">
        <div className="title">You May Also Like</div>
        <div className="story-rail">
          {storyRecommendations && this.renderStoryRail(storyRecommendations)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cmsStoriesApi: state.api.editorial_stories,
    editorialStories: state.cms.stories,
    session: state.session,
  };
};

export default connect(mapStateToProps)(StoryRail);
