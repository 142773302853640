export const CMSLayouts = {
  'Layout 100': [
    'cms-column grid-100 tablet-grid-100 mobile-grid-100 grid-parent',
  ],
  'Layout 100 Full Bleed': [
    'cms-column grid-100 tablet-grid-100 mobile-grid-100 grid-parent',
  ],
  'Layout 60/40': [
    'cms-column grid-60 tablet-grid-60 mobile-grid-100 grid-parent',
    'cms-column grid-40 tablet-grid-40 mobile-grid-100 grid-parent',
  ],
  'Layout 60/40 Full Bleed': [
    'cms-column grid-60 tablet-grid-60 mobile-grid-100 grid-parent',
    'cms-column grid-40 tablet-grid-40 mobile-grid-100 grid-parent',
  ],
  'Layout 66/33': [
    'cms-column grid-66 tablet-grid-66 mobile-grid-100 grid-parent',
    'cms-column grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
  ],
  'Layout 66/33 Full Bleed': [
    'cms-column grid-66 tablet-grid-66 mobile-grid-100 grid-parent',
    'cms-column grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
  ],
  'Layout 50/50': [
    'cms-column grid-50 tablet-grid-50 mobile-grid-100 grid-parent',
    'cms-column grid-50 tablet-grid-50 mobile-grid-100 grid-parent',
  ],
  'Layout 50/50 Full Bleed': [
    'cms-column grid-50 tablet-grid-50 mobile-grid-100 grid-parent',
    'cms-column grid-50 tablet-grid-50 mobile-grid-100 grid-parent',
  ],
  'Layout 33/33/33': [
    'cms-column grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
    'cms-column grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
    'cms-column grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
  ],
  'Layout 33/33/33 Full Bleed': [
    'cms-column grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
    'cms-column grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
    'cms-column grid-33 tablet-grid-33 mobile-grid-100 grid-parent',
  ],
  'Layout 25/25/25/25': [
    'cms-column grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
    'cms-column grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
    'cms-column grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
    'cms-column grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
  ],
  'Layout 25/25/25/25 Full Bleed': [
    'cms-column grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
    'cms-column grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
    'cms-column grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
    'cms-column grid-25 tablet-grid-25 mobile-grid-50 grid-parent',
  ],
  'Layout 20/20/20/20/20': [
    'cms-column grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'cms-column grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'cms-column grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'cms-column grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'cms-column grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
  ],
  'Layout 20/20/20/20/20 Full Bleed': [
    'cms-column grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'cms-column grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'cms-column grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'cms-column grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
    'cms-column grid-20 tablet-grid-20 mobile-grid-50 grid-parent',
  ],
};

export const CMSSiloLayoutTypes = [
  { layout: 'Layout 100 Full Bleed', write: true, type: '100' },
  { layout: 'Layout 50/50', write: false, type: '50' },
  { layout: 'Layout 50/50', write: true, type: '50' },
  { layout: 'Layout 100 Full Bleed', write: true, type: '100' },
  { layout: 'Layout 33/33/33', write: false, type: '33' },
  { layout: 'Layout 33/33/33', write: false, type: '33' },
  { layout: 'Layout 33/33/33', write: true, type: '33' },
];

export const CMSParams = {
  CMS_PREVIEW: 'CMS_Preview',
  CMS_TAGS: 'CMS_Tags',
  cmsSchedule: 'cmsSchedule',
};

export const Channels = {
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
};

export const TrackingTagsMaxLength = {
  'Brand + Channel': 4,
  '2nd Group': 2,
  '3rd Group': 4,
  'Campaign Type': 4,
  Vendor: 16,
  Category: 8,
  'Campaign Name': null,
};

export const PRODUCT_GRID = {
  DEFAULT_NUMBER_OF_PRODUCTS: 16,
};

export const MAIN_SHOT_TYPE = 'm';
export const CURATED = 'CURATED';
export const VISUALNAV_SOURCE  = 'source=vn';
export const PANEL = 'PANEL';
