import React from 'react';
import classNames from 'classnames';
import includes from 'lodash/includes';
import { unescape } from 'he';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import './generalFilter.scss';

const renderMatchingDesignerListResultCharacters = (
  label,
  designerSearchText
) => {
  const term = designerSearchText;

  if (designerSearchText !== '') {
    const termMatch = label.match(new RegExp(term, 'ig'));
    if (termMatch) {
      return label.replace(termMatch[0], `<span>${termMatch[0]}</span>`);
    }
    return null;
  }

  return label;
};

const generalFilter = ({
  facets,
  filterKey,
  onFilterValueClick,
  selectedValues,
  facetsOrderList,
  isRecentSizes = false,
  designerSearchText,
}) => {
  const validFacets = filter(
    facetsOrderList,
    (facetKey) => !isNil(facets[facetKey])
  );
  let matchFound = false;
  const isDesignerFacet = filterKey === 'Designer';

  let filterOptions = (
    <ul
      className={classNames({
        'facet-column': true,
        'recent-sizes-column': isRecentSizes,
        'general-facet-column': !isRecentSizes,
        'designer-facet': isDesignerFacet,
      })}
    >
      {validFacets.map((key, index) => {
        const label = filterKey === 'Designer'
          ? renderMatchingDesignerListResultCharacters(
            unescape(key),
            designerSearchText
          )
          : key;
        const classNames = 'faceted-left-nav__facet__label__srp';
        const facetItem = 'facet-item-srp';
        const uniqueId = `${filterKey}_${key}_${index}`;
        const recentSize = isRecentSizes ? 'Recent Size: ' : '';
        const designerOnlyMargin = filterKey === 'Designer'
          ? { marginLeft: '20px', width: '90%' }
          : {};
        const isItemSelected = selectedValues
          && selectedValues.indexOf(key) > -1
          && 'selected-item-srp';
        if (label) {
          if (!matchFound) matchFound = true;
          return (
            <li
              className={facetItem}
              key={index}
              onClick={(event) => {
                event.preventDefault();
                onFilterValueClick(filterKey, key, event);
              }}
              style={designerOnlyMargin}
            >
              <div
                tabIndex="0"
                onKeyDown={(event) => {
                  if (event.keyCode === 13 || event.keyCode === 32) {
                    event.preventDefault();
                    onFilterValueClick(filterKey, key, event);
                  }
                }}
                role="checkbox"
                aria-checked={includes(selectedValues, key)}
              >
                <input
                  type="checkbox"
                  id={uniqueId}
                  checked={includes(selectedValues, key)}
                  readOnly
                />
                <label
                  htmlFor={uniqueId}
                  aria-label={`${recentSize}Option ${key} with ${
                    facets[key]
                  } items`}
                >
                  <span aria-hidden="true">
                    {filterKey === 'Color' && (
                      <span className={`color-filter-option ${key}`} />
                    )}
                    <span
                      className={`${classNames} ${isItemSelected}`}
                      dangerouslySetInnerHTML={{ __html: label }}
                    />
                  </span>
                </label>
              </div>
            </li>
          );
        }
        return null;
      })}
    </ul>
  );

  if (!matchFound) {
    filterOptions = (
      <div className="faceted-left-nav__designer_filter__noresults">
        {'No results found'}
      </div>
    );
  }

  return filterOptions;
};

export default generalFilter;
