import reactCookie from 'react-cookie';
import dayjs from 'dayjs';
import { setTimeout } from 'window-or-global';

export const isChatOpened = () => {
  let isChatOpenedState = false;
  if (window.NeimanMarcusWebchat && window.NeimanMarcusWebchat.manager) {
    const { flex } = window.NeimanMarcusWebchat.manager.store.getState();
    const { isEntryPointExpanded } = flex.session;
    isChatOpenedState = isEntryPointExpanded;
  }

  return isChatOpenedState;
};

export const isEnableProactiveChat = () => {
  let isChatDeclined = false;
  const proactiveChatEndedAt = window?.localStorage.getItem('proactiveChatEndedAt') !== null ? JSON.parse(window.localStorage.getItem('proactiveChatEndedAt')) : -1;
  const hoursSinceChatDeclined = proactiveChatEndedAt !== -1 ? dayjs().diff(dayjs(proactiveChatEndedAt), 'hours') : 0;
  isChatDeclined = hoursSinceChatDeclined <= 24 && proactiveChatEndedAt !== -1;
  return !isChatDeclined;
};

export const openProactiveChat = ({
  expHoursForTwilioProactive,
  product,
  eventType,
  page,
  proactiveChatDelay = 30000,
}) => {
  if (page === 'contentful') {
    window.NeimanMarcusWebchat.openProactiveChat({
      currentUrl: window.location.href,
      eventType,
      product,
      page,
    }).then((result) => {
      if (result) {
        reactCookie.save(`${product}-isProactiveTwilioChatDismissed`, true, {
          path: '/',
          domain: `${window.location.hostname}`,
          expires: new Date(
            Date.now() + (expHoursForTwilioProactive * 60 * 60 * 1000)
          ),
        });
      }
    }).catch((err) => err);
  } else {
    window.onload = () => {
      setTimeout(() => {
        if (!isChatOpened()) {
          window.NeimanMarcusWebchat.openProactiveChat({
            currentUrl: window.location.href,
            eventType,
            product,
            page,
          }).then((result) => {
            if (result) {
              reactCookie.save(`${product}-isProactiveTwilioChatDismissed`, true, {
                path: '/',
                domain: `${window.location.hostname}`,
                expires: new Date(
                  Date.now() + (expHoursForTwilioProactive * 60 * 60 * 1000)
                ),
              });
            }
          }).catch((err) => err);
        }
      }, proactiveChatDelay);
    };
  }
};

export const openProactiveChatOnEvent = ({
  expHoursForTwilioProactive,
  product,
  eventType,
  page,
  proactiveChatDelay = 30000,
}) => {
  window.addEventListener('webchat_initialized', () => {
    if (!isChatOpened()) {
      setTimeout(() => {
        const currentlyOpen = isChatOpened();
        console.info('WEB_CHAT: Proactive chat starting soon.',
          {
            currentlyOpen,
            event: {
              currentUrl: window.location.href, eventType, product, page,
            },
          });
        if (!currentlyOpen) {
          window.NeimanMarcusWebchat.openProactiveChat({
            currentUrl: window.location.href,
            eventType,
            product,
            page,
          }).then((result) => {
            if (result) {
              reactCookie.save(`${product}-isProactiveTwilioChatDismissed`, true, {
                path: '/',
                domain: `${window.location.hostname}`,
                expires: new Date(
                  Date.now() + (expHoursForTwilioProactive * 60 * 60 * 1000)
                ),
              });
            }
          }).catch((err) => err);
        }
      }, proactiveChatDelay);
    }
  });
};
