import { formatMoney, formatNumber } from 'accounting';

export function renderFormattedPrice(price, currencyCode = '$') {
  const currencyFormat = (currencyCode === '$' ? '%s%v' : '%s %v');
  const currencyThousand = (currencyCode === '$' ? ',' : '');
  return Number.isInteger(parseFloat(price))
    ? formatMoney(price,
      {
        symbol: currencyCode,
        precision: 0,
        format: currencyFormat,
        thousand: currencyThousand,
      })
    : formatMoney(price,
      {
        symbol: currencyCode,
        precision: 2,
        format: currencyFormat,
        thousand: currencyThousand,
      });
}

export function renderFormattedNumber(number) {
  return formatNumber(number);
}

export function renderFormattedPromoPrice(promoText, promotionalPrice) {
  let label = '';
  if (promoText && promotionalPrice) {
    promoText?.split(' ')?.map((val) => {
      if (val?.indexOf('%') != -1) {
        label += `Extra ${val} off ${promotionalPrice}`;
      }
    });
  }
  return label;
}

export function renderDiscountPercentage(originalPrice, markDownPrice) {
  const regularPrice = parseFloat(originalPrice.replace(/[^0-9.]+/g, ""));
  const offerPrice = parseFloat(markDownPrice.replace(/[^0-9.]+/g, ""));
  const discountPercentage = Math.round((regularPrice - offerPrice) / regularPrice * 100);
  return discountPercentage;
}