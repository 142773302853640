import window from 'window-or-global';
import { LOCAL_STROAGE_RETRIVE_KEYS_WITH_PREFIX, LOCAL_STROAGE_RETRIVE_KEYS } from 'plp/constants';

export const saveToLocalStorage = (key, value, retry = false) => {
    try {
      window?.localStorage.setItem(key, value);
    } catch (error) {
      if (!retry) {
        console.log('Error while stroring data to LocalStorage. Errror::', error);
        clearLocalStorage();
        saveToLocalStorage(key, value, true);
      }
    }
  }
  
  export const clearLocalStorage = () => {
    const retrievedValues = {};
    for (let i = 0; i < localStorage?.length; i++) {
      const key = localStorage.key(i);
      if(LOCAL_STROAGE_RETRIVE_KEYS.includes(key)){
        retrievedValues[key] = localStorage.getItem(key);
        continue;
      }
  
      if(LOCAL_STROAGE_RETRIVE_KEYS_WITH_PREFIX.some(prefix => key.startsWith(prefix))){
        retrievedValues[key] = localStorage.getItem(key);
      }
    }
    
    console.error('LocalStorage is full. Clearing items not whitelisted.');
    window?.localStorage.clear();
  
    // Add back to locastorage once it is clear
    Object.entries(retrievedValues).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });
  }
  
  export const clearIfLocalStorageIsFull = () => {
    if (window && window.localStorage) {
      try {
        window.localStorage.setItem('isLocalStrogeFull', false);
        window.localStorage.removeItem('isLocalStrogeFull');
      } catch (error) {
        console.log('Error while stroring data to LocalStorage. Errror::', error);
        clearLocalStorage();
      }
    }
  }