import React from 'react';
import classnames from 'classnames';
import arrowLeft from 'assets/images/arrow-left.svg';

export const getNavItems = (data, path) => {
  const result = [];
  let currentSilo = data;

  path.forEach((item) => {
    result.push(currentSilo[item]);
    currentSilo = currentSilo[item].categories;
  });

  return result;
};


export function constructInitialContextMenuPath(
  data,
  navPath,
  defaultPath = null
) {
  let categoriesList = data;
  let pathString = navPath;
  const result = [];

  if (pathString === undefined || pathString === null || Array.isArray(pathString)) {
    if (defaultPath === null) return result;
    pathString = defaultPath;
  }

  const catIdsArray = pathString.split('_').slice(1);

  for (let i = 0; i < catIdsArray.length; i++) {
    const catId = catIdsArray[i];

    for (let j = 0; j < categoriesList.length; j++) {
      if (
        categoriesList[j].catmanId === catId
        && categoriesList[j].categories.length !== 0
      ) {
        result.push(j);
        categoriesList = categoriesList[j].categories;
        break;
      }
    }
  }

  return result;
}

const ContextMenu = ({
  data,
  path,
  clickHandler,
  currentCategory,
  isSubcatSelected,
  isFeaturedDesignerSelected,
}) => {
  const DESIGNER_INDEX_CAT_ID = 'cat000730';

  const renderContextMenuItems = (data, path) => {
    const navItems = getNavItems(data, path);

    return navItems.map((navItem, idx, array) => {
      if (idx === array.length - 1) {
        if (navItem.catmanId === DESIGNER_INDEX_CAT_ID) {
          return (
            <div
              key={idx}
              className={classnames({
                'designers-title': true,
                'parent-cat-highlighted':
                  !isSubcatSelected
                  && !isFeaturedDesignerSelected
                  && currentCategory !== DESIGNER_INDEX_CAT_ID,
              })}
            >
              {navItem.name}
            </div>
          );
        }

        return (
          <div
            key={idx}
            className={classnames({
              'indent-right-cat-parent': isSubcatSelected,
              'parent-cat-highlighted': !isSubcatSelected,
            })}
          >
            {navItem.name}
          </div>
        );
      }

      return (
        <div key={idx} className="menu-item" onClick={() => clickHandler(path, idx)}>
          <img alt="" src={arrowLeft} className="arrow--back" />
          {navItem.name}
        </div>
      );
    });
  };

  return (
    <nav>
      <ul>{renderContextMenuItems(data, path)}</ul>
    </nav>
  );
};

export default ContextMenu;
