import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import utagReducers from 'server/components/Utag/reducers/reducers';
import insideDataReducer from 'server/components/InsideData/reducers/insideData-reducer';
import pageReducers from 'shared/reducers/reducers-page';
import apiReducers from 'shared/reducers/reducers-api';
import appReducers, {
  userReducer,
  sessionReducer,
  countrySelectorReducer,
  togglesReducer,
  serviceTogglesReducer,
  cookiesReducer,
  abTestSessionReducer,
  headerReducer,
} from 'shared/components/App/app-reducers';
import modalReducers from 'shared/reducers/reducers-modal';
import spinnerReducers from 'shared/components/Spinner/spinner-reducers';
import miniCartReducers from 'storefront/components/Header/MiniCart/miniCart-reducers';
import navigationReducers from 'storefront/components/Navigation/reducers/reducers';
import leftNavReducers from 'storefront/components/LeftNavigation/reducers-leftnavigation';
import mobileNavReducer from 'storefront/components/Navigation/NewMobileNav/NewMobileNavReducer';
import abTestReducer from 'shared/reducers/abTestReducer';
import abTestOptReducer from 'shared/reducers/abTestOptReducer';
import crpReducer from 'shared/reducers/crpReducer';
import productReducers from 'pdp/pages/ProductPage/reducers';
import productListReducer from 'plp/components/ProductListPage/reducers';
import facetsReducer from 'plp/components/ProductListPage/components/Facets/reducers/facetsReducer';
import ecmReducers from 'ecm/reducers';
import searchReducer from 'storefront/components/Header/Search/search-reducers';
import refreshableContentReducer from 'storefront/components/RefreshableContent/refreshableContent-reducers';
import carouselReducer from 'storefront/components/PromoCarousel/promoCarousel-reducer';
import brandLinkReducer from 'storefront/components/Footer/SisterSites/reducers-sisterSites';
import srpReducers from 'srp/reducers/reducers';
import screenReaderReducer from 'storefront/components/ScreenReaderAlert/screenReaderAnnouncer-reducer';
import profileReducer from 'client/profile/reducers/reducers';
import cmsReducers from 'cms/reducers';
import templatesReducer from './templates/dux/templatesReducer';
import searchReducersHc from 'clientHorchow/components/SearchBox/search-reducers';
import panelReducer from 'shared/reducers/reducers-panel';
import drawerPanel from 'shared/reducers/reducers-drawer';


/* eslint sort-keys: "error" */
export default combineReducers({
  abTestContent: (state = {}) => state,
  abTestIds: (state = {}) => state,
  abTestSession: abTestSessionReducer,
  abTests: abTestReducer,
  abTestsOpt: abTestOptReducer,
  aemOptId: (state = {}) => state,
  api: apiReducers,
  apiTimeouts: (state = {}) => state,
  app: appReducers,
  brand_name: (state = {}) => state,
  chatData: insideDataReducer,
  cms: cmsReducers,
  coherentPath: (state = {}) => state,
  cookies: cookiesReducer,
  crp: crpReducer,
  device: (state = {}) => state,
  ecm: ecmReducers,
  env_name: (state = {}) => state,
  facetedLeftNav: facetsReducer,
  giftWrapFilter: (state = {}) => state,
  header: headerReducer,
  lazyLoadDisabledConfig: (state = {}) => state,
  leftNavigation: leftNavReducers,
  locale: countrySelectorReducer,
  mcacheTimeouts: (state = {}) => state,
  miniCart: miniCartReducers,
  mobileConfig: (state = {}) => state,
  mobileNav: mobileNavReducer,
  modal: modalReducers,
  navigation: navigationReducers,
  omniFacet: (state = {}) => state,
  onlyXLeftThresholds: (state = {}) => state,
  opinionLab: (state = {}) => state,
  page: pageReducers,
  productCatalog: productReducers,
  productListPage: productListReducer,
  productPanel: panelReducer,
  productPickup: (state = {}) => state,
  profile: profileReducer,
  promoCarousel: carouselReducer,
  refreshableContent: refreshableContentReducer,
  routing: routerReducer,
  screenReader: screenReaderReducer,
  search: searchReducer,
  hcSearch: searchReducersHc,
  serviceToggles: serviceTogglesReducer,
  session: sessionReducer,
  sisterBrandLinks: brandLinkReducer,
  spinner: spinnerReducers,
  srp: srpReducers,
  templates: templatesReducer,
  toggles: togglesReducer,
  user: userReducer,
  utag: utagReducers,
  drawerPanel: drawerPanel,
  optABTestConfig: (state = {}) => state,
  giftWithPurchaseCategoryId: (state = {}) => state,
});
/* eslint-disable sort-keys */
