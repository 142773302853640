import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import Slider from 'react-slick';
import { InView } from 'react-intersection-observer';
import { shouldLoad } from 'universal/http-client';
// eslint-disable-next-line import/no-cycle
import { checkForICIDAndAddTags } from 'cms/components/utils';
import { openModal } from 'client/common/components/Modal/actions';
import {
  getCmsProducts,
  getCategoryProducts,
  getModalContent,
  setActiveEntryId,
} from '../../actions';
import ProductCarouselTile from './productCarouselTile';
import CustomArrow from './CustomArrow';
import './productCarousel.scss';
import { isGlobalNavUpdateToggleEnabled } from 'cms/actions/index';

const IMAGE_PLACEMENT = {
  beginning: 'Beginning',
  end: 'End',
};
export class ProductCarousel extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = { arrowTop: null };
  }

  componentDidMount() {
    const imageHeight = this.imageRef?.current?.clientHeight;
    if (imageHeight) {
      const newTop = Math.round(imageHeight / 2);
      this.setState({ arrowTop: newTop });
    }

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onClickHandler(e, linkTo) {
    e.preventDefault();

    const { modalContentApi } = this.props;
    const entryId = linkTo;

    if (entryId && shouldLoad(modalContentApi)) {
      this.props.dispatch(getModalContent(entryId));
    }

    this.props.dispatch(setActiveEntryId(entryId));

    this.props.dispatch(
      openModal({
        type: 'InfoModal',
      }),
    );
  }

  getSliderSettings(slidesInDrawer) {
    return {
      infinite: false,
      arrows: true,
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      nextArrow: <CustomArrow arrowTop={this.state.arrowTop} ariaLabel="next" />,
      prevArrow: <CustomArrow arrowTop={this.state.arrowTop} ariaLabel="previous" />,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            infinite: false,
            slidesToShow: 2.5,
            slidesToScroll: 2,
            draggable: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            infinite: false,
            slidesToShow: slidesInDrawer ? slidesInDrawer : 3.5,
            slidesToScroll: 3,
            draggable: true,
          },
        },
        {
          breakpoint: 1600,
          settings: {
            arrows: true,
            slidesToShow: slidesInDrawer ? slidesInDrawer : 5,
            slidesToScroll: 5,
          },
        },
      ],
    };
  }

  getVerticalSliderSettings(numOfProds) {
    return {
      infinite: false,
      arrows: false,
      autoplay: false,
      slidesToShow: numOfProds,
      slidesToScroll: numOfProds,
      vertical: true,
    };
  }

  handleResize = () => {
    const newImageHeight = this.imageRef?.current?.clientHeight;
    const newScreenWidth = window.innerWidth;
    if (newImageHeight && newScreenWidth > 1024) {
      const newTop = Math.round(newImageHeight / 2);
      this.setState({ arrowTop: newTop });
    }
  }

  UNSAFE_componentWillMount() {
    const {
      cmsProductsApi,
      cmsContentItem = {},
      cmsCategoryProductsApi,
      productsCacheToggle,
    } = this.props;
    const contentId = cmsContentItem?.sys?.id;
    const { ids } = cmsContentItem?.fields;

    if (ids.length === 1 && shouldLoad(cmsCategoryProductsApi)) {
      this.props.dispatch(getCategoryProducts(ids));
    } else if (shouldLoad(cmsProductsApi)) {
      this.props.dispatch(getCmsProducts(ids, contentId, productsCacheToggle));
    }
  }

  combineProducts(cmsProducts, childProducts) {
    return cmsProducts.map((product) => (
      /* eslint-disable max-len */
      product.isGroup && product.childProductIds ? childProducts.find((childProduct) => childProduct.id === product.childProductIds[0]) || product : product));
  }

  renderProducts(combinedProducts, isMobilePhone, cmsContentItem, inView = false, isLazyLoaded = false, hideStatus, renderInDrawer) {
    if (combinedProducts?.length > 15) combinedProducts.splice(15);
    return combinedProducts.map((product, index) => {
      return (
        <ProductCarouselTile
          id={product.id}
          designer={product.designerName}
          name={product.name}
          price={product.price}
          imageUrl={product.imageUrl}
          url={product.url}
          promotions={product.promotions}
          displayable={product.displayable}
          isMobilePhone={isMobilePhone}
          displayOptions={cmsContentItem?.fields}
          inView={inView}
          isLazyLoaded={isLazyLoaded}
          hideStatus={hideStatus}
          imageRef={index === 0 ? this.imageRef : false}
          renderInDrawer={renderInDrawer}
        />
      );
    });
  }

  render() {
    const {
      cmsContentItem,
      isMobilePhone = false,
      cmsProducts = [],
      childProducts = [],
      cmsCategoryProducts = [],
      isLazyLoaded,
      trackTags: propsTrackTags = {},
      hideStatus,
      isGlobalUpdateOn,
      drawerCarouselToggle,
    } = this.props;
    let filteredProducts = [];
    if (!isEmpty(childProducts)) {
      filteredProducts = this.combineProducts(cmsProducts, childProducts);
    }
    const combinedProducts = !isEmpty(filteredProducts) ? filteredProducts : cmsProducts;
    const carouselDirection = cmsContentItem?.fields?.carouselDirection;
    const carouselPlacement = cmsContentItem?.fields?.carouselPlacement;
    const carouselTitle = cmsContentItem?.fields?.drawerCarouselTitle;
    const productCarouselImage = cmsContentItem?.fields?.productCarouselImage?.[0]?.['secure_url'];
    const productCarouselImagePlacement = cmsContentItem?.fields?.productCarouselImagePlacement;
    const productCarouselImageLinkTo = cmsContentItem?.fields?.productCarouselImageLinkTo;
    const openLinkInNewTab = cmsContentItem?.fields?.openLinkInNewTab;
    const displayImage = productCarouselImage && productCarouselImageLinkTo;
    const verticalCarouselHeight = (combinedProducts.length * 425);
    const isModalContent = !openLinkInNewTab && !isUndefined(openLinkInNewTab);
    const getLink = (url) => checkForICIDAndAddTags(url, propsTrackTags, [], isModalContent);
    const renderProductCarouselImage = () => (
      <a
        className="carousel-image"
        href={getLink(productCarouselImageLinkTo)}
        target={openLinkInNewTab ? '_blank' : '_self'}
        onClick={(!openLinkInNewTab && !isUndefined(openLinkInNewTab)) ? ((e) => this.onClickHandler(e, productCarouselImageLinkTo)) : undefined}
      >
        <img src={productCarouselImage} />
      </a>
    );

    const renderInDrawer = () => {
      return isGlobalUpdateOn
         && carouselPlacement === 'Drawer'
         && drawerCarouselToggle;
    }

    const carouselContainerClass = (className) => {
      return renderInDrawer() ? `${className}__drawer` : className;
    }

    const renderFeaturedHeader = () => {
      if (renderInDrawer() && carouselTitle) {
        return <h6>{carouselTitle}</h6>
      }
      return null;
    }

    const slidesInDrawer = renderInDrawer() ? 4 : null;

    const renderSlider = (inView = false) => (
      <Slider {...carouselDirection === 'Vertical' ? this.getVerticalSliderSettings((!isEmpty(combinedProducts) && combinedProducts.length) || (!isEmpty(cmsCategoryProducts) && cmsCategoryProducts.length)) : this.getSliderSettings(slidesInDrawer)}>
        {displayImage && productCarouselImagePlacement === IMAGE_PLACEMENT.beginning && renderProductCarouselImage()}
        {!isEmpty(combinedProducts) && this.renderProducts(combinedProducts, isMobilePhone, cmsContentItem, inView, isLazyLoaded, hideStatus, renderInDrawer())}
        {!isEmpty(cmsCategoryProducts) && this.renderProducts(cmsCategoryProducts, isMobilePhone, cmsContentItem, inView, isLazyLoaded, hideStatus, renderInDrawer())}
        {displayImage && productCarouselImagePlacement === IMAGE_PLACEMENT.end && renderProductCarouselImage()}
      </Slider>
    );

    return (
      <div className={carouselContainerClass('product-carousel-slider-container')} style={carouselDirection === 'Vertical' ? { width: '100%' } : {}}>
        {renderFeaturedHeader()}
        <div style={carouselDirection === 'Vertical' ? { height: `${verticalCarouselHeight}px` } : {}}>
          {isLazyLoaded
            ? (
              <InView
                rootMargin="100px"
                triggerOnce
              >
                {({ inView, ref }) => (
                  <div ref={ref}>
                    {renderSlider(inView)}
                  </div>
                )}
              </InView>
            ) : (
              renderSlider()
            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const contentId = ownProps.cmsContentItem?.sys?.id;
  const categoryId = ownProps.cmsContentItem?.fields?.ids[0];

  return {
    isMobilePhone: get(state, 'device.isMobilePhone'),
    cmsProductsApi: state.api[`cms_products_${contentId}`.toLowerCase()],
    cmsProducts: state.cms.products?.[contentId],
    cmsCategoryProductsApi: state.api[`cms_currated_products_${categoryId}`.toLowerCase()],
    cmsCategoryProducts: state.cms.productsByCategory?.[categoryId],
    childProducts: state.cms.childProducts?.[contentId],
    isLazyLoaded: get(state, 'toggles.LAZYLOAD_CMS_CONTENT'),
    modalContentApi: state.api[`modal_content_${contentId}`.toLowerCase()],
    drawerCarouselToggle: get(state, 'toggles.DRAWER_CAROUSEL', false),
    isGlobalUpdateOn: isGlobalNavUpdateToggleEnabled(state),
    productsCacheToggle: get(state, 'toggles.CF_CACHE_PRDS', false),
  };
};

export default connect(mapStateToProps)(ProductCarousel);
