import {
  APP_REQUEST_RECEIVED,
} from 'client/common/components/App/app-action-types';

const getCustomerStatus = (tmsData, ucaProfileDataCookie) => {
  const {
    logged_in_status: loggedInStatus,
    customer_registered: customerRegistered,
  } = ucaProfileDataCookie;

  if (loggedInStatus === true || tmsData.logged_in_status === 'true') {
    return 'Logged in';
  }
  if (customerRegistered === true || tmsData.customer_registered === 'true') {
    return 'Recognized';
  }
  return 'Not recognized';
};

export default (state = {}, action) => {
  switch (action.type) {
    case APP_REQUEST_RECEIVED: {
      const {
        tmsData,
        profileData = {},
        ucaProfileDataCookie = {},
        analyticsProfileData={},
      } = action.payload;
      return {
        ...tmsData,
        ...analyticsProfileData,
        cart_total_items: profileData.cartItemCount || ucaProfileDataCookie?.cartItemCount || 0,
        customer_status: getCustomerStatus(tmsData, ucaProfileDataCookie),
      };
    }
    default:
      return state;
  }
};
