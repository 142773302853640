const baseConfig = {
  region: 'us-west-2',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  endpoint: 'https://ciuw2ac.neimanmarcus.com',
  fetchOptions: {
    credentials: 'include',
  },
};
const baseConfigHC = {
  region: 'us-west-2',
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  endpoint: 'https://ciuw2ac.horchow.com',
  fetchOptions: {
    credentials: 'include',
  },
};

module.exports = {
  amplifyConfig: (USE_CONFIDENTIAL_CLIENT) => ({
    'dev-int': USE_CONFIDENTIAL_CLIENT ? {
      ...baseConfig,
      userPoolId: 'us-west-2_8cuD8FzQl',
      userPoolWebClientId: '5njnmta0ltppcv72og9sot20ck',
      endpoint: 'https://ciuw2acse-nonprod.neimanmarcus.com',
    } : {
      ...baseConfig,
      userPoolId: 'us-west-2_8cuD8FzQl',
      userPoolWebClientId: '3jdmuhvr20jmg4signtvou6bn5',
      endpoint: 'https://ciuw2acse-nonprod.neimanmarcus.com',
    },
    'dev-int2': {
      ...baseConfig,
      userPoolId: 'us-west-2_rHnftU8NB',
      userPoolWebClientId: 'u6fd7elqq6veivil078gl5upf',
      endpoint: 'https://ciuw2acse-nonprod.neimanmarcus.com',
    },
    prep: USE_CONFIDENTIAL_CLIENT ? {
      ...baseConfig,
      userPoolId: 'us-west-2_vev0sIKcw',
      userPoolWebClientId: '53knft0g7tb0leabeof356okse',
      endpoint: 'https://ciuw2acse-prod.neimanmarcus.com',
    } : {
      ...baseConfig,
      userPoolId: 'us-west-2_vev0sIKcw',
      userPoolWebClientId: '3vojb4t8ts2mqsgpbhq2hh4jun',
    },
    prod: USE_CONFIDENTIAL_CLIENT ? {
      ...baseConfig,
      userPoolId: 'us-west-2_L21NNsM7n',
      userPoolWebClientId: '5hvb8464fjjlcidfi4rp4kurae',
      endpoint: 'https://ciuw2acse-prod.neimanmarcus.com',
    } : {
      ...baseConfig,
      userPoolId: 'us-west-2_L21NNsM7n',
      userPoolWebClientId: '1lbdqjrtg6274jv4ofe5vqarkq',
    },
  }),
  amplifyConfigHC: {
    'hc-dev-int': {
      ...baseConfigHC,
      userPoolId: 'us-west-2_sqGPsekjw',
      endpoint: 'https://ciuw2acse-nonprod.horchow.com',
      userPoolWebClientId: '9bj6b1mt69cksd5j8gtl6hm46',
    },
    'hc-dev-int2': {
      ...baseConfigHC,
      userPoolId: 'us-west-2_G5tDcYwh8',
      endpoint: 'https://ciuw2acse-nonprod.horchow.com',
      userPoolWebClientId: '2tgimpr34eh6g8ahk7hmnvoqvt',
    },
    'hc-prep': {
      ...baseConfigHC,
      userPoolId: 'us-west-2_KvWPLCX3O',
      userPoolWebClientId: '42c8m4q8no95dfbt4sge5jusi',
    },
    'hc-prod': {
      ...baseConfigHC,
      userPoolId: 'us-west-2_gT34G3GHn',
      userPoolWebClientId: '69ejrhslo24ltumku5lmpvafbm',
    },
  },
  hostList: {
    'dev-int': 'https://dev-int-api.nmgcloudapps.com',
    'dev-int2': 'https://dev-int2-api.nmgcloudapps.com',
    prep: 'https://prep-api.neimanmarcuscloud.com',
    prod: 'https://prod-api.neimanmarcuscloud.com',
  },
  hostListHC: {
    'hc-dev-int': 'https://dev-int-amber.api-hc.io',
    'hc-dev-int2': 'https://dev-int2-amber.api-hc.io',
    'hc-prep': 'https://prep-api.horchow.com',
    'hc-prod': 'https://prod-api.horchow.com',
  },
  fastlyHostListNM: {
    'dev-int': 'https://dev-int-amber.api-nm.io',
    'dev-int2': 'https://dev-int2-amber.api-nm.io',
    prep: 'https://prep-beige.api-nm.io',
    prod: 'https://prod-beige.api-nm.io',
  },
};
