/* eslint-disable no-param-reassign */
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import get from 'lodash/get';

export const keysToExclude = ['lsc', 'page', 'navpath', 'facet', 'sizesFiltered', 'sortBy', 'priorityProdId', 'scroll', 'src', 'source', 'seoToggle'];

const parse = (search) => qs.parse(search, QUERY_STRING_OPTIONS);

export const isSeoToggleOn = (search) => {
  const parsedOptions = parse(search);
  const tgl = `${parsedOptions?.seoToggle}` !== 'false';
  return tgl;
};

export function formatFilterKey(string) {
  if (string) {
    string = string.replace(/\s/g, '-');
    string = string.replace(/[^a-zA-Z-]/g, '');
    return string;
  }
}

export function formatDesignerName(string) {
  if (string) {
    string = string.replace(/[^A-Z0-9]/ig, "");
    return string;
  }
}

const QUERY_STRING_OPTIONS = {
  arrayFormat: 'separator',
  arrayFormatSeparator: '|',
};

const convertToSEOParam = ([key, val]) => {
  let r = [];

  const validFilterValue = val && val.length > 0 && Array.isArray(val) && val.some((v) => v);
  if (validFilterValue) {
    try {
      r = [formatFilterKey(key).toLowerCase(), val.map((s) => s.replace(/%20/g, '%2B')).map((s) => s.replace(/%C2%A0/g, '+')).join('|')];
    } catch (e) {

    }
  }
  return r;
};

const convertToFilterParam = ([key, val]) => {
  let r = [];

  const valid = val && val.length > 0;
  const validConvertedFilter = valid && typeof val === 'string';
  const validArray = valid && Array.isArray(val);
  if (validConvertedFilter) {
    try {
      r = [key, [val]];
    } catch (e) {
      console.log(e);
    }
  } else if (validArray) {
    r = [key, val];
  }
  return r;
};

const convertDesignerName = (item) => {
  if (item.includes('   ')) {
    return item.replace('   ', ' + ');
  }

  switch (item) {
    case 'NIC ZOE':
      return 'NIC+ZOE';
    case 'NIC ZOE Plus':
      return 'NIC+ZOE Plus';
    case 'NZT NIC ZOE':
      return 'NZT NIC+ZOE';
    case 'Russell Hazel':
      return 'Russell+Hazel';
    case 'russell hazel':
      return 'russell+hazel';
    case 'AO.LA by Alice Olivia':
      return 'AO.LA by Alice+Olivia';
    case 'Aden Anais':
      return 'Aden+Anais';
    case 'Cleo Coco':
      return 'Cleo+Coco';
    case 'Gamma ':
      return 'Gamma+';
    case 'Harper Ari':
      return 'Harper+Ari';
    case 'INDU ':
      return 'INDU+';
    case 'Light Nine':
      return 'Light+Nine';
    case 'Harper Ari':
      return 'Harper+Ari';
    case 'Swiss Tech':
      return 'Swiss+Tech';
    case 'i.am ':
      return 'i.am+';
    case 'R Co':
      return 'R+Co';
    case 'R Co Bleu':
      return 'R+Co Bleu';
    default:
      return item;
  }
};

export const useFilterOptions = (location) => {
  const parsedOptions = parse(location.search);

  if (parsedOptions.designer && Array.isArray(parsedOptions.designer) && parsedOptions.designer.length > 1) {
    parsedOptions.designer = parsedOptions.designer.map((item) => convertDesignerName(item));
  }


  if (parsedOptions.brand && Array.isArray(parsedOptions.brand) && parsedOptions.brand.length > 1) {
    parsedOptions.brand = [...new Set(parsedOptions.brand.map((item) => convertDesignerName(item)))];
  }

  if (parsedOptions.size && Array.isArray(parsedOptions.size) && parsedOptions.size.length > 1) {
    parsedOptions.size = parsedOptions.size.map((item) => {
      if (item === 'Oversized (51" )') {
        return 'Oversized (51"+)';
      }
      return item;
    });
  }

  if (parsedOptions['heel-height'] && Array.isArray(parsedOptions['heel-height']) && parsedOptions['heel-height'].length > 1) {
    parsedOptions['heel-height'] = parsedOptions['heel-height'].map((item) => {
      if (item === 'Ultra High (4"  )') {
        return 'Ultra High (4" +)';
      }
      return item;
    });
  }

  // except for lsc and page, wrap in an array
  // http://localhost:3000/c/womens-clothing-clothing-dresses-cat43810733?Color=Black&page=1
  // const wrapped = Object.fromEntries(Object.entries(parsedOptions)
  //  .filter(([key]) => !keysToExclude.includes(key))
  //  .map((pairs) => (pairs[1] === null ? [pairs[0], []] : [pairs[0], pairs[1].split('|')])));
  const wrapped = Object.fromEntries(Object.entries(parsedOptions)
    .filter(([key]) => !keysToExclude.includes(key))
    .filter(([key, val]) => key !== 'filterOptions' && val && (typeof val === 'object' || typeof val === 'string'))
    .map(convertToFilterParam)
    .filter(([k, v]) => k && v));
  // the correct pattern to return for this is filterOptions wrapped and parsed, then all the excluded keys, all at the same level
  const metaOptions = pick(parsedOptions, keysToExclude);

  return { filterOptions: wrapped, ...metaOptions };
};

export function getParams(location) {
  return location ? qs.parse(location.search) : '';
}

export function updateParams(router, params, priorityParams) {
  const location = router.getCurrentLocation();
  let search = qs.parse(location.search);
  if (isSeoToggleOn(location.search)) {
    search = Object.fromEntries(Object.entries(qs.parse(location.search)).filter(([key]) => keysToExclude.includes(key)));
  }
  let { filterOptions, ...paramsWithoutFilterOptions } = params;

  if (!params?.seoToggle || filterOptions === undefined) {
    return router.push({
      pathname: location.pathname,
      search: `?${qs.stringify({ ...search, ...params })}`,
    });
  }

  // convert null to [] and join on |
  filterOptions = Object.fromEntries(Object.entries(JSON.parse(params.filterOptions))
    .filter(([key]) => !keysToExclude.includes(key))
    .filter(([key, val]) => key !== 'filterOptions' && val && (typeof val === 'object' || typeof val === 'string'))
    .map(convertToSEOParam)
    .filter(([k, v]) => k && v));
  if (filterOptions.brand) {
    filterOptions.brand = filterOptions.brand.split('|').filter((s) => s !== 'R Co Bleu' && s !== 'R+Co Bleu').join('|');
  }
  const querySortOptions = { ...QUERY_STRING_OPTIONS };
  if (priorityParams && priorityParams.length > 0) {
    querySortOptions.sort = (ak, bk) => {
      if (priorityParams.includes(ak) && priorityParams.includes(bk) && priorityParams.indexOf(ak) > priorityParams.indexOf(bk)) {
        return 1;
      }
      if (priorityParams?.includes(ak)) return -1;
      if (priorityParams?.includes(bk)) return 1;
      return 0;
    };
  }
  const searchObj = {
    ...search, ...filterOptions, ...paramsWithoutFilterOptions, filterOptions: [],
  };
  if (searchObj?.seoToggle) {
    delete searchObj.seoToggle;
  }
  if (isEmpty(filterOptions) && searchObj?.source === 'vn') {
    delete searchObj.source;
  }
  let finalSearchStr = qs.stringify(searchObj, querySortOptions);

  finalSearchStr = finalSearchStr.replace(/\%20/g, '+');

  return router.push({
    pathname: location.pathname,
    search: `?${finalSearchStr}`,
  });
}

function cleanParams(param, search) {
  const savedFacet = Object.keys(param)[0];
  const searchKeys = Object.keys(search);
  searchKeys.forEach((key) => {
    if (key === savedFacet) {
      delete search[key];
    }
  });
}

export function updateParamsWithSEO(
  router,
  firstParam,
  secondParam,
  params,
  savedFirstParam,
  savedSecondParam,
  isDeselectFirst,
  isDeselectSecond,
  seoFacetKeys,
) {
  const location = router.getCurrentLocation();
  const searchString = location.search;
  const search = qs.parse(searchString);
  let firstParamString = `${qs.stringify({ ...savedFirstParam })}`;
  if (firstParamString) {
    firstParamString = decodeFilterValue(firstParamString);
  }
  let secondParamString = `${qs.stringify({ ...savedSecondParam })}`;
  if (secondParamString) {
    secondParamString = decodeFilterValue(secondParamString);
  }
  const isDeselect = isDeselectFirst || isDeselectSecond;
  let forcedParams = '';

  if (!isEmpty(savedFirstParam) && !isEmpty(savedSecondParam) && !isDeselect) {
    forcedParams = `${firstParamString}&${secondParamString}&`;
    cleanParams(savedFirstParam, search);
    cleanParams(savedSecondParam, search);
  } else {
    if (isDeselectFirst) {
      cleanParams(firstParam, search);
      cleanParams(savedSecondParam, search);
      if (isEmpty(savedSecondParam)) {
        forcedParams = '';
      } else {
        forcedParams = `${secondParamString}&`;
      }
    } else if (!isEmpty(savedFirstParam)) {
      cleanParams(savedFirstParam, search);
      forcedParams += `${firstParamString}&`;
    } else {
      firstParamString = `${qs.stringify({ ...firstParam })}`;
      firstParamString = (firstParamString);
      if (!isEmpty(firstParamString)) {
        forcedParams += `${firstParamString}&`;
      }
    }
    if (isDeselectSecond) {
      cleanParams(secondParam, search);
      cleanParams(savedFirstParam, search);
      if (isEmpty(savedFirstParam)) {
        forcedParams = '';
      } else {
        forcedParams = `${firstParamString}&`;
      }
    } else if (!isEmpty(savedSecondParam)) {
      cleanParams(savedSecondParam, search);
      forcedParams += `${secondParamString}&`;
    } else {
      secondParamString = `${qs.stringify({ ...secondParam })}`;
      if (!isEmpty(secondParamString)) {
        forcedParams += `${secondParamString}&`;
      }
    }
  }

  return updateParams(router, params, seoFacetKeys);
}

export function clearAllParams(router, firstParamKey, secondParamKey) {
  const location = router.getCurrentLocation();
  const search = parse(location.search);

  let params = { filterOptions: null, page: 1 };
  if (search?.seoToggle !== 'false') {
    // filter search object for below keys and include those keys and values to params
    const filteredSearch = Object.fromEntries(Object.entries(search).filter((pairs) => keysToExclude.includes(pairs[0])));
    params = { ...filteredSearch, filterOptions: [], page: 1 };
    if (params?.source === 'vn') {
      delete params.source;
    }
    delete params["lsc"]
    delete search["lsc"]
    return router.push({
      pathname: location.pathname,
      search: `?${qs.stringify({ ...params })}`,
    });
  }
  if (params?.source === 'vn') {
    delete params.source;
  }
  if (search?.source === 'vn') {
    delete search.source;
  }
  delete search[firstParamKey];
  delete search[secondParamKey];

  return router.push({
    pathname: location.pathname,
    search: `?${qs.stringify({ ...search, ...params })}`,
  });
}

export function clearParamsForMultiSelect(router, paramKey, params) {
  const location = router.getCurrentLocation();
  const search = qs.parse(location.search);
  delete search[paramKey];
  return router.push({
    pathname: location.pathname,
    search: `?${qs.stringify({ ...search, ...params })}`,
  });
}

export function updateParamsForSortBy(router, params) {
  const location = router.getCurrentLocation();
  const search = qs.parse(location.search);
  const searchObj = { ...search, ...params };
  if (searchObj?.seoToggle) {
    delete searchObj.seoToggle;
  }
  return router.push({
    pathname: location.pathname,
    search: `?${qs.stringify(searchObj)}`,
  });
}

export function updateParamsWithStoreName(router, params, storeName) {
  const location = router.getCurrentLocation();
  const search = parse(location.search);
  delete search.filterOptions;
  const searchObj = { ...params, ...search, storeName };
  if (searchObj?.seoToggle) {
    delete searchObj.seoToggle;
  }
  if(searchObj?.filterOptions === null){
    delete searchObj.filterOptions;
  }
  return router.push({
    pathname: location.pathname,
    search: `?${qs.stringify(searchObj)}`,
  });
}

export function setParams(router, params) {
  const location = router.getCurrentLocation();
  return router.push({
    pathname: location.pathname,
    search: `?${qs.stringify({ ...params })}`,
  });
}

export function buildBaseUrlWithPathname(router) {
  const location = get(router, 'location', '');
  const pathname = location?.pathname;
  const baseUrl = `https://www.neimanmarcus.com${pathname}`;
  return baseUrl;
}

export function replaceSpaceWithPlus(string) {
  if (string) {
    return string.replace(/\s/g, '+');
  }
}

export function decodePlusSymbol(string) {
  if (string) {
    return string.replace(/%2B/g, '+');
  }
}

export function replaceEncodedSpaceWithPlus(string) {
  if (string) {
    return string.replace(/%20/g, '+');
  }
}

export function encodeAndReplaceEncodedSpaceWithPlus(string) {
  if (string) {
    string = encodeURIComponent(string).replace(/[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16)}`);
    string = string.replace(/%20/g, '+');
    return string;
  }
}

export function removeHyphen(string) {
  if (string) {
    return string.replace(/-/g, '');
  }
}

export function replaceSpaceWithHyphen(string) {
  if (string) {
    return string.replace(/\s/g, '-');
  }
}

export function removeSpecialCharacters(string) {
  if (string) {
    string = string.replace(/[^a-zA-Z ]/g, '');
    return replaceSpaceWithHyphen(string);
  }
}

export function replaceSpaceWithHyphenAndEncode(string) {
  if (string) {
    string = removeHyphen(string);
    string = replaceSpaceWithHyphen(string);
    string = string.replace(/[^a-zA-Z-]/g, '');
    return encodeURIComponent(string);
  }
}

export function decodeFilterValue(string) {
  if (string) {
    const filterFields = string.split('=');
    const filterKey = filterFields[0];
    const filterValue = decodePlusSymbol(filterFields[1]);
    return `${filterKey}=${filterValue}`;
  }
}

export function formatEncodedFilterKey(string) {
  if (string) {
    string = string.replace(/%20/g, '-');
    string = string.replace(/[^a-zA-Z-]/g, '');
    return string;
  }
}

export function formatEncodedFilterValue(string) {
  if (string) {
    string = string.replace(/%20/g, '+');
    return string;
  }
}

export function formatSingleParam(splitString) {
  if (splitString) {
    const secondSplit = splitString.split('=');
    const firstPart = formatEncodedFilterKey(secondSplit[0]);
    const secondPart = formatEncodedFilterValue(secondSplit[1]);
    const forcedParam = `${firstPart}=${secondPart}&`;
    return forcedParam;
  }
}

export function forcedParamsFormatter(string) {
  if (string) {
    const splitString = string.split('&');
    if (splitString.length > 2) {
      const firstForcedParam = formatSingleParam(splitString[0]);
      const secondForcedParam = formatSingleParam(splitString[1]);
      const forcedParams = firstForcedParam + secondForcedParam;
      return forcedParams;
    }
    const forcedParam = formatSingleParam(splitString[0]);
    return forcedParam;
  }
  return '';
}

export function formatParamForCanonical(first, second) {
  if (!first && second) {
    second = formatSingleParam(second);
    second = second.substring(0, second.length - 1);
    return second;
  } if (!second && first) {
    first = formatSingleParam(first);
    first = first.substring(0, first.length - 1);
    return first;
  }
  const firstSplit = first.split('=');
  const nextKey = firstSplit[0];
  let nextValue = firstSplit[1];
  nextValue = formatEncodedFilterValue(nextValue);
  first = `${nextKey}=${nextValue}`;
  let nextParam = `${first}&${formatSingleParam(second)}`;
  nextParam = nextParam.substring(0, nextParam.length - 1);
  return nextParam;
}

export function identifyRequestOriginator(daisrobot='0', isMobilePhone, userAgent='') {
  let originator = 'legacy';
  if (daisrobot === '1') {
    originator = 'bot';
  } else if (userAgent) {
    if (userAgent.startsWith('Neiman')) {
      originator = 'oneapp';
    } else if (userAgent.startsWith('Mozilla') || userAgent.startsWith('Opera')) {
      originator = isMobilePhone ? 'mweb' : 'web';
    } else if (userAgent.startsWith('scsbpx')) {
      originator = 'perftest';
    }
  } else if (isMobilePhone) {
    originator = 'mlegacy';
  }
  return originator;
}

export function removeDuplicateNavPath(navpath) {
  let index = navpath?.indexOf(',') || -1;
  let result = index !== -1 ? navpath.substring(0, index) : navpath;
  return result;
}