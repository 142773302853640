import { combineReducers } from 'redux';
import productReducer from './reducers/productReducer';
import groupReducer from './reducers/groupReducer';
import recommendationsReducer from './reducers/recommendationsReducer';
import relatedItemsReducer from './reducers/relatedItemsReducer';
import recentlyViewedReducer from './reducers/recentlyViewedReducer';
import storeReducer from './reducers/storeReducer';
import paginationReducer from './reducers/paginationReducer';
import outfittingReducer from './reducers/outfittingReducer';
import appointmentsReducer from './reducers/appointmentsReducer';
import productPickupReducer from './reducers/productPickupReducer';
import deliveryShipReducer from './reducers/deliveryShipReducer';
import relatedLinksReducer from './reducers/relatedLinksReducer';
import relatedProductsReducer from './reducers/relatedProductsReducer';

export default combineReducers({
  product: productReducer,
  group: groupReducer,
  recommendations: recommendationsReducer,
  outfitting: outfittingReducer,
  relatedItems: relatedItemsReducer,
  recentlyViewedProducts: recentlyViewedReducer,
  stores: storeReducer,
  appointments: appointmentsReducer,
  deliveryShip: deliveryShipReducer,
  boss: (state = {}) => state,
  vendorQtyRestrictions: (state = {}) => state,
  zeroDollarPageAllBeautyLink: (state = {}) => state,
  maxPageForAll: (state = {}) => state,
  pdpPagination: paginationReducer,
  productPageImageTimeout: (state = {}) => state,
  outfittingConf: (state = {}) => state,
  pdpMainImageScene7Size: (state = {}) => state,
  productPickup: productPickupReducer,
  graphiteHQRelatedLinks: relatedLinksReducer,
  graphiteHQRelatedProducts: relatedProductsReducer,
});
