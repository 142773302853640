/* eslint import/no-cycle: 0 */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Loadable from 'react-loadable';
import StoryRailV2 from 'cms/components/editorialSilo/storyRail';
import GenderSwitch from 'cms/components/GenderSwitcher/genderSwitcher';
import ButtonsGrid from 'cms/components/buttonsGrid/buttonsGrid';
import RenderContentSlot from './renderContentSlot';
import RenderLayout from './renderLayout';
import SimpleText from './simpleText/simpleText';
import ImageAsset from './image/imageAsset';
import ImageWithTextAsset from './imageWithText/imageWithText';
import RichText from './richText/richText';
import TextOverImage from './textOverImage/textOverImage';
import VideoAsset from './video/videoAsset';
import ProductCarouselAsset from './Product/productCarousel';
import QuizAsset from './quiz/quiz';
import JustForYou from './justForYou/justForYou';
import VisualNav from './visualNav/visualNav';
import ProductComponent from './productComponent/productComponent';
import StoryRailAsset from './storyRail/storyRail';
import SocialSharingAsset from './socialSharing/socialSharing';
// eslint-disable-next-line import/no-named-as-default
import FeaturedSubCatComponent from './featuredSubCatComponent/featuredSubCatComponent';
import CarouselComponent from './carouselComponent/carouselComponent';
import MultiCarousel from './Product/MultiProductCarousel';
import MultiGrid from './Product/MultiProductGrid';
import VideoBackgroundAsset from './VideoBackground/VideoBackground';
import { seoSelectedFilter } from '../../../client/utilities/utilities-seoFacetUtag';
import { getSelectedFilterCount } from '../../plp/components/ProductListPage/components/ProductList/components/Filters/filters';
import { VISUALNAV_SOURCE } from "../constants"
const Store = Loadable({
  loader: () => import(/* webpackChunkName: 'Store' */ './store/store'),
  loading: () => false,
});

const SocialVideoAsset = Loadable({
  loader: () => import(/* webpackChunkName: 'SocialVideoAsset' */ './video/socialVideoAsset'),
  loading: () => false,
});

const HorizontalLine = Loadable({
  loader: () => import(/* webpackChunkName: 'HorizontalLine' */ './horizontalLine/horizontalLine'),
  loading: () => false,
});

const IframeAsset = Loadable({
  loader: () => import(/* webpackChunkName: 'IframeAsset' */ './iframe/iframeAsset'),
  loading: () => false,
});

const DumbRenderContentItem = ({
  cmsContentItem = {}, placement, trackTags = {}, neverLazyload = false, parentType = 'CMSComponent',
  productListPage, page, session, isMobilePhone, founditVisualNav = false, founditVisualNavTest = false
}) => {  
  const cmsContentId = get(cmsContentItem, 'sys.id');
  switch (cmsContentItem.contentType) {
    case 'frame': {
      const contentPlacement = get(cmsContentItem, 'fields.l1Layouts', []);
      const pagePlacement = contentPlacement.find((item) => get(item, 'fields.placement', '') === placement);
      if (pagePlacement) {
        return <RenderLayout cmsLayout={cmsContentItem} placement={placement} key={cmsContentId}  founditVisualNav={founditVisualNav} founditVisualNavTest={founditVisualNavTest}/>;
      }
      return null;
    }
    case 'placeholderContentAsset': {
      const { type } = get(cmsContentItem, 'fields', {});
      return {
        'Gender switch': <GenderSwitch />,
        'Story Rail': <StoryRailAsset cmsContentItem={cmsContentItem} key={cmsContentId} />,
        'Story Rail V2': <StoryRailV2 />,
        'Social Sharing': <SocialSharingAsset cmsContentItem={cmsContentItem} key={cmsContentId} />,
        Quiz: <QuizAsset cmsContentItem={cmsContentItem} key={cmsContentId} />,
        JFY: <JustForYou key={cmsContentId} />,
      }[type] || null;
    }
    case 'contentSlot':
      return <RenderContentSlot cmsContentSlot={cmsContentItem} key={cmsContentId} />;
    case 'imageContentAsset':
      return (
        <ImageAsset
          trackTags={trackTags}
          cmsContentItem={cmsContentItem}
          key={cmsContentId}
          neverLazyload={neverLazyload}
        />
      );
    case 'simpleTextContentAsset':
      return (
        <SimpleText
          cmsContentItem={cmsContentItem}
          key={cmsContentId}
          trackTags={trackTags}
        />
      );
    case 'richTextContentAsset':
      return <RichText cmsContentItem={cmsContentItem} key={cmsContentId} trackTags={trackTags} />;
    case 'textOverImageContentAsset':
      return (
        <TextOverImage
          cmsContentItem={cmsContentItem}
          key={cmsContentId}
          trackTags={trackTags}
          neverLazyload={neverLazyload}
        />
      );
    case 'textWithImageContentAsset':
      return (
        <ImageWithTextAsset
          trackTags={trackTags}
          cmsContentItem={cmsContentItem}
          key={cmsContentId}
          parentType={parentType}
          neverLazyload={neverLazyload}
        />
      );
    case 'videoContentAsset':
      return <VideoAsset cmsContentItem={cmsContentItem} key={cmsContentId} />;
    case 'socialVideoContentAsset':
      return <SocialVideoAsset cmsContentItem={cmsContentItem} key={cmsContentId} />;
    case 'videoBackgroundContentAsset':
      return (
        <VideoBackgroundAsset
          cmsContentItem={cmsContentItem}
          key={cmsContentId}
          trackTags={trackTags}
        />
      );
    case 'multiCarouselContentAsset':
      return (
        <MultiCarousel
          multiCarousel={cmsContentItem.content}
          key={cmsContentId}
          cmsContentId={cmsContentId}
        />
      );
    case 'multiGridContentAsset':
      return (
        <MultiGrid
          multiGrid={cmsContentItem.content}
          key={cmsContentId}
          cmsContentId={cmsContentId}
          trackTags={trackTags}
        />
      );
    case 'productCarouselContentAsset':
      return (
        <ProductCarouselAsset
          cmsContentItem={cmsContentItem}
          key={cmsContentId}
          trackTags={trackTags}
        />
      );
    case 'storeContentAsset':
      return <Store cmsContentItem={cmsContentItem} key={cmsContentId} />;
    case 'productComponent':
      return (
        <ProductComponent
          trackTags={trackTags}
          cmsContentItem={cmsContentItem}
          key={cmsContentId}
        />
      );
    case 'iframeContentAsset':
      return <IframeAsset cmsContentItem={cmsContentItem} key={cmsContentId} />;
    case 'visualNav':
        const splitNextURL =  page?.location?.search?.split('?') || session?.url?.split('?');
        const locationQueryString = splitNextURL[1];
        const selectedFiltersUtag = seoSelectedFilter(productListPage?.products, locationQueryString);
        selectedFiltersUtag['In Store'] = selectedFiltersUtag['In Store']?.filter((element,index) => (index > 0 && (element !== '' && element !== null)));
        const noOfFiltersSelected = getSelectedFilterCount(selectedFiltersUtag);
        return !(isMobilePhone && noOfFiltersSelected > 0 && locationQueryString?.indexOf(VISUALNAV_SOURCE) > -1) ?
        (
          <VisualNav
            cmsContentItem={cmsContentItem}
            key={cmsContentId}
            trackTags={trackTags}
          />
        ): null;
    case 'horizontalLine':
      return <HorizontalLine cmsContentItem={cmsContentItem} key={cmsContentId} />;
    case 'carouselComponent':
      return (
        <CarouselComponent
          trackTags={trackTags}
          cmsContentItem={cmsContentItem}
          key={cmsContentId}
        />
      );
    case 'featuredSubcatComponent': {
      return (
        <FeaturedSubCatComponent
          trackTags={trackTags}
          cmsContentItem={cmsContentItem}
          key={cmsContentId}
        />
      );
    }
    case 'buttonsGrid': {
      return (
        <ButtonsGrid
          trackTags={trackTags}
          cmsContentItem={cmsContentItem}
          key={cmsContentId}
        />
      );
    }
    default:
      return <div />;
  }
};

const RenderContentItem = (props) => {
  const { bgColor, ...otherProps } = props;
  return bgColor ? (
    <div style={{ backgroundColor: bgColor }}>
      <DumbRenderContentItem {...otherProps} />
    </div>
  ) : (
    <DumbRenderContentItem {...otherProps} />
  );
};

export default RenderContentItem;