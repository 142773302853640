import URL from 'url-parse';
import getUrl from 'url';

const urlDefaults = {
  protocol: 'https',
  slashes: true,
  hostname: 'www.neimanmarcus.com',
};

const hcUrlDefaults = { ...urlDefaults, hostname: 'www.horchow.com' };

export function normalize(address, brand) {
  if (!address) {
    return '';
  }

  const defaultLocation = brand && brand === 'HC' ? hcUrlDefaults : urlDefaults;
  const parsed = new URL(address, defaultLocation);
  return parsed.toString();
}

export function getQueryParam(param, url) {
  const currentUrl = !url ? window.location.href : url;
  const parsed = getUrl.parse(currentUrl, true);
  return parsed.query[param];
}

export function hasProtocol(address) {
  const protocols = ['http:', 'https:'];
  return protocols.some((element) => address.startsWith(element));
}

export function prependSlashes(address) {
  return address.startsWith('//') ? address : `//${address}`;
}

export function parseUrlId(urlInfo) {
  const lastDashIndex = urlInfo.lastIndexOf('-');
  if (lastDashIndex === -1) {
    return urlInfo;
  }
  return urlInfo.slice(lastDashIndex + 1);
}

export function hasNoIndexParams(params) {
  const noIndexParams = [
    'ecid',
    'ECID',
    'icid',
    'utm_source',
    'sf_associd',
    'eVar4',
  ];
  const paramsKeys = Object.keys(params);
  return paramsKeys.some((param) => noIndexParams.includes(param));
}

export function updateRedirectURLWithNoIndexQueryParams(url, redirectURL = '') {
  const qureyParamKeys = [
    'ecid',
    'ECID',
    'icid',
    'sf_associd',
    'utm_source',
    'hConversionEventId',
    'eVar4',
    'adobe_mc'
  ];
  let queryParam = '';
  const currentUrl = !url ? window.location.href : url;
  const parsed = getUrl.parse(currentUrl, true);
  const redirectUrl= getUrl.parse(redirectURL, true);
  qureyParamKeys.forEach(param=>{
    if(parsed?.query[param] && !redirectUrl?.query[param]){
      if(queryParam != ''){
        queryParam = queryParam + '&';
      }
      queryParam = queryParam + `${param}=${parsed.query[param]}`;
    }
  })
  if(queryParam)
    redirectURL = redirectURL.includes('?') ? `${redirectURL}&${queryParam}` : `${redirectURL}?${queryParam}`;
  return redirectURL;

}
