import React from 'react';
import Loadable from 'react-loadable';
import { Route, IndexRoute } from 'react-router';
import { getParams } from 'client-utils/utilities-router';
import App from './common/components/App/App';

const AppCategoryWrapper = Loadable({
  loader: () => import(/* webpackChunkName: 'app-category' */ './common/components/App/app-category-wrapper'),
  loading: () => false,
});

const AppCprCategoryWrapper = Loadable({
  loader: () => import(/* webpackChunkName: 'app--crp-category' */ './common/components/App/app-crp-category-wrapper'),
  loading: () => false,
});

const SpecialOffer = Loadable({
  loader: () => import(/* webpackChunkName: 'app-favorites' */ '../hc/client/components/SpecialOffer/specialOffer'),
  loading: () => false,
});

export function scrollToTop() {
  const scrollToTop = getParams(window.location).scroll || 'true';
  const isScrollFixToggleOn = window.sessionStorage.getItem('isScrollFixToggleOn');
  const isComingBackFromPDP = window.sessionStorage.getItem('visitedPDPpage');
  const isInitialPLPload = window.sessionStorage.getItem('initialPLPload');

  if (scrollToTop === 'true') {
    if (isScrollFixToggleOn === 'true' && isComingBackFromPDP === 'true') {
      if (isInitialPLPload === 'true') {
        window.sessionStorage.removeItem('visitedPDPpage');
        window.sessionStorage.removeItem('initialPLPload');
        window.scrollTo(0, 0);
      }
      window.sessionStorage.removeItem('visitedPDPpage');
    } else {
      window.scrollTo(0, 0);
      if (isScrollFixToggleOn === 'true') {
        window.sessionStorage.removeItem('initialPLPload');
      }
    }
  }
}

export function scrollToTopSrp() {
  const scrollToTop = getParams(window.location).scroll || 'true';
  const isScrollFixToggleOn = window.sessionStorage.getItem('isScrollFixToggleOn');
  const isComingBackFromPDP = window.sessionStorage.getItem('visitedPDPpage');
  const isInitialPLPload = window.sessionStorage.getItem('initialPLPload');
  const isPagePLP = window.location.pathname.indexOf('/p/') !== -1;

  if (scrollToTop === 'true') {
    if (isScrollFixToggleOn === 'true' && isComingBackFromPDP === 'true') {
      if (isInitialPLPload === 'true') {
        window.sessionStorage.removeItem('visitedPDPpage');
        window.sessionStorage.removeItem('initialPLPload');
        window.scrollTo(0, 0);
      }
      if (!isPagePLP) {
        window.sessionStorage.removeItem('visitedPDPpage');
      }
    } else {
      window.scrollTo(0, 0);
      if (isScrollFixToggleOn === 'true') {
        window.sessionStorage.removeItem('initialPLPload');
      }
    }
  }
}

const CategoryWrapper = (isCRPOn) => (isCRPOn ? AppCprCategoryWrapper : AppCategoryWrapper);

const conditionalScrollToTop = (isSrpScrollFix) => (isSrpScrollFix ? scrollToTopSrp : scrollToTop);

const RouterRoutes = (isCRPOn, isSrpScrollFix) => {
  return (
    <Route path="/(en-:countryCode)" component={App} onChange={conditionalScrollToTop(isSrpScrollFix)}>
      <IndexRoute component={CategoryWrapper(isCRPOn)} deferHpScripts />
      <Route path="c(/:optParam1)(/:optParam2)(/:optParam3)(/:optParam4)/:categoryInfo" component={CategoryWrapper(isCRPOn)} deferScripts />
      <Route path="(*/):categoryId/c.cat" component={CategoryWrapper(isCRPOn)} deferScripts />
      <Route path="specialoffer" component={SpecialOffer} />
    </Route>
  );
};

export default RouterRoutes;
