import React, { Fragment } from 'react';
import includes from 'lodash/includes';
import { unescape } from 'he';

const valueHeader = (letter, headerObjects) => (
  <dt
    ref={(input) => { headerObjects[letter] = input; }}
    className="content-header"
    tabIndex="-1"
  >
    {letter}
  </dt>
);

const contentGrid = ({
  content,
  headerObjects,
  onFilterValueClick,
  filterKey,
  selectedValues,
}) => (
  <dl className="content-container">
    {
        Object.keys(content).map((key, index) => (
          <Fragment key={key}>
            {valueHeader(key, headerObjects)}
            {
              content[key].map((facet, contentIndex) => (
                <dd
                  key={facet.label}
                  onClick={(event) => {
                    event.preventDefault();
                    onFilterValueClick(filterKey, facet.label, event);
                  }}
                >
                  <div
                    aria-checked={includes(selectedValues, facet.label)}
                    aria-label={`Filter by ${facet.label}, ${facet.value} items`}
                    onKeyDown={(event) => {
                      if (event.keyCode === 13 || event.keyCode === 32) {
                        event.preventDefault();
                        onFilterValueClick(filterKey, facet.label, event);
                      }
                    }}
                    role="checkbox"
                    tabIndex="0"
                  >
                    <input
                      checked={includes(selectedValues, facet.label)}
                      className="designer_input_box"
                      id={`designer_${facet.label.split(' ')[0]}_${index}_${contentIndex}`}
                      readOnly
                      type="checkbox"
                    />
                    <label htmlFor={`designer_${facet.label.split(' ')[0]}_${index}_${contentIndex}`}>
                      <span className="facet-label-text">
                        {unescape(facet.label)}
                        {' '}
                        <i>
                          {'('}
                          {facet.value}
                          {')'}
                        </i>
                      </span>
                    </label>
                  </div>
                </dd>
              ))
            }
          </Fragment>
        ))
      }
  </dl>
);

export default contentGrid;
