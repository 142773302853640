import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import NO_IMAGE_AVAILABLE from 'assets/images/no-image.jpeg';
import HC_NO_IMAGE_AVAILABLE from 'hcAssets/images/hc-no-image.jpeg';
import { CLOUDINARY_IMG_NOT_AVAILABLE, HC_CLOUDINARY_IMG_NOT_AVAILABLE } from 'client-utils/utilities-cloudinary';

export const NO_IMAGE_AVAILABLE_CDN = 'https://neimanmarcus.scene7.com/is/image/NeimanMarcus/vnt_error?&wid=456&height=570';
export const NO_IMAGE_AVAILABLE_LARGE_CDN = 'https://neimanmarcus.scene7.com/is/image/NeimanMarcus/vnt_error?&wid=700&height=800';
export const HC_NO_IMAGE_AVAILABLE_LARGE_CDN = 'https://media.horchow.com/image/upload/f_auto,q_auto:low,ar_4:5,c_fill,dpr_2.0,w_500/v1598369085/HC-no-image-vnt_error';
export const HC_NO_IMAGE_AVAILABLE_CDN = 'https://media.horchow.com/image/upload/f_auto,q_auto:low,ar_4:5,c_fill,dpr_2.0,w_500/v1598369085/HC-no-image-vnt_error';

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: props.src,
      alt: props.alt,
    };
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    const { timeout } = this.props;
    if (this.image && this.image.complete && this.image.naturalWidth === 0) {
      this.handleError();
    }

    if (this.image && !this.image.complete) {
      setTimeout(() => {
        if (this.image && this.image.naturalWidth === 0) {
          this.handleError();
        }
      }, timeout || 500);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { src } = this.props;
    if (nextProps.src !== src) {
      this.setState({
        src: nextProps.src,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { src } = this.props;
    return nextState.src !== src;
  }

  handleError() {
    const {
      isPdp,
      isPlp,
      updateSwatchesValue,
      swatchIndex,
      productId,
      errorImage,
      errorMessage,
      useCloudinaryImages = true,
      brand,
    } = this.props;
    const { src } = this.state;
    if (isPdp) {
      if (window && window.QuantumMetricAPI) {
        window.QuantumMetricAPI.sendEvent(159, 0, '');
      }
    }
    if (isPlp === true) {
      updateSwatchesValue(swatchIndex, productId);
    }
    if (errorMessage) {
      this.setState({ src: errorImage, alt: 'No image available' });
      return null;
    }
    if (!isEmpty(errorImage)) {
      this.setState({ src: errorImage });
      return null;
    }
    if (brand === 'HC') {
      if (useCloudinaryImages
        && src !== HC_CLOUDINARY_IMG_NOT_AVAILABLE
        && src !== HC_NO_IMAGE_AVAILABLE) {
        this.setState({
          src: HC_CLOUDINARY_IMG_NOT_AVAILABLE,
          alt: 'No image available',
        });
      } else if (!useCloudinaryImages
        && src !== HC_NO_IMAGE_AVAILABLE_CDN
        && src !== HC_NO_IMAGE_AVAILABLE) {
        this.setState({
          src: HC_NO_IMAGE_AVAILABLE_CDN,
          alt: 'No image available',
        });
      } else if (src === HC_NO_IMAGE_AVAILABLE_CDN) {
        this.setState({
          src: HC_NO_IMAGE_AVAILABLE,
          alt: 'No image available',
        });
      }
    } else if (useCloudinaryImages
      && src !== CLOUDINARY_IMG_NOT_AVAILABLE
      && src !== NO_IMAGE_AVAILABLE) {
      this.setState({
        src: CLOUDINARY_IMG_NOT_AVAILABLE,
        alt: 'No image available',
      });
    } else if (!useCloudinaryImages
      && src !== NO_IMAGE_AVAILABLE_CDN
      && src !== NO_IMAGE_AVAILABLE) {
      this.setState({
        src: NO_IMAGE_AVAILABLE_CDN,
        alt: 'No image available',
      });
    } else if (src === NO_IMAGE_AVAILABLE_CDN) {
      this.setState({
        src: NO_IMAGE_AVAILABLE,
        alt: 'No image available',
      });
    }
    return null;
  }

  render() {
    const {
      className,
      title,
      onClick,
      productId,
      dataColorName
    } = this.props;
    const {
      alt,
      src,
    } = this.state;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <img
        className={className}
        src={src}
        ref={(ref) => { this.image = ref; }}
        alt={alt}
        onClick={onClick}
        onError={this.handleError}
        title={title}
        data-product-id={productId}
        data-color-name={dataColorName}
        data-swatch={dataColorName !== null}
      />
    );
  }
}

export default Image;
