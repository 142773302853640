import { SET_SEARCH_LIST_DATA_TO_UTAG, REJECTED_SEARCH_LIST, CLEAR_QR_FROM_UTAG } from 'srp/actions/actions';
import toString from 'lodash/toString';
import map from 'lodash/map';

function constructObjectWithNotNullProperties(state, action) {
  return {
    ...state,
    page_definition_id: toString(action.payload.page_definition_id),
    page_name: toString(action.payload.page_name),
    page_type: toString(action.payload.page_type),
    page_version: toString(action.payload.page_version),
    search_type: toString(action.payload.search_type),
    internal_search_term: toString(action.payload.internal_search_term),
    category_results: toString(action.payload.total),
    page_number: toString(action.payload.page),
    sort_method: action.payload.sortBy,
    previous_view_filter_submit:
      toString(action.payload.previousViewFilterSubmit),
    previous_view_sort_submit: toString(action.payload.previousViewSortSubmit),
    filter_selection: action.payload.filterOptions
      && action.payload.filterOptions.filterSelection,
    filter_type: action.payload.filterOptions
      && action.payload.filterOptions.filterType,
    page_template: toString(action.payload.page_template),
    previous_view_search_submit:
      toString(action.payload.previousViewSearchSubmit),
    product_cmos_catalog_id: map(action.payload.productsMetadata, 'cmosCatalogId'),
    product_cmos_item: map(action.payload.productsMetadata, 'cmosItem'),
    search_type_ahead: toString(action.payload.search_type_ahead),
    query_relax: toString(action.payload.query_relax),
    facet_selection: action.payload.facetOptions
      && action.payload.facetOptions.filterSelection,
    facet_type: action.payload.facetOptions
      && action.payload.facetOptions.filterType,
    previous_view_facet_submit: toString(
      action.payload.previousViewFacetSubmit
    ),
    recently_searched: action.payload.recently_searched,
  };
}
export default (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH_LIST_DATA_TO_UTAG:
      return constructObjectWithNotNullProperties(state, action);
    case CLEAR_QR_FROM_UTAG:
      return {
        ...state,
        query_relax: '',
      };
    case REJECTED_SEARCH_LIST:
      return {
      };
    default:
      return state;
  }
};
