import React, { Component } from 'react';
import { connect } from 'react-redux';
import NO_IMAGE_AVAILABLE from 'assets/images/no-image.jpeg';
import HC_NO_IMAGE_AVAILABLE from 'hcAssets/images/hc-no-image.jpeg';
import get from 'lodash/get';
import './image.scss';
import { InView } from 'react-intersection-observer';
import QuickLookButton from '../QuickLookButton/quickLookButton';
import FavoriteButton from '../Favorite/favoriteButton';
import CrossFadeImage from './Animations/transitionImages';
import QuickLookViewSimilarButton from '../QuickLookViewSimilarButton/quickLookViewSimilarButton';

const createImagePreloader = (src, onError) => () => {
  const image = new window.Image();
  image.onerror = onError;
  image.src = src;
  return image;
};

const activeImages = {
  MAIN_IMAGE: 'MAIN_IMAGE',
  ALT_IMAGE: 'ALT_IMAGE',
};

const defaultState = {
  activeImage: activeImages.MAIN_IMAGE,
  mainImageLoadFailed: false,
  altImageLoadFailed: false,
  isHover: false,
};

class Image extends Component {
  constructor(props) {
    super();
    this.state = defaultState;
    this.showAltImage = this.showAltImage.bind(this);
    this.showMainImage = this.showMainImage.bind(this);
    this.imageErrorHandler = this.imageErrorHandler.bind(this);

    const createPreloader = props.createPreloader || createImagePreloader;
    this.preload = createPreloader(props.src, this.imageErrorHandler);
  }

  componentDidMount() {
    if (IS_CLIENT && !this.props.enableLazyLoading && !this.props.lazyLoad) this.preload();
  }

  getActiveImageSrc() {
    const { activeImage, mainImageLoadFailed } = this.state;
    const { src: mainImage, altImage, brand } = this.props;
    if (activeImage === activeImages.ALT_IMAGE) {
      return altImage;
    }
    if (activeImage === activeImages.MAIN_IMAGE && mainImageLoadFailed) {
      if (brand === 'HC') {
        return HC_NO_IMAGE_AVAILABLE;
      }
      return NO_IMAGE_AVAILABLE;
    }
    return mainImage;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.src !== nextProps.src) {
      this.setState(defaultState);
    }
  }

  imageErrorHandler() {
    if (this.state.activeImage === activeImages.MAIN_IMAGE) {
      this.setState({
        mainImageLoadFailed: true,
      });
    } else {
      this.setState({
        activeImage: activeImages.MAIN_IMAGE,
        altImageLoadFailed: true,
      });
    }
  }

  showMainImage() {
    this.setState({ activeImage: activeImages.MAIN_IMAGE });
  }

  showAltImage() {
    if (this.state.altImageLoadFailed) return;

    this.setState({ activeImage: activeImages.ALT_IMAGE });
  }

  isHover() {
    this.setState({ isHover: true });
  }

  isNotHover() {
    this.setState({ isHover: false });
  }

  lazyLoadImage(source, imageLoadProps) {
    const {
      alt,
      title,
      altImage,
    } = this.props;

    const image = (
      <img
        {...imageLoadProps}
        onMouseOver={altImage && this.showAltImage}
        onMouseOut={altImage && this.showMainImage}
        alt={alt}
        title={title}
        onError={(event) => {
          this.imageErrorHandler(event);
        }}
        name="mainImage"
      />
    );

    return source ? (
      <InView
        triggerOnce
        rootMargin="200px"
      >
        {({ inView, ref }) => (
          <div ref={ref}>
            {
            (inView)
              ? (
                image
              )
              : null
          }
          </div>
        )}
      </InView>
    ) : (
      image
    );
  }

  renderCtaButton = () => {
    const { quickLookVSToggle, showViewSimilarButton } = this.props;

    if(quickLookVSToggle && showViewSimilarButton() && this.props.isDomestic){
      return this.renderQuickLookViewSimilarButton();
    } 

    return this.renderQuickLookButton();
  }

  renderQuickLookButton = () => {
    const { quickLookToggle, quickLookVSToggle, showQLModalWindow } = this.props;

    if(!quickLookVSToggle && quickLookToggle){
      return (
      <QuickLookButton
        showQLModalWindow={showQLModalWindow}
        isHover={this.state.isHover}
      />
      )
    }

    return;
  }

  renderQuickLookViewSimilarButton = () => {
    const { quickLookVSToggle, selectedColorName, showQLModalWindow, productId, defaultColorName, selectedColorCode,
      groupChildColorName, groupChildId } = this.props;

    if(quickLookVSToggle){
      return (<QuickLookViewSimilarButton 
        showQLModalWindow={showQLModalWindow}
        isHover={this.state.isHover}
        selectedColorName={groupChildColorName ? groupChildColorName : selectedColorName}
        productId={groupChildId ? groupChildId : productId}
        defaultColorName={defaultColorName}
        selectedColorCode={selectedColorCode}
      />)
    } 
    return;
  }

  render() {
    const {
      alt,
      title,
      altImage,
      enableLazyLoading = false,
      favoriteToggle = false,
      isSwatchHover,
      isUIPRICTest,
    } = this.props;
    const src = this.getActiveImageSrc();
    const imageLoadProps = enableLazyLoading && !this.props.isDomLoadComplete ? { 'data-src': src } : { src };
    return (
      <div
        className="main-image-container"
        onMouseEnter={() => {
          if (altImage) {
            this.showAltImage();
          }
          this.isHover();
        }}
        onMouseLeave={() => {
          if (altImage) {
            this.showMainImage();
          }
          this.isNotHover();
        }}
        onFocus={() => {
          this.isHover();
        }}
        onBlur={() => {
          this.isNotHover();
        }}
      >
        <CrossFadeImage
          alt={alt}
          altImage={altImage}
          altImageLoadFailed={this.state.altImageLoadFailed}
          isHover={this.state.isHover}
          imageErrorHandler={this.imageErrorHandler}
          transitionToImage={src}
          isSwatchHover={isSwatchHover}
          mainImageLoadFailed={this.state.mainImageLoadFailed}
          productId={this.props.productId}
          {...imageLoadProps}
          title={title}
        />
        {!isUIPRICTest && favoriteToggle && this.props.displayable
              && (
              <FavoriteButton
                productId={this.props.productId}
                isFavorite={this.props.isFavorite}
                isDisplayAsGroups={this.props.isDisplayAsGroups}
                cmosItem={this.props.cmosItem}
                cmosCatalogId={this.props.cmosCatalogId}
              />
              )
          }
        {this.renderCtaButton()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
    brand: state.brand_name?.env,
    isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
  };
}

export default connect(mapStateToProps)(Image);
export const UnconnectedImage = Image;
