import { DRAWER_HANDLER } from 'cms/actions';

const initialState = { open: false };

export default (state = initialState, action = {}) => {
    switch (action.type) {
      case DRAWER_HANDLER:
        return {
          ...state,
          open: action.payload,
        };
      default:
        return state;
    }
  };
  