import React from 'react';
import property from 'lodash/property';
import parseInt from 'lodash/parseInt';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import flow from 'lodash/flow';
import partialRight from 'lodash/partialRight';
import classnames from 'classnames';
import { renderFormattedPrice, renderFormattedPromoPrice, renderDiscountPercentage } from 'client-utils/utilities-price';
import { getTypeOfSalePrice, concatSaleType } from 'client-utils/utilities-page';
import PriceAdornment from 'shared/components/SalePrice/components/PriceAdornment/priceAdornment';
import './salePrice.scss';

const getMinPriceAdornment = partialRight(minBy, flow(property('price'), parseInt));
const getMaxPriceAdornment = partialRight(maxBy, flow(property('price'), parseInt));

function getSaleDiscountPercentage(originalPrice, markDownPrice) {
  const discountPercentage = renderDiscountPercentage(originalPrice, markDownPrice);
  return (
    <span className="product-discount">{`${discountPercentage}% Off`}</span>
  );
}

function setItemSaleTypeUtag(retailPrice, original, promotionalPrice) {
  const typeOfSalePrice = getTypeOfSalePrice(retailPrice, original, promotionalPrice);
  if (typeof window !== 'undefined' && window.utag_data_dt) {
    const itemSaleType = window?.utag_data_dt?.item_sale_type || '';
    if (!itemSaleType?.includes(typeOfSalePrice)) {
      window.utag_data_dt = {
        ...window.utag_data_dt,
        item_sale_type: concatSaleType(itemSaleType, typeOfSalePrice)
      };
    }
  }
}

function getPriceAdornments(adornments, isPLP, currencyCode, priceAdornmentColor,
  plpSRPPricingToggle, isUIPRICTest, newProductThumbStyle, promotionalPrice, isDomestic, salePercentageAbTest, brand) {
  const retailPrice = getMinPriceAdornment(adornments);
  const maxPrice = getMaxPriceAdornment(adornments);
  const priceDisplay = (brand === 'HC'? '' : "price-display");
  return (
    <span className={priceDisplay}>
      <PriceAdornment
        price={maxPrice.price}
        currencyCode={currencyCode}
        plpSRPPricingToggle={plpSRPPricingToggle}
        isUIPRICTest={isUIPRICTest}
        hasPromoPrice={!!promotionalPrice}
      />
      {(salePercentageAbTest && isDomestic) ? getSaleDiscountPercentage(maxPrice.price, retailPrice.price) : null}
      <PriceAdornment
        currentPrice
        label={(isUIPRICTest || plpSRPPricingToggle) && !isPLP ? '' : retailPrice.label}
        color={priceAdornmentColor}
        price={retailPrice.price}
        currencyCode={currencyCode}
        nonStrikeplpSRPPricingToggle={plpSRPPricingToggle}
        isUIPRICTest={isUIPRICTest}
        newProductThumbStyle={newProductThumbStyle}
        hasPromoPrice={!!promotionalPrice}
      />
      {' ' /* Allow line to wrap */}
    </span>
  );
}

function getFlattenedPriceAdornments(adornLabel, original, priceAdornmentColor,
  retailPrice, isPLP, plpSRPPricingToggle, isUIPRICTest, newProductThumbStyle, 
  promotionalPrice, isDomestic, salePercentageAbTest, brand) {
  const priceDisplay = (brand === 'HC'? '' : "price-display");
  return (
    <span className={priceDisplay}>
      <PriceAdornment
        price={original}
        isPLP={isPLP}
        plpSRPPricingToggle={plpSRPPricingToggle}
        isUIPRICTest={isUIPRICTest}
        hasPromoPrice={!!promotionalPrice}
      />
      {(salePercentageAbTest && isDomestic) ? getSaleDiscountPercentage(original, retailPrice) : null}
      <PriceAdornment
        currentPrice
        label={adornLabel}
        color={priceAdornmentColor}
        price={retailPrice}
        isPLP={isPLP}
        nonStrikeplpSRPPricingToggle={plpSRPPricingToggle}
        isUIPRICTest={isUIPRICTest}
        newProductThumbStyle={newProductThumbStyle}
        hasPromoPrice={!!promotionalPrice}
      />
      {' ' /* Allow line to wrap */}
    </span>
  );
}

function getRetailPrice(retailPrice, currencyCode, isPLP,
  plpSRPPricingToggle, isUIPRICTest) {
    return (
      <span className={classnames(
        { 'price-no-promo': (isUIPRICTest || plpSRPPricingToggle) }
      )}
      >
      {isPLP ? retailPrice : renderFormattedPrice(retailPrice, currencyCode)}
      </span>
    );
}

function addSalePricePromoLabel(original, retailPrice, promotionalPrice, promoText) {
  const promo = renderFormattedPromoPrice(promoText, promotionalPrice);
  if ((retailPrice && original) && !promotionalPrice) {
    return `WAS ${original}, NOW ${retailPrice}`;
  } else if ((retailPrice && promotionalPrice) && !original) {
    return `${retailPrice}, ${promo}`;
  } else if (original && retailPrice && promotionalPrice) {
    return `WAS ${original}, NOW ${retailPrice}, ${promo}`;
  }
}

function displayPriceAdornments(
  adornments,
  currencyCode,
  priceAdornmentColor,
  retailPrice,
  plpSRPPricingToggle,
  isPLP,
  isUIPRICTest,
  newProductThumbStyle = false,
  promotionalPrice,
  isDomestic,
  salePercentageAbTest,
  brand
) {
  return (adornments && adornments.length > 1)
    ? getPriceAdornments(adornments, isPLP,
      currencyCode, priceAdornmentColor, plpSRPPricingToggle, isUIPRICTest, 
      newProductThumbStyle, promotionalPrice, isDomestic, salePercentageAbTest, brand)
    : getRetailPrice(retailPrice,
      currencyCode, isPLP, plpSRPPricingToggle, isUIPRICTest);
}

function displayFlattenedPriceAdornments(original, currencyCode,
  priceAdornmentColor, retailPrice, isPLP, adornLabel, plpSRPPricingToggle,
  isUIPRICTest, newProductThumbStyle, promotionalPrice, isDomestic, salePercentageAbTest, brand) {
  return original
    ? getFlattenedPriceAdornments(
      adornLabel,
      original,
      priceAdornmentColor,
      retailPrice,
      isPLP,
      plpSRPPricingToggle,
      isUIPRICTest,
      newProductThumbStyle,
      promotionalPrice,
      isDomestic,
      salePercentageAbTest,
      brand
    )
    : getRetailPrice(retailPrice, currencyCode, isPLP, plpSRPPricingToggle, isUIPRICTest);
}

const SalePrice = ({
  currencyCode,
  retailPrice,
  adornments,
  priceAdornmentColor,
  isPLP,
  original,
  adornLabel,
  plpSRPPricingToggle = false,
  promotionComponent,
  isUIPRICTest,
  newProductThumbStyle = false,
  promotionalPrice = null,
  promoText,
  isDomestic,
  salePercentageAbTest,
  brand
}) => {
  if (!retailPrice) {
    return false;
  }
  if (salePercentageAbTest && isDomestic) {
    setItemSaleTypeUtag(retailPrice, original, promotionalPrice);
  }

  const classStyle = 'product-thumbnail__sale-price enhanced';
  return (
    <div tabIndex={0} className={classStyle} aria-label={addSalePricePromoLabel(original, retailPrice, promotionalPrice, promoText)}>
      {isPLP ? displayFlattenedPriceAdornments(original, currencyCode,
        priceAdornmentColor, retailPrice, isPLP, adornLabel, plpSRPPricingToggle,
        isUIPRICTest, newProductThumbStyle, promotionalPrice, isDomestic, salePercentageAbTest, brand)
        : displayPriceAdornments(
          adornments,
          currencyCode,
          priceAdornmentColor,
          retailPrice,
          plpSRPPricingToggle,
          isPLP,
          isUIPRICTest,
          newProductThumbStyle,
          promotionalPrice,
          isDomestic,
          salePercentageAbTest,
          brand
        )}
      {promotionComponent && promotionComponent()}
    </div>
  );
};

export default SalePrice;
