import get from 'lodash/get';
import httpWithLogging from 'universal/http-client';
import { openModal } from 'shared/components/Modal/actions';
import { splitHistoryDataByTypes, formatOrderHistoryData } from './actionsUtils';
import { statusMap } from '../components/OrderStatusLineItem/orderStatusUtils';

export const SET_REDIRECT_ON_PERSONAL_PROMO_PAGE = 'SET_REDIRECT_ON_PERSONAL_PROMO_PAGE';
export const SET_REDIRECT_ON_ACCOUNT_OVERVIEW_PAGE = 'SET_REDIRECT_ON_ACCOUNT_OVERVIEW_PAGE';
export const SET_REDIRECT_ON_RESET_PASSWORD_PAGE = 'SET_REDIRECT_ON_RESET_PASSWORD_PAGE';
export const LOADING_PERSONALIZED_PROMOTIONS = 'LOADING_PERSONALIZED_PROMOTIONS';
export const RESOLVED_PERSONALIZED_PROMOTIONS = 'RESOLVED_PERSONALIZED_PROMOTIONS';
export const REJECTED_PERSONALIZED_PROMOTIONS = 'REJECTED_PERSONALIZED_PROMOTIONS';
export const NO_PERSONALIZED_PROMOTIONS_AVAILABLE = 'NO_PERSONALIZED_PROMOTIONS_AVAILABLE';
export const LOADING_ORDER_HISTORY_DATA = 'LOADING_ORDER_HISTORY_DATA';
export const RESOLVED_ORDERHISTORY_DATA = 'RESOLVED_ORDERHISTORY_DATA';
export const REJECTED_ORDERHISTORY_DATA = 'REJECTED_ORDERHISTORY_DATA';
export const NO_ORDERHISTORY_DATA = 'NO_ORDERHISTORY_DATA';
export const NO_ORDERHISTORY_DATA_AVAILABLE = 'NO_ORDERHISTORY_DATA_AVAILABLE';
export const RESOLVED_CCPA_FORM = 'RESOLVED_CCPA_FORM';
export const REJECTED_CCPA_FORM = 'REJECTED_CCPA_FORM';
export const RESOLVED_RECAPTCHA = 'RESOLVED_RECAPTCHA';
export const REJECTED_RECAPTCHA = 'REJECTED_RECAPTCHA';
export const RECAPTCHA_SITE = 'RECAPTCHA_SITE';
export const REJECTED_SEND_EMAIL = 'REJECTED_SEND_EMAIL';
export const RESOLVED_SEND_EMAIL = 'RESOLVED_SEND_EMAIL';
export const LOADING_RESET_PASSWORD = 'LOADING_RESET_PASSWORD';
export const RESOLVED_RESET_PASSWORD = 'RESOLVED_RESET_PASSWORD';
export const REJECTED_RESET_PASSWORD = 'REJECTED_RESET_PASSWORD';
export const LOGIN_PAGES_UTAG_DATA = 'LOGIN_PAGES_UTAG_DATA';
export const REGISTER_PAGE_UTAG_DATA = 'REGISTER_PAGE_UTAG_DATA';
export const CCPA_UTAG_DATA = 'CCPA_UTAG_DATA';
export const RESET_PW_PAGELOAD_UTAG_DATA = 'RESET_PW_PAGELOAD_UTAG_DATA';
export const RESOLVED_COMBINED_ORDER_HISTORY = 'RESOLVED_COMBINED_ORDER_HISTORY';
export const SET_GUEST_ORDER_HISTORY = 'SET_GUEST_ORDER_HISTORY';
export const LOADING_REPLENISHMENT_STATUS = 'LOADING_REPLENISHMENT_STATUS';
export const RESOLVED_REPLENISHMENT_STATUS = 'RESOLVED_REPLENISHMENT_STATUS';
export const REJECTED_REPLENISHMENT_STATUS = 'REJECTED_REPLENISHMENT_STATUS';
export const GUEST_HISTORY_UTAG = 'GUEST_HISTORY_UTAG';
export const GUEST_DETAILS_UTAG = 'GUEST_DETAILS_UTAG';
export const ORDER_HISTORY_UTAG = 'ORDER_HISTORY_UTAG';
export const MAP_OH_ITEM_ADDRESSES = 'MAP_OH_ITEM_ADDRESSES';
export const SET_RAW_OH_DATA = 'SET_RAW_OH_DATA';
export const SET_FETCHED_ALL_OH = 'SET_FETCHED_ALL_OH';
export const LOADING_CANCELLED_ORDERS = 'LOADING_CANCELLED_ORDERS';
export const SET_CANCELLED_ORDER_HISTORY = 'SET_CANCELLED_ORDER_HISTORY';
export const DT_LOGIN_FAILURE = 'DT_LOGIN_FAILURE';
export const REJECTED_CANCELLED_ORDER_HISTORY = 'REJECTED_CANCELLED_ORDER_HISTORY';
export const REJECTED_ORDER_HISTORY = 'REJECTED_ORDER_HISTORY';
export const SET_SELECTED_REPLENISHABLE = 'SET_SELECTED_REPLENISHABLE';
export const SET_OH_SEARCH_TEXT = 'SET_OH_SEARCH_TEXT';
export const START_SEARCHING_OH = 'START_SEARCHING_OH';

export function setRedirectURL(targetURL, sourcePageType) {
  return (dispatch) => {
    let redirectFromPage = '';
    if (sourcePageType === 'specialoffers') {
      redirectFromPage = SET_REDIRECT_ON_PERSONAL_PROMO_PAGE;
    } else if (sourcePageType === 'accountoverview') {
      redirectFromPage = SET_REDIRECT_ON_ACCOUNT_OVERVIEW_PAGE;
    } else if (sourcePageType === 'resetpassword') {
      redirectFromPage = SET_REDIRECT_ON_RESET_PASSWORD_PAGE;
    }
    dispatch({
      type: redirectFromPage,
      payload: { targetURL, redirectType: 302 },
    });
  };
}

export function openRegisterEmailErrorModal() {
  return (dispatch) => dispatch(openModal({ type: 'RegisterEmailErrorModal', className: 'register-email-modal' }));
}

export function openForgotPasswordModal(modalClassName, data) {
  return (dispatch) => dispatch(openModal({ type: 'ForgotPasswordModalDT', className: modalClassName, props: { email: data?.email, modalClassName, isOrderHistPage: data?.isOrderHistPage } }));
}

export function getReplenishableStatus() {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state, 6000, true);
    const session = get(state, 'session', {});
    const DYN_USER_ID = get(session, 'DYN_USER_ID', '');
    dispatch({ type: LOADING_REPLENISHMENT_STATUS });
    return requestApi
      .get(`${NMConfig.API_REPLENISHMENT_STATUS}?profileId=${DYN_USER_ID}`) // Migration list || return true or false
      .then((res) => {
        return dispatch({
          type: RESOLVED_REPLENISHMENT_STATUS,
          payload: res.data.hasReplenishment,
        });
      }).catch((err) => dispatch({
        type: REJECTED_REPLENISHMENT_STATUS,
        payload: err,
      }));
  };
}

export function getOrderHistoryData(
  jwtToken,
  username,
  {
    sort = {},
    filter = {},
    pagination = {},
    search = '',
  } = {},
  from = 0,
) {
  const size = 50;

  function handleOrderHistory(res, state, dispatch) {
    if (res.data.length < size) {
      dispatch({
        type: SET_FETCHED_ALL_OH,
        payload: true,
      });
    }

    dispatch({
      type: SET_OH_SEARCH_TEXT,
      payload: search,
    });

    const allData = [...state.profile.orderHistory.pagination.rawOrderHistoryData, ...res.data];
    dispatch({
      type: SET_RAW_OH_DATA,
      payload: allData,
    });
    const hasCancelled = allData.some((item) => statusMap[item.orderStatus]?.startsWith('Cancel'));
    const dataObj = formatOrderHistoryData(allData);
    return dispatch({
      type: RESOLVED_COMBINED_ORDER_HISTORY,
      payload: { orders: splitHistoryDataByTypes(dataObj), hasCancelled },
    });
  }

  function handleCancelledOrders(res, dispatch) {
    const formattedData = formatOrderHistoryData(res.data);
    dispatch({ type: SET_CANCELLED_ORDER_HISTORY, payload: formattedData });
  }

  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state, 6000, true);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    };

    const togglesObj = {
      connect: get(state, 'toggles.OH_CONNECT_ORDERS', false),
      'in-store': get(state, 'toggles.OH_STORE_ORDERS', false),
    };
    const filterArr = ['connect', 'in-store'].filter((filter) => togglesObj[filter]);
    if (filterArr.length !== 2) filter.channels = [...filterArr, 'online'];

    const body = {
      sort: {
        sortType: 'desc',
        ...sort,
      },
      pagination: {
        from,
        size,
        ...pagination,
      },
      filter: {
        ...filter,
      },
    };

    if (search) body.searchText = search;

    const isCancelled = filter.status === 'CX';

    const loadingAction = isCancelled
      ? { type: LOADING_CANCELLED_ORDERS, payload: true }
      : { type: LOADING_ORDER_HISTORY_DATA, payload: true };

    dispatch(loadingAction);

    return requestApi
      .post(`${NMConfig.API_DT_ORDER_HISTORY}/${username}/orders`, body, { headers })
      .then((res) => {
        isCancelled
          ? handleCancelledOrders(res, dispatch)
          : handleOrderHistory(res, state, dispatch);
      }).catch(() => {
        const type = isCancelled ? REJECTED_CANCELLED_ORDER_HISTORY : REJECTED_ORDER_HISTORY;
        dispatch({ type });
      });
  };
}

export function mapAddressesToItems(mappedOrders) {
  return (dispatch, getState) => {
    const state = getState();
    const orders = { ...state.profile.orderHistory.combinedOrderHistory };
    const orderIndex = orders.allOrders.findIndex(
      (order) => order[0].formattedOrderId === mappedOrders.formattedOrderId
    );
    for (let i = 0; i < mappedOrders.orderDetails.length; i++) {
      for (let j = 0; j < mappedOrders.orderDetails[i].items.length; j++) {
        const mappedItem = mappedOrders.orderDetails[i].items[j];
        for (let k = 0; k < orders.allOrders[orderIndex].length; k++) {
          const browseItem = orders.allOrders[orderIndex][k];
          if (
            mappedItem.cmosCatalogItem === browseItem.item.cmosCatalogItem
            && mappedItem.status === browseItem.orderStatus
            && (mappedItem.trackingLink === browseItem.trackingLink
              || (!mappedItem.trackingLink && !browseItem.trackingLink))
          ) {
            browseItem.shippingAddress = mappedOrders.orderDetails[i].shippingAddress;
          }
        }
      }
    }
    dispatch({
      type: MAP_OH_ITEM_ADDRESSES,
      payload: orders,
    });
  };
}

export function submitCcpaForm(ccpaFormData, errorCallback) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    return requestApi
      .post(NMConfig.API_SUBMIT_CCPA_FORM, ccpaFormData).then((resObj) => {
        dispatch({
          type: RESOLVED_CCPA_FORM,
          payload: resObj.data,
        });
        return resObj.data;
      }).catch((e) => {
        if (errorCallback) {
          errorCallback(e);
        }
        dispatch({
          type: REJECTED_CCPA_FORM,
        });
      });
  };
}

export function validateReCaptcha(captchaValue, resetCaptcha) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    return requestApi
      .post(NMConfig.API_RECAPTCHA_VERIFY, { captchaValue }).then((resObj) => {
        if (resObj.data && resObj.data.success === true) {
          return dispatch({
            type: RESOLVED_RECAPTCHA,
            payload: resObj.data.success,
          });
        } resetCaptcha();
        dispatch({
          type: REJECTED_RECAPTCHA,
        });
        return resObj.data;
      }).catch(() => {
        resetCaptcha();
        dispatch({
          type: REJECTED_RECAPTCHA,
        });
      });
  };
}

export function sendForgotPwEmail(email, successCallback, errorCallback) {
  return (dispatch, getState) => {
    const state = getState();
    const apiTimeout = get(state, 'apiTimeouts.FORGOT_PW_API_TIMEOUT', 5000);
    const requestApi = httpWithLogging(state, apiTimeout, true);
    return requestApi
      .post(NMConfig.API_SEND_FORGOT_PW_EMAIL, { email }).then((resObj) => {
        if (resObj) {
          dispatch({
            type: RESOLVED_SEND_EMAIL,
            payload: { email, successMessage: resObj.data },
          });
          return successCallback();
        }
        return errorCallback();
      }).catch(() => {
        errorCallback();
        dispatch({
          type: REJECTED_SEND_EMAIL,
        });
      });
  };
}

export const sendCCPAUtagDataOnPageload = () => ({ type: CCPA_UTAG_DATA });
export const sendResetPwPageUtagDataOnPageload = () => ({ type: RESET_PW_PAGELOAD_UTAG_DATA });
export const sendLoginPagesUtagDataOnPageload = () => ({ type: LOGIN_PAGES_UTAG_DATA });
export const loadRegisterPageUtagData = () => ({ type: REGISTER_PAGE_UTAG_DATA });
export const loadGuestOrderPageUtagData = () => ({ type: GUEST_HISTORY_UTAG });
export const loadGuestOrderDetailsUtagData = () => ({ type: GUEST_DETAILS_UTAG });
export const loadOrderHistoryPageUtagData = () => ({ type: ORDER_HISTORY_UTAG });
export const loginFailureUtagData = () => ({ type: DT_LOGIN_FAILURE });

export const resetPassword = (
  requestBody,
  successCallback,
  errorCallback
) => (dispatch, getState) => {
  dispatch({ type: LOADING_RESET_PASSWORD });

  const state = getState();
  const pwMessagingToggle = get(state, 'toggles.RESET_PW_MESSAGING', false);
  const requestApi = pwMessagingToggle
    ? httpWithLogging(state, 3000, true)
    : httpWithLogging(state);
  const endpoint = pwMessagingToggle
    ? NMConfig.API_RESET_PASSWORD
    : NMConfig.API_RESET_PASSWORD_PROXY;

  return requestApi
    .post(endpoint, requestBody)
    .then((resObj) => {
      if (resObj) {
        dispatch({
          type: RESOLVED_RESET_PASSWORD,
          payload: resObj.data,
        });
        return successCallback();
      }
      return errorCallback();
    }).catch((e) => {
      dispatch({
        type: REJECTED_RESET_PASSWORD,
      });
      return errorCallback(e?.response?.data);
    });
};

export function setGuestOrderHistory(orderHistory) {
  return {
    type: SET_GUEST_ORDER_HISTORY,
    payload: orderHistory,
  };
}

export function setSelectedReplenishable(replenItem) {
  return {
    type: SET_SELECTED_REPLENISHABLE,
    payload: replenItem,
  };
}

export function startSearching() {
  return {
    type: START_SEARCHING_OH,
  };
}
