import { combineReducers } from 'redux';
import profileReducer, {
  acctOverviewReducer, ccpaFormReducer, resetPasswordReducer, dtLoginReducer,
} from './profileReducer';
import orderHistoryReducer from './orderHistoryReducer';

export default combineReducers({
  personalizedPromoPage: profileReducer,
  accountOverviewPage: acctOverviewReducer,
  resetPasswordPage: resetPasswordReducer,
  orderHistory: orderHistoryReducer,
  ccpaForm: ccpaFormReducer,
  dtLogin: dtLoginReducer,
});
