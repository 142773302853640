import {
  TRANSFORMATION_DEFAULT_VALUES,
  PREFIX,
  UNIT,
  SUPPORTED_CROP_MODES,
  SUPPORTED_GRAVITY_MODES,
} from './constants';

export function parseTransformation(rawTransformation) {
  return rawTransformation.split(',')
    .reduce(
      (transformation, param) => parseParameter(transformation, param),
      TRANSFORMATION_DEFAULT_VALUES
    );
}

function parseParameter(transformation, param) {
  const paramPrefix = param.substr(0, param.indexOf('_') + 1);
  switch (paramPrefix) {
    case PREFIX.CROP: return parseCropParameter(transformation, param);
    case PREFIX.WIDTH: return parseWidthParameter(transformation, param);
    case PREFIX.HEIGHT: return parseHeightParameter(transformation, param);
    case PREFIX.ASPECT_RATIO: return parseAspectRatioParameter(transformation, param);
    case PREFIX.X: return parseXParameter(transformation, param);
    case PREFIX.Y: return parseYParameter(transformation, param);
    case PREFIX.GRAVITY: return parseGravityParameter(transformation, param);
    case PREFIX.FORMAT:
    case PREFIX.QUALITY:
    case PREFIX.RADIUS:
    case PREFIX.EFFECT:
    case PREFIX.OPACY:
    case PREFIX.BORDER:
    case PREFIX.BACKGROUND:
    case PREFIX.COLOR:
    case PREFIX.COLOR_SPACE:
      return transformation;
    default: throw new Error('Transformation constains a unsupported parameter type.');
  }
}

function parseCropParameter(transformation, param) {
  if (!SUPPORTED_CROP_MODES.includes(param)) {
    throw new Error('Transformation has unsupported crop mode.');
  }

  return { ...transformation, cropMode: param };
}

function parseWidthParameter(transformation, param) {
  return { ...transformation, width: parseNumberValue(param.substr(PREFIX.WIDTH.length)) };
}

function parseHeightParameter(transformation, param) {
  return { ...transformation, height: parseNumberValue(param.substr(PREFIX.HEIGHT.length)) };
}

function parseNumberValue(valueStr) {
  return {
    value: parseFloat(valueStr),
    unit: valueStr.includes('.') ? UNIT.PERCENT : UNIT.PIXEL,
  };
}

function parseAspectRatioParameter(transformation, param) {
  const value = param.substr(PREFIX.ASPECT_RATIO.length);
  if (value.includes(':')) {
    const [width, height] = value.split(':').map(parseFloat);
    return { ...transformation, aspectRatio: width / height };
  }
  return { ...transformation, aspectRatio: parseFloat(value) };
}

function parseXParameter(transformation, param) {
  return { ...transformation, x: parseNumberValue(param.substr(PREFIX.X.length)) };
}

function parseYParameter(transformation, param) {
  return { ...transformation, y: parseNumberValue(param.substr(PREFIX.Y.length)) };
}

function parseGravityParameter(transformation, param) {
  if (!SUPPORTED_GRAVITY_MODES.includes(param)) {
    throw new Error('Transformation has unsupported gravity mode.');
  }

  return { ...transformation, gravity: param };
}
