import { localStorage } from 'window-or-global';
import NO_IMAGE_AVAILABLE from 'assets/images/no-image.jpeg';

export const internationalMiniCartHtml = (intlCartName, country) => {
  const localInternationalCart = (() => {
    const localInternationalCartData = localStorage.getItem(intlCartName);
    return localInternationalCartData
      ? JSON.parse(localInternationalCartData)
      : [];
  })();
  const countryCode = country.toLowerCase();
  let cartHtmlData = '';
  let totalCartPrice = 0;
  let totalCartQty = 0;
  let currencyCode = '';
  if (localInternationalCart && localInternationalCart.length > 0) {
    currencyCode = localInternationalCart[0].price.currencyCode;
    localInternationalCart.map((cartItem) => {
      totalCartPrice += Number(cartItem.price.retailPrice * cartItem.productQty);
      totalCartQty += Number(cartItem.productQty);
      cartHtmlData += `<tr>
      <th scope="row"> <a href="${cartItem.productUrl}">
          <img src="${(cartItem.images.small.url || cartItem.images.thumbnail.url || cartItem.images.dynamic.url || NO_IMAGE_AVAILABLE)}" alt="${cartItem.description}" /> </a>
      </th>
      <td>
        <p class="OneLinkNoTx">
          <b> <a href="${cartItem.productUrl}">${cartItem.name} ${cartItem.description}</a> </b>
        </p>
        ${(cartItem.cmosCatalogId && cartItem.cmosItem) && `<p class="catItem OneLinkNoTx"> Item: ${cartItem.cmosCatalogId}_${cartItem.cmosItem} </p>`}
        ${cartItem.price.adornments ? `<p class="m-minicart-price"> <span class="del mute "> <span class="price-adorn-label">${cartItem.price.adornments[0].label}:</span> <span class="price-adorn-value">${cartItem.price.currencyCode} ${cartItem.price.adornments[0].price * cartItem.productQty}</span><br> </span> <span class="price-adornment-highlight "> <span class="price-adorn-label">${cartItem.price.adornments[1].label}:</span> <span class="price-adorn-value">${cartItem.price.currencyCode} ${cartItem.price.adornments[1].price * cartItem.productQty}</span> </span> </p>` : `<p class="m-minicart-price">
          <span class="price-adorn-value"> ${cartItem.price.currencyCode}${cartItem.price.retailPrice * cartItem.productQty} </span> </p>`}
        ${cartItem.productQty ? `<p>Qty: ${cartItem.productQty}</p>` : ''}
        ${cartItem.color ? `<p>Color: ${cartItem.color}</p>` : ''}
        ${cartItem.size ? `<p>Size: ${cartItem.size}</p>` : ''}
        ${cartItem.stockStatusMessage ? `<p>${cartItem.stockStatusMessage}</p>` : ''}
        ${cartItem.shippingStatusMessage ? `<p>${cartItem.shippingStatusMessage}</p>` : ''}
      </td>
    </tr>`;
      return true;
    });
  }
  return (
    `${localInternationalCart && localInternationalCart.length > 0 ? `<div id="cartlinkspandivgutter" class="header-drop-down layer m-popUp-minicart prev_ui">
    <div class="header-drop-down-top">
      <div class="float-left">
        <p class="gutter-bottom-quarter"> <a id="lbl_easycartshoppingbag_top"
            href="/en-${countryCode}/international/cart"> View shopping bag</a> </p>
        <p> <span class="mute">${totalCartQty} items in your bag</span> </p>
        <span role="button" class="cart-close-x" aria-label="Shopping bag opened and will automatically close in 10 seconds. Close" tabindex="0" >&times;</span>
      </div>
      <div class="float-right gutter-right align-right">
        <p class="gutter-bottom-quarter">Subtotal:${currencyCode} ${totalCartPrice}</p>
        <p> <a id="lbl_easycarheader_top"
            href="/en-${countryCode}/international/cart"
            class="button button-action button-small">Checkout</a> </p>
      </div>
    </div>
    <div class="header-drop-down-content cartitems" id="miniCartDisplayDiv">
      <table class="table-checkout-menu" role="presentation">
        <p>Please note: Items and promotional pricing are not reserved until checkout is complete.</p>
        ${cartHtmlData}
      </table>
    </div>
    <div class="header-drop-down-bottom">
      <div class="float-left">
        <p class="gutter-bottom-quarter"> <a id="lbl_easycartshoppingbag_bottom"
            href="/en-${countryCode}/international/cart"> View shopping bag</a> </p>
        <p> <span class="mute">${totalCartQty} items in your bag</span> </p>
      </div>
      <div class="float-right gutter-right align-right">
        <p class="gutter-bottom-quarter">Subtotal:${currencyCode} ${totalCartPrice} </p>
        <p> <a id="lbl_easycarheader_bottom"
            href="/en-${countryCode}/international/cart"
            class="button button-action button-small">Checkout</a> </p>
      </div>
    </div>
</div>` : ''}`
  );
};
