/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { CLOUDINARY_IMG_NOT_AVAILABLE, HC_CLOUDINARY_IMG_NOT_AVAILABLE, isValidCloudinaryUrl } from 'client-utils/utilities-cloudinary';
import './transitionImages.scss';
import get from 'lodash/get';
import { connect } from 'react-redux';
import CloudinaryPicture from 'client/common/components/CloudinaryPicture/CloudinaryPicture';

export class TransitionCrossFadeImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visited: false,
      showImage: props.src,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!this.state.visited && newProps.isHover) {
      this.setState({ visited: true });
    }
    if (newProps.src === newProps.altImage) return;

    if (!newProps.isSwatchHover) {
      this.setState({ showImage: newProps.src });
    }
  }

  render() {
    const {
      alt,
      altImage,
      altImageLoadFailed,
      imageErrorHandler,
      imageLoadHandler,
      isHover,
      isSwatchHover,
      mainImageLoadFailed,
      src,
      title,
      productId,
      selectedColorName,
      cloudinaryDPRToggle,
      brand,
    } = this.props;

    const showAltImage = () => (((!altImageLoadFailed && !mainImageLoadFailed) && altImage
              && this.state.visited) || isSwatchHover);

    const shouldFadeOutImage = () => {
      return (!altImageLoadFailed && !mainImageLoadFailed) && isHover && altImage;
    };

    const showErrorImage = () => {
      return (
        <img
          src={brand === 'HC' ? HC_CLOUDINARY_IMG_NOT_AVAILABLE : CLOUDINARY_IMG_NOT_AVAILABLE}
          alt="No image available"
          title="No image available"
          name="mainImage"
          data-top-image
        />
      );
    };

    const imageSrc = isSwatchHover ? src : altImage;
    const fallbackImageSrc = isSwatchHover ? src : this.state.showImage;

    return (
      <Fragment>
        {showAltImage() && (
          isValidCloudinaryUrl(imageSrc) && cloudinaryDPRToggle ? (
            <CloudinaryPicture
              src={imageSrc}
              className={classnames({
                'bottom-image': true,
                'image-fade-out': !shouldFadeOutImage(),
              })}
              alt={alt}
              title={title}
              onError={(event) => { imageErrorHandler(event); }}
              onLoad={(event) => { imageLoadHandler(event); }}
              name="mainImage"
            />
          ) : (
            <img
              src={imageSrc}
              className={classnames({
                'bottom-image': true,
                'image-fade-out': !shouldFadeOutImage(),
              })}
              alt={alt}
              title={title}
              onError={(event) => { imageErrorHandler(event); }}
              onLoad={(event) => { imageLoadHandler(event); }}
              name="mainImage"
            />
          )
        )}
        {mainImageLoadFailed && showErrorImage()}
        {!mainImageLoadFailed && (
          isValidCloudinaryUrl(fallbackImageSrc) && cloudinaryDPRToggle ? (
            <CloudinaryPicture
              src={fallbackImageSrc}
              alt={alt}
              title={title}
              className={classnames({ 'image-fade-out': shouldFadeOutImage() })}
              onError={(event) => { imageErrorHandler(event); }}
              onLoad={(event) => { imageLoadHandler(event); }}
              name="mainImage"
              data-top-image
              dataProductId={productId}
              dataColorName={selectedColorName}
            />
          ) : (
            <img
              src={fallbackImageSrc}
              alt={alt}
              title={title}
              className={classnames({ 'image-fade-out': shouldFadeOutImage() })}
              onError={(event) => { imageErrorHandler(event); }}
              onLoad={(event) => { imageLoadHandler(event); }}
              name="mainImage"
              data-top-image
              data-product-id={productId}
              data-color-name={selectedColorName}
            />
          )
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cloudinaryDPRToggle: get(state, 'toggles.CLOUDINARY_DPR', false),
  brand: get(state, 'brand_name.env', 'NM'),
});

export default connect(mapStateToProps)(TransitionCrossFadeImage);
