import {
  getCache,
  putCache,
} from 'client-utils/utilities-mcache';
import compact from 'lodash/compact';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import remove from 'lodash/remove';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import window from 'window-or-global';
import axios from 'axios';
import { normalizeJSON, normalizeProductJSON } from 'server/utilities/contentful';
import httpWithLogging from 'universal/http-client';
import { buildCookieString } from 'universal/utilities-cookies';
import Auth from '@aws-amplify/auth';
import {
  setPageVisited, setEditorialBreadcrumbs, setEditorialClass, CONTENT_TEST,
} from 'shared/actions/actions-page';
import { resolveEdtiorialBreadcrumbs } from 'client-utils/editorialUtagResolver';
import { promiseAllSettled } from 'client-utils/promiseAllSettled';
import { getCloudinaryImageSetIfEnabled } from 'client-utils/utilities-cloudinary';
import {
  Channels, CMSParams, MAIN_SHOT_TYPE, CURATED, PANEL,
} from '../constants';
import { removeDraftsFromLayout } from '../utilities';
import { markProductsIsFavorites } from 'cms/components/utils';
import { getRequestParams } from 'profile/components/MyFavorites/MyFavorites';


export const LOADING_CMS_ENTRIES = 'LOADING_CMS_ENTRIES';
export const RESOLVED_CMS_ENTRIES = 'RESOLVED_CMS_ENTRIES';
export const REJECTED_CMS_ENTRIES = 'REJECTED_CMS_ENTRIES';
export const RESOLVED_CMS_STORIES = 'RESOLVED_CMS_STORIES';
export const LOADING_EDITORIAL_STORIES = 'LOADING_EDITORIAL_STORIES';
export const RESOLVED_EDITORIAL_STORIES = 'RESOLVED_EDITORIAL_STORIES';
export const REJECTED_EDITORIAL_STORIES = 'REJECTED_EDITORIAL_STORIES';
export const LOADING_CMS_GLOBAL = 'LOADING_CMS_GLOBAL';
export const RESOLVED_CMS_GLOBAL = 'RESOLVED_CMS_GLOBAL';
export const REJECTED_CMS_GLOBAL = 'REJECTED_CMS_GLOBAL';
export const SET_STORY_CLASSIFICATION = 'SET_STORY_CLASSIFICATION';
export const OPEN_POPOUT = 'OPEN_POPOUT';
export const CLOSE_POPOUT = 'CLOSE_POPOUT';
export const EDITORIAL_NAVLINK_CLICK = 'EDITORIAL_NAVLINK_CLICK';
export const LOADING_MODAL_CONTENT = 'LOADING_MODAL_CONTENT';
export const RESOLVED_MODAL_CONTENT = 'RESOLVED_MODAL_CONTENT';
export const REJECTED_MODAL_CONTENT = 'REJECTED_MODAL_CONTENT';
export const SET_ACTIVE_ENTRY_ID = 'SET_ACTIVE_ENTRY_ID';
export const SET_RAIL_LINK_HOVERED = 'SET_RAIL_LINK_HOVERED';
export const SET_RAIL_LINK_NOT_HOVERED = 'SET_RAIL_LINK_NOT_HOVERED';
export const SET_RAIL_HOVERED = 'SET_RAIL_HOVERED';
export const SET_RAIL_NOT_HOVERED = 'SET_RAIL_NOT_HOVERED';
export const ERROR_PRODUCT_PANEL = 'ERROR_PRODUCT_PANEL';
export const LOADING_PRODUCT_PANEL = 'LOADING_PRODUCT_PANEL';
export const OPEN_PRODUCT_PANEL = 'OPEN_PRODUCT_PANEL';
export const LOAD_PRODUCTS_IN_PANEL = 'LOAD_PRODUCTS_IN_PANEL';
export const CLOSE_PRODUCT_PANEL = 'CLOSE_PRODUCT_PANEL';
export const SET_PANEL_TITLE = 'SET_PANEL_TITLE';
export const VIEW_SIMILAR = 'View Similar';
export const DRAWER_HANDLER = "DRAWER_HANDLER";
export const SET_PANEL_SELECTED_COLOR_CODE = 'SET_PANEL_SELECTED_COLOR_CODE';
export const FAVORITE_PRODUCT_PANEL = 'FAVORITE_PRODUCT_PANEL';

const BRAND = NMConfig.BRAND_NAME || 'NM';

export function setStoryClassification(classification) {
  return (dispatch) => dispatch({ type: SET_STORY_CLASSIFICATION, payload: classification });
}

export function getEditorialStories(query) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_EDITORIAL_STORIES });

    const state = getState();
    const { toggles } = state;
    const useCMSLambda = get(toggles, 'CMS_SERVICE', false);
    const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
    const usePreview = state.api.requestContext[CMSParams.CMS_PREVIEW]
      && (state.api.requestContext.isInternalIp
        || isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
    let cmsCallString;
    const headers = {};

    if (useCMSLambda) {
      if (addApiKeyToHeader) {
        headers['x-api-key'] = global.contentMgtV1_SSMKey;
        cmsCallString = `${NMConfig.API_CONTENT_MGT}/v1/content?cPath=${query}`;
      } else {
        cmsCallString = `${NMConfig.API_CONTENT_MGT}/content?cPath=${query}`;
      }
      cmsCallString = usePreview ? cmsCallString.concat('&preview=true') : cmsCallString;
    }

    const requestApi = httpWithLogging(state, 5000);
    return requestApi
      .get(cmsCallString, { headers })
      .then((response) => {
        const responseData = response.data;
        const normalPayload = useCMSLambda ? responseData : normalizeJSON(responseData);
        const pageContent = get(normalPayload, '[0]', {});
        const pageContentFields = get(pageContent, 'fields', {});
        const pageContentSlots = pageContentFields.contentSlots || [];
        const editorialStories = remove(pageContentSlots, { fields: { type: 'Editorial Story' } });
        if (!editorialStories) {
          dispatch({
            type: REJECTED_EDITORIAL_STORIES,
          });
        }
        if (!isEmpty(editorialStories)) {
          dispatch({
            type: RESOLVED_EDITORIAL_STORIES,
            payload: editorialStories,
          });
        }
      })
      .catch(() => dispatch({ type: REJECTED_EDITORIAL_STORIES }));
  };
}

export function openPopOut() {
  return (dispatch) => {
    return dispatch({ type: OPEN_POPOUT });
  };
}

export function closePopOut() {
  return (dispatch) => {
    return dispatch({ type: CLOSE_POPOUT });
  };
}

export function setDynamicRailHovered() {
  return { type: SET_RAIL_HOVERED };
}

export function setDynamicRailNotHovered() {
  return { type: SET_RAIL_NOT_HOVERED };
}

export function setDynamicRailLinkHovered() {
  return { type: SET_RAIL_LINK_HOVERED };
}

export function setDynamicRailLinkNotHovered() {
  return { type: SET_RAIL_LINK_NOT_HOVERED };
}

const getServiceUrl = (
  queryParams,
  isMobileVersionAvailable,
  usePreview,
  isNewContentModel,
  segmentationParams,
  usePreviewLambda,
  useApiKey,
  cmsSchedulePreview,
) => {
  const {
    query, channel, isInternational, contentId,
  } = queryParams;

  let apiPath = NMConfig.API_CONTENT_MGT;
  if (isNewContentModel) {
    apiPath = NMConfig.API_CONTENT_MGT_V2;
    if (get(segmentationParams, 'segToggle', false)) {
      apiPath = NMConfig.API_CONTENT_SEG;
    }
  }

  let url = `${apiPath}/content?cPath=${query}`;
  if (useApiKey && !get(segmentationParams, 'segToggle', false)) {
    if (isNewContentModel) {
      url = `${NMConfig.API_CONTENT_MGT_SVC}/v2/content?cPath=${query}`;
    } else {
      url = `${apiPath}/v1/content?cPath=${query}`;
    }
  }
  if (usePreview && usePreviewLambda && isNewContentModel) {
    url = `${NMConfig.API_CONTENT_PREVIEW}/contentPreview?cPath=${query}`;
    url = cmsSchedulePreview ? url.concat(`&schedule=${cmsSchedulePreview}`) : url;
  }

  url = isNewContentModel ? url.concat(`&brand=${BRAND}`) : url;
  url = isMobileVersionAvailable ? url.concat(`&channel=${channel}`) : url;
  url = isInternational ? url.concat('&isInternational=International') : url;
  if (usePreview && !usePreviewLambda) {
    url = url.concat('&preview=true');
  }
  url = get(segmentationParams, 'profileId') ? url.concat(`&profileId=${get(segmentationParams, 'profileId')}`) : url;
  url = !isEmpty(get(segmentationParams, 'cmsTags')) ? url.concat(`&tags=${encodeURI(get(segmentationParams, 'cmsTags'))}`) : url;

  return contentId ? `${NMConfig.API_ENTRY_CONTENT}?entryId=${contentId}` : url;
};

const getResponseData = (response, isCFModelV2) => {
  if (isCFModelV2 && isArray(response)) {
    const resolvedData = response.filter((data) => data.value?.data?.length);

    return resolvedData[0].value.data;
  }

  return response.data ? [response.data[0]] : [];
};

const getEntries = (originContentUrl, updatedContentUrl, requestApi, isCFModelV2, headers) => {
  return isCFModelV2
    ? promiseAllSettled(
      [requestApi(updatedContentUrl,
        headers.v2Lambdaheaders),
      requestApi(originContentUrl,
        headers.v1Lambdaheaders)]
    )
    : requestApi(originContentUrl, headers.v1Lambdaheaders);
};

const getPreviewEntries = (updatedContentUrl, requestApi, headers) => {
  return requestApi(updatedContentUrl, headers.previewLambdaHeader);
};

export function getCmsEntries(query, isQuizOrEditorialPage = false, isPersonalPromo) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_CMS_ENTRIES });
    const state = getState();
    const { device, toggles } = state;
    let timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
    const isMobileVersionAvailable = get(toggles, 'MOBILE_VERSION', false);
    const isCFModelV2 = get(toggles, 'CF_MODEL_V2', false);
    const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
    const isMobilePhone = get(device, 'isMobilePhone', false);
    const usePreviewLambda = get(toggles, 'PREVIEW_CONTENT_API', false);
    const cmsSchedulePreview = state.api.requestContext?.[CMSParams.cmsSchedule];
    const usePreview = state.api.requestContext?.[CMSParams.CMS_PREVIEW]
      && (state.api.requestContext.isInternalIp
        || isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
    const { MOBILE, DESKTOP } = Channels;
    const channel = isMobilePhone ? MOBILE : DESKTOP;
    const v1Lambdaheaders = {};
    const v2Lambdaheaders = {};
    const previewLambdaHeader = {};
    if (usePreview) {
      v1Lambdaheaders['Cache-Control'] = 'max-age=0';
      v2Lambdaheaders['Cache-Control'] = 'max-age=0';
      if (usePreviewLambda) {
        timeout = get(state, 'apiTimeouts.PREVIEW_CONTENT_API_TIMEOUT', 5000);
        previewLambdaHeader['x-api-key'] = global.contentPreview_SSMKey;
      }
    }
    if (addApiKeyToHeader) {
      v1Lambdaheaders['x-api-key'] = global.contentMgtV1_SSMKey;
      v2Lambdaheaders['x-api-key'] = global.contentManagement_SSMKey;
    }
    const queryParams = { channel, query };
    const requestApi = (url, headers) => httpWithLogging(state, timeout).get(url, { headers });
    const originContentUrl = getServiceUrl(
      queryParams,
      false,
      usePreview,
      false,
      {},
      false,
      addApiKeyToHeader,
    );
    const updatedContentUrl = getServiceUrl(
      queryParams,
      isMobileVersionAvailable,
      usePreview,
      true,
      {},
      usePreviewLambda,
      addApiKeyToHeader,
      cmsSchedulePreview,
    );
    const content = usePreview && usePreviewLambda
      ? getPreviewEntries(
        updatedContentUrl,
        requestApi,
        { previewLambdaHeader }
      ) : getEntries(
        originContentUrl,
        updatedContentUrl,
        requestApi,
        isCFModelV2,
        { v1Lambdaheaders, v2Lambdaheaders }
      );
    return content
      .then((response) => {
        const normalPayload = getResponseData(response, isCFModelV2);
        const pageContent = get(normalPayload, '[0]', {});
        const pageContentFields = get(pageContent, 'fields', {});
        const pageContentSlots = pageContentFields.contentSlots || [];
        const editorialStories = remove(pageContentSlots, { fields: { type: 'Editorial Story' } });
        const pageFrames = get(pageContentFields, 'l1Layouts[0].fields.frames', []);

        dispatch({
          type: RESOLVED_CMS_ENTRIES,
          payload: isPersonalPromo ? normalPayload : [pageContent],
        });

        if (!isEmpty(editorialStories) || !isEmpty(pageFrames)) {
          const payload = editorialStories.length ? editorialStories : pageFrames;

          dispatch({
            type: RESOLVED_CMS_STORIES,
            payload,
          });
        }

        return ({ normalPayload, query });
      })
      .then((response) => {
        const queryString = response.query.indexOf('/') === 0 ? response.query.substr(1, response.query.length - 1) : response.query;
        const arrPath = queryString.split('/');
        if (isQuizOrEditorialPage) {
          const pathToAnalyticsPageName = (path) => path.split('-').map(capitalize).join(' ');
          if (query.startsWith('/quiz/')) {
            const queryWithoutTrailingSlash = query.replace(/\/+$/, '');
            const quizNameInPath = queryWithoutTrailingSlash.substr(queryWithoutTrailingSlash.lastIndexOf('/') + 1);
            const quizName = pathToAnalyticsPageName(quizNameInPath);
            dispatch(setPageVisited({
              event_name: 'pageLoad',
              page_name: `Quiz:${quizName}`,
              page_definition_id: 'quiz',
              page_type: 'editorial',
              page_template: 'quiz',
            }));
          } else {
            const pageDefinitionId = arrPath.length === 1 ? 'silo' : 'story';
            const pageName = ['Magazine', ...arrPath.slice(1)].map(pathToAnalyticsPageName).join(':');
            const pageType = arrPath[0];
            const isEditorial = query.startsWith('/editorial');
            dispatch(setPageVisited({
              event_name: 'pageLoad',
              page_name: pageName,
              page_definition_id: isEditorial ? pageDefinitionId : pageType,
              page_type: pageType,
              page_template: isEditorial ? pageDefinitionId : pageType,
            }));
          }

          const editorialclass = get(response.normalPayload, '[0].fields.metaData.fields.classifications', []);
          dispatch(setEditorialClass({ editorialclass }));
        }

        return (arrPath);
      })
      .then((response) => {
        const breadcrumbs = resolveEdtiorialBreadcrumbs(response);
        if (isQuizOrEditorialPage) {
          dispatch(setEditorialBreadcrumbs({ breadcrumbs }));
        }
      })
      .catch(() => dispatch({ type: REJECTED_CMS_ENTRIES }));
  };
}

export function getCmsHomeEntries(query) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_CMS_ENTRIES });
    const state = getState();
    const {
      device,
      toggles,
      user,
      session,
    } = state;
    let timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
    const isMobileVersionAvailable = get(toggles, 'MOBILE_VERSION', false);
    const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
    const isMobilePhone = get(device, 'isMobilePhone', false);
    const usePreviewLambda = get(toggles, 'PREVIEW_CONTENT_API', false);
    const cmsSchedulePreview = state.api.requestContext?.[CMSParams.cmsSchedule];
    const hpSegmentation = get(toggles, 'CMS_HP_SEGMENT', false);
    const usePreview = state.api.requestContext[CMSParams.CMS_PREVIEW]
      && (state.api.requestContext.isInternalIp
        || isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
    const { MOBILE, DESKTOP } = Channels;
    const channel = isMobilePhone ? `${MOBILE},${DESKTOP}` : DESKTOP;
    const localeValue = get(state, 'locale.countryCode', 'US');
    const isInternational = localeValue !== 'US';
    let headers = usePreview ? { 'Cache-Control': 'max-age=0' } : {};

    if (hpSegmentation && global.contentSegmentation_SSMKey) {
      headers['x-api-key'] = global.contentSegmentation_SSMKey;
      if (addApiKeyToHeader) {
        headers['use-api-key'] = 'true';
      }
    } else if (addApiKeyToHeader) {
      headers['x-api-key'] = global.contentManagement_SSMKey;
    }
    if (usePreviewLambda && usePreview) {
      headers = { 'x-api-key': global.contentPreview_SSMKey };
      timeout = get(state, 'apiTimeouts.PREVIEW_CONTENT_API_TIMEOUT', 5000);
      if (hpSegmentation) {
        headers['use-preview-lambda'] = 'true';
      }
    }
    const queryParams = { channel, query, isInternational };
    const requestApi = (url) => httpWithLogging(state, timeout).get(url, { headers });

    const profileId = user && user.securityStatus
      && (user.securityStatus.toLowerCase() === 'authenticated' || user.securityStatus.toLowerCase() === 'anonymous') && user.name
      ? session.WID
      : null;

    let cmsTags = get(state, 'api.requestContext.CMS_Tags');
    if (get(toggles, 'ABTEST_CONTENT', false)) {
      let abTestVariation = get(state, `abTestsOpt.${CONTENT_TEST}.variation`, null);
      const abTestContentConfig = get(state, 'abTestContent', []);

      if (abTestVariation && abTestContentConfig.length > 1) {
        const abTestName = abTestContentConfig
          .find((testObj) => testObj[CONTENT_TEST])?.[CONTENT_TEST];
        abTestVariation = abTestContentConfig
          .find((testObj) => testObj[abTestVariation])?.[abTestVariation];

        if (abTestVariation && abTestName) {
          cmsTags = isEmpty(cmsTags) ? `${abTestName}:${abTestVariation}` : cmsTags.concat(`,${abTestName}:${abTestVariation}`);
        }
      }
    }

    const segmentationParams = hpSegmentation
      ? {
        segToggle: true,
        cmsTags,
        profileId,
      } : null;

    const updatedContentUrl = getServiceUrl(
      queryParams,
      isMobileVersionAvailable,
      usePreview,
      true,
      segmentationParams,
      usePreviewLambda,
      addApiKeyToHeader,
      cmsSchedulePreview,
    );
    const content = requestApi(updatedContentUrl);

    return content
      .then((response) => {
        const normalPayload = response.data ? [response.data[0]] : [];
        const pageContent = get(normalPayload, '[0]', {});

        dispatch({
          type: RESOLVED_CMS_ENTRIES,
          payload: isMobileVersionAvailable ? [pageContent] : normalPayload,
        });
        return ({ normalPayload, query });
      })
      .catch(() => dispatch({ type: REJECTED_CMS_ENTRIES }));
  };
}

export function getEditorialStoriesV2(query) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_EDITORIAL_STORIES });
    const state = getState();
    const { device, toggles } = state;
    let timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
    const isMobileVersionAvailable = get(toggles, 'MOBILE_VERSION', false);
    const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
    const isMobilePhone = get(device, 'isMobilePhone', false);
    const usePreviewLambda = get(toggles, 'PREVIEW_CONTENT_API', false);
    const cmsSchedulePreview = state.api.requestContext?.[CMSParams.cmsSchedule];
    const usePreview = state.api.requestContext[CMSParams.CMS_PREVIEW]
      && (state.api.requestContext.isInternalIp
        || isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
    const { MOBILE, DESKTOP } = Channels;
    const channel = isMobilePhone ? `${MOBILE},${DESKTOP}` : DESKTOP;
    const localeValue = get(state, 'locale.countryCode', 'US');
    const isInternational = localeValue !== 'US';
    let headers = usePreview ? { 'Cache-Control': 'max-age=0' } : {};
    if (addApiKeyToHeader) {
      headers['x-api-key'] = global.contentManagement_SSMKey;
    }
    if (usePreviewLambda && usePreview) {
      headers = { 'x-api-key': global.contentPreview_SSMKey };
      timeout = get(state, 'apiTimeouts.PREVIEW_CONTENT_API_TIMEOUT', 5000);
    }
    const queryParams = { channel, query, isInternational };
    const requestApi = (url) => httpWithLogging(state, timeout).get(url, { headers });
    const updatedContentUrl = getServiceUrl(
      queryParams,
      isMobileVersionAvailable,
      usePreview,
      true,
      {},
      usePreviewLambda,
      addApiKeyToHeader,
      cmsSchedulePreview,
    );
    const content = requestApi(updatedContentUrl);
    return content
      .then((response) => {
        const normalPayload = response?.data?.[0] || {};
        const payload = get(normalPayload, 'fields.l1Layouts[0].fields.frames', []);

        dispatch({
          type: RESOLVED_EDITORIAL_STORIES,
          payload,
        });
      })
      .catch(() => dispatch({ type: REJECTED_EDITORIAL_STORIES }));
  };
}

export function getCmsGlobal(query) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_CMS_GLOBAL });
    const state = getState();
    const { device, toggles } = state;
    let timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
    const isMobileVersionAvailable = get(toggles, 'MOBILE_VERSION', false);
    const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
    const isMobilePhone = get(device, 'isMobilePhone', false);
    const usePreviewLambda = get(toggles, 'PREVIEW_CONTENT_API', false);
    const cmsSchedulePreview = state.api.requestContext?.[CMSParams.cmsSchedule];
    const usePreview = state.api.requestContext[CMSParams.CMS_PREVIEW]
      && (state.api.requestContext.isInternalIp
        || isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
    const { MOBILE, DESKTOP } = Channels;
    const channel = isMobilePhone ? `${MOBILE},${DESKTOP}` : DESKTOP;
    const localeValue = get(state, 'locale.countryCode', 'US');
    const isInternational = localeValue !== 'US';
    let headers = usePreview ? { 'Cache-Control': 'max-age=0' } : {};
    if (addApiKeyToHeader) {
      headers['x-api-key'] = global.contentManagement_SSMKey;
    }
    if (usePreviewLambda && usePreview) {
      headers = { 'x-api-key': global.contentPreview_SSMKey };
      timeout = get(state, 'apiTimeouts.PREVIEW_CONTENT_API_TIMEOUT', 5000);
    }
    const queryParams = { channel, query, isInternational };
    const requestApi = (url) => httpWithLogging(state, timeout).get(url, { headers });
    const updatedContentUrl = getServiceUrl(
      queryParams,
      isMobileVersionAvailable,
      usePreview,
      true,
      {},
      usePreviewLambda,
      addApiKeyToHeader,
      cmsSchedulePreview,
    );
    const content = requestApi(updatedContentUrl);
    return content
      .then((response) => {
        const normalPayload = response.data ? removeDraftsFromLayout([response.data[0]]) : [];
        const pageContent = get(normalPayload, '[0]', {});
        dispatch({
          type: RESOLVED_CMS_GLOBAL,
          payload: isMobileVersionAvailable ? [pageContent] : normalPayload,
        });
        return ({ normalPayload, query });
      })
      .catch(() => dispatch({ type: REJECTED_CMS_GLOBAL }));
  };
}

export function getProductCarouselFromId(contentId) {
  return (dispatch, getState) => {
    dispatch({ type: `LOADING_CMS_PRODUCT_CAROUSEL_${contentId}` });

    const state = getState();
    const { toggles } = state;
    const timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
    const useCMSLambda = get(toggles, 'CMS_SERVICE', false);
    const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
    const useCache = get(toggles, 'CF_CACHE_PRDS', false);
    const usePreview = state.api.requestContext[CMSParams.CMS_PREVIEW]
      && (state.api.requestContext.isInternalIp
        || isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
    const headers = {};
    let cmsCallString;

    if (useCMSLambda) {
      if (addApiKeyToHeader) {
        headers['x-api-key'] = global.contentMgtV1_SSMKey;
        cmsCallString = `${NMConfig.API_CONTENT_MGT}/v1/productCarousel?contentId=${contentId}`;
      } else {
        cmsCallString = `${NMConfig.API_CONTENT_MGT}/productCarousel?contentId=${contentId}`;
      }
      cmsCallString = usePreview ? cmsCallString.concat('&preview=true') : cmsCallString;
    }
    const requestApi = httpWithLogging(state, timeout);
    return requestApi
      .get(cmsCallString, { headers })
      .then((response) => {
        const responseData = response.data;
        if (responseData.sys.contentType.sys.id === 'productCarouselContentAsset') {
          dispatch({
            type: `RESOLVED_CMS_PRODUCT_CAROUSEL_${contentId}`,
            payload: responseData,
            contentId,
          });
          dispatch(
            getCmsProducts(responseData?.fields?.ids, responseData?.sys?.id, useCache)
          );
        }
      })
      .catch(() => dispatch({ type: `REJECTED_CMS_PRODUCT_CAROUSEL_${contentId}` }));
  };
}

export function getCmsProducts(productIds, contentId, useCache = false) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_CMS_PRODUCTS_${contentId}`,
      contentId,
    });
    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 6000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const joinProductIds = (productIds) => {
      return productIds.join(',');
    };

    let prodCallString = `?productIds=${joinProductIds(productIds)}`;
    if (useCache) {
      prodCallString = `${NMConfig.API_PRODUCT_CF}${prodCallString}`;
    } else {
      prodCallString = `${NMConfig.API_PRODUCT}${prodCallString}`;
    }

    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      if (currencyCode !== 'USD') {
        const currencyQuery = `&currency=${currencyCode}`;
        prodCallString = `${prodCallString}${currencyQuery}`;
      }
    }
    if (prodCallString.indexOf('?') > -1) {
      const mainShotType = `&mainShotType=${MAIN_SHOT_TYPE}`;
      const calledBy = `&calledBy=${CURATED}`;
      prodCallString = `${prodCallString}${mainShotType}${calledBy}`;
    }
    return requestApi.get(prodCallString, { headers })
      .then((res) => {
        /* eslint-disable-next-line max-len */
        const sortedProducts = productIds.map((id) => res.data.products.find(((product) => product.id === id)));
        const rawChildIds = sortedProducts.map((product) => {
          if (product.isGroup === true) {
            return product.childProductIds[0];
          }
          return null;
        });
        const childProductIds = rawChildIds ? compact(rawChildIds) : [];
        if (!isEmpty(childProductIds)) {
          dispatch(getChildProducts(childProductIds, contentId, useCache));
        }
        dispatch({
          type: `RESOLVED_CMS_PRODUCTS_${contentId}`,
          payload: normalizeProductJSON(sortedProducts),
          contentId,
        });
      })
      .catch(() => {
        dispatch({
          type: `REJECTED_CMS_PRODUCTS_${contentId}`,
          contentId,
        });
      });
  };
}

export function getChildProducts(childProductIds, contentId, useCache = false) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_CHILD_PRODUCTS_${contentId}`,
      contentId,
    });
    if (!childProductIds || !childProductIds.length) {
      dispatch({
        type: `REJECTED_CHILD_PRODUCTS_${contentId}`,
        contentId,
      });
    }
    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 6000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const joinProductIds = (childProductIds) => {
      return childProductIds.join(',');
    };
    let prodCallString = `?productIds=${joinProductIds(childProductIds)}`;
    if (useCache) {
      prodCallString = `${NMConfig.API_PRODUCT_CF}${prodCallString}`;
    } else {
      prodCallString = `${NMConfig.API_PRODUCT}${prodCallString}`;
    }
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      if (currencyCode !== 'USD') {
        const currencyQuery = `&currency=${currencyCode}`;
        prodCallString = `${prodCallString}${currencyQuery}`;
      }
    }
    if (prodCallString.indexOf('?') > -1) {
      const mainShotType = `&mainShotType=${MAIN_SHOT_TYPE}`;
      const calledBy = `&calledBy=${CURATED}`;
      prodCallString = `${prodCallString}${mainShotType}${calledBy}`;
    }
    return requestApi.get(prodCallString, { headers })
      .then((res) => {
        dispatch({
          type: `RESOLVED_CHILD_PRODUCTS_${contentId}`,
          payload: normalizeProductJSON(res.data.products),
          contentId,
        });
      })
      .catch(() => {
        dispatch({
          type: `REJECTED_CHILD_PRODUCTS_${contentId}`,
          contentId,
        });
      });
  };
}

function trimSpaces(str) {
  return str.trim().replace(/\s\s+/g, ' ');
}

function stripHtml(html) {
  return html.replace(/<[^>]+>/g, '');
}

function getTitle(product) {
  const isGroup = product.isGroup;
  const cmosItemNum = get(product, 'metadata.cmosItem', null);
  const designerName = unescape(get(product, 'designer.name', ' '));
  let productName = stripHtml(unescape(product.name));
  if (isGroup) {
    const childProducts = get(product, 'childProducts', []);
    productName = stripHtml(unescape(childProducts.map((product) => { return product.name; }).join(' ')));
  }
  return trimSpaces(`${cmosItemNum} ${designerName} ${productName}`);
}

export function getCategoryProducts(catID = '', noOfProducts = 16) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_CMS_CURRATED_PRODUCTS_${catID}`,
      catID,
    });
    const state = getState();
    const { user, session, toggles } = state;
    const requestApi = httpWithLogging(state, 6000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const apiConfig = NMConfig.API_CURATED_PRODUCT_LIST;
    let prodCallString = `${apiConfig}?categoryId=${catID}&fetchSize=${noOfProducts}`;

    let cacheKey;
    let cacheResponse;

    if (prodCallString) {
      cacheKey = prodCallString;
      const cacheData = getCache(cacheKey, state);
      cacheResponse = cacheData ? { data: cacheData } : null;
    }
    const curatedProductResponse = (catID, curratedProductList) => {
      dispatch({
        type: `RESOLVED_CMS_CURRATED_PRODUCTS_${catID}`,
        payload: curratedProductList,
        catID,
      });
    };

    if (cacheResponse) {
      return new Promise((resolve) => {
        curatedProductResponse(catID, cacheResponse.data);
        return resolve();
      });
    }
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      if (currencyCode !== 'USD') {
        const currencyQuery = `&currency=${currencyCode}`;
        prodCallString = `${prodCallString}${currencyQuery}`;
      }
    }
    if (prodCallString.indexOf('?') > -1) {
      const mainShotType = `&mainShotType=${MAIN_SHOT_TYPE}`;
      prodCallString = `${prodCallString}${mainShotType}`;
    }
    return requestApi.get(prodCallString, { headers })
      .then((res) => {
        if (!res?.data?.products?.length) {
          dispatch({
            type: `REJECTED_CMS_CURRATED_PRODUCTS_${catID}`,
            catID,
          });
        }

        const curratedProductList = [];
        for (const product of res.data.products) {
          const imageSet = getCloudinaryImageSetIfEnabled(product?.media?.main);
          const imageUrl = imageSet?.medium?.url
            || '/assets/images/no-image.c9a49578722aabed021ab4821bf0e705.jpeg';

          const designerName = get(product, 'designer.name', ' ');
          const name = product.name || '';
          const isGroup = product.isGroup;
          const childProducts = isGroup ? get(product, 'childProducts', []) : [];
          const displayable = product.displayable;

          curratedProductList.push({
            id: product.id,
            designerName,
            name,
            url: get(product, 'details.canonicalUrl', ''),
            price: product?.price,
            imageUrl,
            title: getTitle(product),
            promotions: product.promotions,
            isGroup,
            childProducts,
            displayable,
          });
        }
        if (cacheKey) {
          const mcacheTimeout = get(state, 'mcacheTimeouts.cproducts', 900000);
          putCache(cacheKey, curratedProductList, mcacheTimeout, state);
        }
        curatedProductResponse(catID, curratedProductList);
      })
      .catch(() => {
        dispatch({
          type: `REJECTED_CMS_CURRATED_PRODUCTS_${catID}`,
          catID,
        });
      });
  };
}

export function editorialNavlinkClick(editorialClass) {
  return (dispatch) => {
    dispatch({
      type: EDITORIAL_NAVLINK_CLICK,
      payload: editorialClass,
    });
  };
}

export function getModalContent(contentId) {
  return async (dispatch, getState) => {
    dispatch({ type: `LOADING_MODAL_CONTENT_${contentId}` });

    try {
      const state = getState();
      const timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
      const isMobileVersionAvailable = get(
        state,
        'toggles.MOBILE_VERSION',
        false,
      );
      const queryParams = { contentId };
      const requestApi = (url) => httpWithLogging(state, timeout).get(url);
      const updatedContentUrl = getServiceUrl(
        queryParams,
        isMobileVersionAvailable,
        false,
        true,
      );

      const response = await requestApi(updatedContentUrl);
      const getData = isArray(response.data) ? response.data[0] : response.data;
      const modalContent = getData || {};

      if (modalContent.sys.contentType.sys.id !== 'productCarouselContentAsset') {
        dispatch({
          type: `RESOLVED_MODAL_CONTENT_${contentId}`,
          payload: modalContent,
          contentId,
        });
      }

      return modalContent;
    } catch (err) {
      dispatch({ type: `REJECTED_MODAL_CONTENT_${contentId}` });

      return err;
    }
  };
}

export function setActiveEntryId(entryId) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_ENTRY_ID,
      payload: entryId,
    });
  };
}

const getFavorites = async (isGuest, state) => {
  try {
    const { session } = state;
    const { fastlyHost, id, headers } = await getRequestParams(isGuest, session);
    const url = `${fastlyHost}/uca-favorites/v1/${(isGuest ? 'guests' : 'accounts')}/${id}/products`;
    const { data: { products } } = await axios.get(url, { headers });
    return products;
  } catch (e) {
    return [];
  }
};

const getFavoriteList = (state) => {
  const isGuest = true;
  return Auth.currentAuthenticatedUser()
    .then(() => getFavorites(!isGuest, state))
    .catch(() => getFavorites(isGuest, state));
};

export function openProductPanel(ids, selectedColorCode) {
  ids = Array.isArray(ids) ? ids : [ids];
  return async (dispatch, getState) => {
    try {
      dispatch({ type: OPEN_PRODUCT_PANEL, payload: false });
      const state = getState();
      const { user, session } = state;
      const timeout = 5000;
      const headers = {
        Cookie: buildCookieString({
          JSESSIONID: get(session, 'JSESSIONID', ''),
          ucid: get(user, 'ucid', ''),
          rid: get(user, 'rid', 'US'),
          DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
          W2A: get(session, 'W2A', ''),
        }),
      };
      const apiString = `${NMConfig.API_PRODUCT}?productIds=${ids.join()}&calledBy=SRP`;
      const requestProducts = httpWithLogging(state, timeout).get(apiString, { headers });

      // eslint-disable-next-line max-len
      const [productsResponse, favProductsResponse] = await Promise.all([requestProducts, getFavoriteList(state)]);

      let { products } = productsResponse.data;
      products = markProductsIsFavorites(products, favProductsResponse);

      dispatch({ type: LOAD_PRODUCTS_IN_PANEL, payload: products});

      if(selectedColorCode) {
        dispatch({ type: SET_PANEL_SELECTED_COLOR_CODE, payload: selectedColorCode });
      }

      return true;
    } catch (err) {
      dispatch({ type: ERROR_PRODUCT_PANEL, payload: 'Error: a problem occurred while loading products' });
      return err;
    }
  };
}

export function openQLProductPanel(productId, selectedColorCode) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: OPEN_PRODUCT_PANEL, payload: true });
      const state = getState();
      const { user, session } = state;
      const timeout = 5000;
      const headers = {
        Cookie: buildCookieString({
          JSESSIONID: get(session, 'JSESSIONID', ''),
          ucid: get(user, 'ucid', ''),
          rid: get(user, 'rid', 'US'),
          DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
          TLTSID: get(session, 'TLTSID', ''),
          W2A: get(session, 'W2A', ''),
        }),
      };
      const { promos } = JSON.parse(get(session, 'dt_personalize_data', '{}'));
      const pageId = get(state, 'page.pageId', '');

      let requestURI = promos && promos.length
        ? `${NMConfig.API_PRODUCT_DETAIL_PDP}/${productId}?personalizedPromos=${promos}`
        : `${NMConfig.API_PRODUCT_DETAIL_PDP}/${productId}`;

      const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
      if (internationalToggle) {
        const currencyCode = get(state, 'locale.currencyCode', 'USD');
        const countryCode = get(state, 'locale.countryCode', 'US');
        if (currencyCode !== 'USD') {
          let currencyQuery = `?currency=${currencyCode}&country=${countryCode}`;
          if (requestURI.indexOf('?') > -1 || requestURI.indexOf('&') > -1) {
            currencyQuery = `&currency=${currencyCode}&country=${countryCode}`;
          }
          requestURI = `${requestURI}${currencyQuery}`;
        }
      }
      const requestProducts = httpWithLogging(state, timeout).get(requestURI, { headers });

      await Promise.all([requestProducts, getFavoriteList(state)]).then(([productsResponse, favProductsResponse]) => {
        if (favProductsResponse?.length > 0) {
          favProductsResponse?.map((item) => {
            if ((item?.id === productsResponse?.data?.id) && !isUndefined(productsResponse.data.isFavorite)) {
              productsResponse.data.isFavorite = true;
            }
          });
        }
        let { childProducts, isGroup, skus } = productsResponse.data;
        window.utag_data_dt = {
          ...window.utag_data_dt,
          event_name: 'Quicklook',
          page_name: 'product detail',
          df_nmo_sku_id: skus?.[0]?.id,
          page_definition_id: pageId === 'PAGE_ID_PLP' ? 'P3' : 'product',
          page_template: pageId === 'PAGE_ID_PLP' ? 'P3' : 'product detail',
          page_type: pageId === 'PAGE_ID_PLP' ? 'subcategory' : 'product detail',
          outfitting_product: '',
        };
        if (isGroup) {
          dispatch({ type: LOAD_PRODUCTS_IN_PANEL, payload: childProducts});
        } else {
          dispatch({ type: LOAD_PRODUCTS_IN_PANEL, payload: [productsResponse.data]});
        }

        if(selectedColorCode) {
          dispatch({ type: SET_PANEL_SELECTED_COLOR_CODE, payload: selectedColorCode });
        }
        return true;
      }).catch((err) => {
        window.utag_data_dt = {
          ...window.utag_data_dt,
          event_name: 'Quicklook',
          page_name: 'product detail',
          page_definition_id: pageId === 'PAGE_ID_PLP' ? 'P3' : 'product',
          page_template: pageId === 'PAGE_ID_PLP' ? 'P3' : 'product detail',
          page_type: pageId === 'PAGE_ID_PLP' ? 'subcategory' : 'product detail',
          outfitting_product: '',
        };
        dispatch({ type: LOAD_PRODUCTS_IN_PANEL, payload: []});
        dispatch({ type: ERROR_PRODUCT_PANEL, payload: 'Error: a problem occurred while loading products' });
        return err;
      });
      return true;
    }catch (err) {
      dispatch({ type: ERROR_PRODUCT_PANEL, payload: 'Error: a problem occurred while loading products' });
      return err;
    }
  };
}

export function openViewSimilarProductPanel (productData, selectedColorCode) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: OPEN_PRODUCT_PANEL });
      const state = getState();
      const { user, session } = state;
      const timeout = 5000;
      const headers = {
        Cookie: buildCookieString({
          JSESSIONID: get(session, 'JSESSIONID', ''),
          ucid: get(user, 'ucid', ''),
          rid: get(user, 'rid', 'US'),
          DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
          TLTSID: get(session, 'TLTSID', ''),
          W2A: get(session, 'W2A', ''),
        }),
      };

      const requestApi = httpWithLogging(state, timeout);

      const { productId, selectedColorName } = productData;
      const variantIds = `${productId}${selectedColorName ? `-${selectedColorName}` : ''}`;
      const stylyzeApi = `${NMConfig.API_STYLYZE}?variantIds=${variantIds}`;
      
      const response = await requestApi.get(stylyzeApi, { headers });

      const viewSimilarData = response?.data?.visuallySimilarLsbs?.[0];

      const variants = viewSimilarData?.variants;
      const productIds = variants?.map((product) => {
        const productId = product?.variantId?.split('-');
        return productId?.[0];
      });

      const productResponse = await Promise.all([
        requestApi.get(`${NMConfig.API_PRODUCT_PANEL}?productIds=${productIds.join()}&calledBy=${PANEL}`, { headers }),
        getFavoriteList(state),
      ]);

      const viewSimilarPanelProducts = productResponse?.length > 1 ? productResponse[0] : [];
      const favoriteProducts = productResponse?.length === 2 ? productResponse[1] : [];

      if (favoriteProducts?.length > 0) {
        viewSimilarPanelProducts?.data?.products?.map((product) => {
          const fProducts = favoriteProducts?.filter((fProduct) => fProduct?.id === product?.id);
          product.isFavorite = fProducts?.length > 0 || false;
        });
      } else {
        viewSimilarPanelProducts?.data?.products?.map((product) => {
          product.isFavorite = false;
        });
      }

      const sortProductsOrder = (array, order, key) => {
        if (!array || array?.length === 0) return [];
        return array.sort((a, b) => {
          if (order.indexOf(a[key]) > order.indexOf(b[key])) {
            return 1;
          }
          return -1;
        });
      };

      const uniqueProductIds = [...new Set(productIds)]; // eslint-disable-line compat/compat
      const mappedProducts = sortProductsOrder(viewSimilarPanelProducts?.data?.products, uniqueProductIds, 'id');
      window.utag_data_dt = {
        ...window.utag_data_dt,
        event_name: 'pageLoad',
        page_name: "product detail",
        outfitting_product: 'vs cta click',
        page_definition_id: 'P3',
        page_template: 'P3',
        page_type: 'subcategory'
      };
      dispatch({ type: LOAD_PRODUCTS_IN_PANEL, payload: mappedProducts });

      if(selectedColorCode) {
        dispatch({ type: SET_PANEL_SELECTED_COLOR_CODE, payload: selectedColorCode });
      }

      return true;
    } catch (err) {
      window.utag_data_dt = {
        ...window.utag_data_dt,
        event_name: 'pageLoad',
        page_name: "product detail",
        outfitting_product: 'vs cta click',
        page_definition_id: 'P3',
        page_template: 'P3',
        page_type: 'subcategory'
      };
      dispatch({ type: LOAD_PRODUCTS_IN_PANEL, payload: [] });
      return err;
    }
  };
}

export function closeProductPanel() {
  return (dispatch) => dispatch({ type: CLOSE_PRODUCT_PANEL });
}

export function setPanelTitle(title) {
  return (dispatch) => dispatch({ type: SET_PANEL_TITLE, payload:  title});
}

export function drawerControl(isOpen){
  return (dispatch) => dispatch({ type: DRAWER_HANDLER, payload: isOpen });
}

export function isGlobalNavUpdateToggleEnabled(state){
  const isDomestic = get(state, 'locale.countryCode') === 'US';
  const globalNavUpdateToggle = get(state, 'toggles.GLOBAL_NAV_UPDATE', false);
  return isDomestic && Boolean(globalNavUpdateToggle);
}

export function toggleProductPanelFavorite(product, isFavorite) {
  const { cmosCatalogId, cmosItem } = product;
  const favoriteData = {
    favoriteStatus: isFavorite ? 'favorite' : 'unfavorite',
    cmosCatalogId,
    cmosItem,
  };

  return (dispatch) => dispatch({ type: FAVORITE_PRODUCT_PANEL, payload: favoriteData });
}