import { SET_PANEL_SELECTED_COLOR_CODE } from 'client/cms/actions/index';
import { CLOSE_PRODUCT_PANEL, OPEN_PRODUCT_PANEL, LOAD_PRODUCTS_IN_PANEL, SET_PANEL_TITLE } from 'cms/actions';

const initialState = { open: false, products: [], loading: false, title: "", shouldUseOriginalCanonicalURL: false };

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case OPEN_PRODUCT_PANEL:
      return {
        ...state,
        open: true,
        loading: true,
        shouldUseOriginalCanonicalURL: action.payload,
      };
    case CLOSE_PRODUCT_PANEL:
      return {
        ...state,
        open: false,
        loading: false,
        shouldUseOriginalCanonicalURL: false,
      };
    case LOAD_PRODUCTS_IN_PANEL:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case SET_PANEL_TITLE: 
      return {
        ...state,
        title: action.payload
      }
    case SET_PANEL_SELECTED_COLOR_CODE: 
      return {
        ...state,
        products: state.products.map((product) => {
          product.options['selectedColorCode'] = action.payload || '';
          return product;
        })
      }
    default:
      return state;
  }
};
