import React, { Component } from 'react';
import { RecentSizesStorageHandler } from 'shared/Storage/RecentSizesStorageHandler';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import take from 'lodash/fp/take';
import thru from 'lodash/fp/thru';
import { addRecentSizesToUtagData } from 'plp/components/ProductListPage/actions';
import classnames from 'classnames';
import { connect } from 'react-redux';
import GeneralFilter from '../GeneralFilter/generalFilter';
import './sizeFilter.scss';

export class SizeFilter extends Component {
  constructor(props) {
    super(props);
    if (props.storageHandler) {
      this.storageHandler = props.storageHandler;
    } else {
      this.storageHandler = new RecentSizesStorageHandler();
    }

    this.state = {
      recentSizes: undefined,
    };
  }

  loadRecentSizes() {
    const recentSizesArray = this.storageHandler.getRecentSizesForFilter();
    let recentSizes;
    if (isEmpty(recentSizesArray)) {
      recentSizes = {};
    } else {
      recentSizes = flow(
        filter((opt) => !isNil(this.props.facets[opt])),
        take(4),
        thru((top4Sizes) => pick(this.props.facets, top4Sizes))
      )(recentSizesArray);
    }
    const newRecentSizes = Object.keys(recentSizes);
    if (this.stateChanged(newRecentSizes)) {
      this.props.addRecentSizesToUtagData(newRecentSizes);
      this.setState({ recentSizes: newRecentSizes });
    }
    return recentSizes;
  }

  stateChanged(newRecentSizes) {
    if (this.state.recentSizes === undefined) {
      return true;
    }
    return (JSON.stringify(this.state.recentSizes.sort())
      !== JSON.stringify(newRecentSizes.sort()));
  }

  render() {
    const {
      facets,
      filterKey,
      onFilterValueClick,
      selectedValues,
      facetsOrderList,
    } = this.props;
    const recentSizes = this.loadRecentSizes();
    const hasRecentSizes = !isEmpty(recentSizes);
    return (
      <div>
        { hasRecentSizes
        && (
        <div>
          <p className={classnames({
            'recent-sizes-label-sf': true,
          })}
          >
          Recent Sizes
          </p>
          <GeneralFilter
            facets={recentSizes}
            filterKey={filterKey}
            onFilterValueClick={onFilterValueClick}
            selectedValues={selectedValues}
            facetsOrderList={facetsOrderList}
            isRecentSizes
          />
          <div className={classnames({
            'recent-sizes-separator-sf': true,
          })}
          />
        </div>
        )
        }
        <GeneralFilter
          facets={facets}
          filterKey={filterKey}
          onFilterValueClick={onFilterValueClick}
          selectedValues={selectedValues}
          facetsOrderList={facetsOrderList}
        />
      </div>
    );
  }
}

const mapDispatchToProps = { addRecentSizesToUtagData };
export default connect(undefined, mapDispatchToProps)(SizeFilter);
