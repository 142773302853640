import React from 'react';
import classNames from 'classnames';

const Notifications = ({ num, overrideClass }) => {
  return (
    <div className={classNames('panel__notification--container', overrideClass)}>
      <span className="panel__notification--num">{num}</span>
    </div>
  );
};

export default Notifications;