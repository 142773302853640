/* eslint import/no-cycle: 0 */
import React from 'react';
import './ShoppableLook.scss';
import { openProductPanel, setPanelTitle } from 'cms/actions/index';
import classnames from 'classnames';

export const ShoppableLook = ({ dispatch, isDesktop, ids = [] }) => {
  const openPanel = () => {
    dispatch(openProductPanel(ids));
    dispatch(setPanelTitle(""));
  };

  return (
    <div
      className={classnames('shoppable-look--wrapper', {
        'hover-logic': isDesktop,
      })}
      onClick={openPanel}
    >
      <span className="shoppable-look--text">SHOP THE LOOK</span>
      <svg
        height="35"
        viewBox="0 0 157 35"
        width="157"
        xmlns="http://www.w3.org/2000/svg"
        className="shoppable-look--svg"
      >
        <g id="Shop_the_look_icon_-_Closed" transform="translate(-19 -595.666)">
          <g
            id="Rectangle_4105"
            stroke="#fff"
            strokeWidth="0.75"
            transform="translate(19 595.666)"
          >
            <rect
              height="34"
              className="shoppable-look--icon"
              rx="17"
              stroke="#fff"
              style={{ fillOpacity: 0.7 }}
              width="34"
              x="0.375"
              y="0.375"
            />
          </g>
          <g id="icon-bag-empty" transform="translate(25 601.666)">
            <rect fill="none" height="22" id="Rectangle_121" width="22" />
            <path
              d="M15.5,7.412A4.413,4.413,0,0,0,11,3,4.413,4.413,0,0,0,6.5,7.412H2V18H20V7.412ZM11,3.882a3.576,3.576,0,0,1,3.6,3.529H7.4A3.576,3.576,0,0,1,11,3.882ZM6.95,10.941a.883.883,0,1,1,0-1.765.842.842,0,0,1,.9.882A.893.893,0,0,1,6.95,10.941Zm8.1,0a.883.883,0,1,1,.9-.882A.893.893,0,0,1,15.05,10.941Z"
              fill="#fff"
              id="Path_84"
              transform="translate(0 0)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default ShoppableLook;
