import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import { PLP_AND_SRP_FILTERS_RECENT_SIZES } from 'shared/constants';
import { getSelectedGender } from 'client-utils/utilities-gender';
import { clearLocalStorage } from 'client/utilities/utilities-storage';

const saveToStorage = (storage, key, value) => {
  try {
    if(storage){
      storage.setItem(key, value);
    }
  } catch (error) {
    console.log('Error while stroring data to LocalStorage. Errror::', error);
    clearLocalStorage();
    if(storage){
      storage.setItem(key, value);
    }
  }
}

export class RecentSizesStorageHandler {
  constructor(storage = '') {
    if (storage === '') {
      if (typeof (localStorage) !== 'undefined') {
        this.storage = localStorage;
      } else {
        this.storage = undefined;
      }
    } else {
      this.storage = storage;
    }
  }

  getRecentSizesForFilter() {
    if (this.storage === undefined || !this.hasRecentSizesInStorage()) {
      return [];
    }
    return JSON.parse(this.storage.getItem(PLP_AND_SRP_FILTERS_RECENT_SIZES));
  }

  hasRecentSizesInStorage() {
    return this.storage.getItem(PLP_AND_SRP_FILTERS_RECENT_SIZES) !== null;
  }

  upsertRecentSizesForFilter(sizes) {
    const previouslySavedRecentSizes = this.getRecentSizesForFilter();

    if (isEmpty(previouslySavedRecentSizes)) {
      saveToStorage(
        this.storage,
        PLP_AND_SRP_FILTERS_RECENT_SIZES, 
        JSON.stringify(sizes)
      );
      return;
    }

    saveToStorage(
      this.storage,
      PLP_AND_SRP_FILTERS_RECENT_SIZES,
      JSON.stringify(union(sizes, previouslySavedRecentSizes))
    );
  }
}

export class RecentDesignersHandler {
  constructor(storage = '') {
    if (storage === '') {
      if (typeof (localStorage) !== 'undefined') {
        this.storage = localStorage;
      } else {
        this.storage = undefined;
      }
    } else {
      this.storage = storage;
    }

    this.storageKey = this.getStorageKey();
  }

  getStorageKey() {
    if (getSelectedGender() === 'M') {
      return 'recent_designer_m';
    }
    return 'recent_designer_w';
  }

  getRecentDesigners() {
    if (this.storage === undefined || !this.hasRecentDesigners()) {
      return [];
    }
    return JSON.parse(this.storage.getItem(this.storageKey));
  }

  hasRecentDesigners() {
    return this.storage.getItem(this.storageKey) !== null;
  }

  upsertRecentDesigners(designer) {
    const previouslySavedRecentDesigners = this.getRecentDesigners();

    if (isEmpty(previouslySavedRecentDesigners)) {
      saveToStorage(
        this.storage,
        this.storageKey, 
        JSON.stringify(designer)
      )
      return;
    }

    saveToStorage(
      this.storage,
      this.storageKey,
      JSON.stringify(union(designer, previouslySavedRecentDesigners))
    );
  }
}
