export const swatchesMapper = (products = [], lsc) => (
  products.map((product) => {
    const swatches = product?.swatches;

    if (swatches) {
      const selectedSwatch = swatches.filter(swt => !!swt.fct).find(({ fct }) => fct === lsc);

      return {
        ...product,
        swatches: selectedSwatch
          ? [...new Set([selectedSwatch, ...swatches])] : swatches,
      };
    }

    return product;
  })
);
