import React from 'react';

const gridKeys = [
  ['A', 'B', 'C', 'D', 'E'],
  ['F', 'G', 'H', 'I', 'J'],
  ['K', 'L', 'M', 'N', 'O'],
  ['P', 'Q', 'R', 'S', 'T'],
  ['U', 'V', 'W', 'X', 'Y'],
  ['Z', '#'],
];

const indexComp = (letter, enableLink, clickHandler, shouldHighlight) => (
  <td key={letter}>
    {enableLink
      ? (
        <button
          className={shouldHighlight ? 'index-link active-row highlight' : 'index-link active-row'}
          onClick={clickHandler}
          aria-label={`Select "${letter}" for list of designers with name "${letter}"`}
        >
          {letter}
        </button>
      )
      : <button className="index-link disabled-row" disabled>{letter}</button>
    }
  </td>
);

const createAToZGrid = (indexValues, clickHandler, selectedAlphabet) => gridKeys.map(
  (gridRow, index) => (
    <tr key={index}>
      {gridRow.map(
        (indexValue) => indexComp(indexValue, indexValues[indexValue],
          clickHandler, indexValue === selectedAlphabet)
      )}
    </tr>
  )
);

const indexGrid = ({ indexValues, clickHandler, selectedIndexAlphabet }) => (
  <div className="index-grid">
    <p className="designers-by-alpha-text">By A-Z</p>
    <table className="index-grid-table">
      <tbody>
        {createAToZGrid(indexValues, clickHandler, selectedIndexAlphabet)}
      </tbody>
    </table>
  </div>
);

export default indexGrid;
