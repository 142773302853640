import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Loadable from 'react-loadable';
import Button from 'shared/components/Button/button';
import { ENTER_KEYCODE, SPACE_KEYCODE } from 'client-utils/keyCodes';
import getProduct from 'pdp/pages/ProductPage/selectors/getProduct';
import screenReaderAnnouncerAction from 'storefront/components/ScreenReaderAlert/screenReaderAnnouncer-actions';
import { isMobile } from 'client-utils/utilities-page';
import { markStart, markTTD } from 'client-utils/utilities-performance';
import validatePDP, { ADD_TO_CART } from 'pdp/pages/ProductPage/pdpPageValidator';
import { PRECIOUS_JEWELRY_PAYPAL_MESSAGE } from 'pdp/constants';
import {
  addToBag as addToBagAction,
  showAddToBagError as showAddToBagErrorAction,
  clearError as clearErrorAction,
  updateQuantity as updateQty,
} from 'pdp/pages/ProductPage/actions';
import ErrorMessage from '../ErrorMessage/errorMessage';

const ProductCheckout = Loadable({
  loader: () => import('@nmg/pdp-components').then((module) => module.ProductCheckout),
  loading: () => false,
});

export class DumbAddToBagButton extends Component {
  constructor(props) {
    super(props);
    this.onClickAddToBag = this.onClickAddToBag.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onClickQuantity = this.onClickQuantity.bind(this);
  }

  componentDidMount() {
    markTTD('PDP_AddToBagButton');
  }

  shouldComponentUpdate(nextProps) {
    const { quantity, product } = this.props;
    return (
      quantity !== nextProps.quantity
      || product.addToBagError !== nextProps.product.addToBagError
      || nextProps.selectedSku
      || (nextProps.isMiniCartLoading && nextProps.isResolved));
  }

  componentDidUpdate(nextProps) {
    if (nextProps.isMiniCartLoading && nextProps.isResolved) {
      this.handleFocus();
    }
  }

  onClickAddToBag() {
    markStart('PDP_TimeToAddToBag');
    const {
      addToBag,
      clearError,
      customizationOptionTypePDP,
      isLoading,
      isNewPDP,
      product,
      screenReaderAnnouncer,
      selectedSku,
      showAddToBagError,
      skuMediaUrl,
      vendorQtyRestrictions,
      storeNumber,
    } = this.props;

    if (isLoading) {
      return;
    }

    clearError();

    const errorMessage = validatePDP(
      product,
      selectedSku,
      vendorQtyRestrictions,
      ADD_TO_CART,
      customizationOptionTypePDP,
      isNewPDP,
    );
    if (errorMessage) {
      showAddToBagError(errorMessage);
      screenReaderAnnouncer(errorMessage);
      return;
    }

    addToBag(product, selectedSku, skuMediaUrl, storeNumber);
  }

  onClickQuantity(selectedQuantity) {
    const { quantity, updateQuantity } = this.props;
    const defaultValue = 1;

    if (quantity === 0) {
      updateQuantity(defaultValue);
    } else {
      updateQuantity(selectedQuantity + 1);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleFocus() {
    if (!isMobile()) {
      const checkoutButton = document.getElementById('miniCartButton');
      setImmediate(() => {
        if (checkoutButton) {
          checkoutButton.focus();
        }
      });
    }
  }

  handleKeyDown(event) {
    if (event.which === ENTER_KEYCODE || event.which === SPACE_KEYCODE) {
      event.stopPropagation();
      this.onClickAddToBag();
    }
  }


  render() {
    const {
      product,
      selectedSku,
      showPdpRedesign = false,
      quantity,
      vendorQtyRestrictions,
      isGroupPDP = false,
      onlyXLeftThreshold,
    } = this.props;
    const restrictedQty = get(
      vendorQtyRestrictions,
      product.departmentCode, null
    );
    const selectedIndex = quantity ? quantity - 1 : 0;
    let stockLevel = get(selectedSku, 'stockLevel', 10);
    if (stockLevel === 0 || stockLevel === null) {
      stockLevel = 10;
    }
    const preciousJewelryItem = get(product, 'details.preciousJewelryItem', false);

    const showNewPpdRedesignComponents = showPdpRedesign
      && !isGroupPDP
      && !product.isChanel;

    return (
      <>
        {showNewPpdRedesignComponents
          ? (
            <div className="product-checkout-test-id">
              <ProductCheckout
                buttonTitle="Add to Shopping Bag"
                showButton
                onButtonClick={this.onClickAddToBag}
                onKeyDown={this.handleKeyDown}
                onClick={this.onClickQuantity}
                selectedIndex={selectedIndex}
                errorMessage={product.addToBagError}
                resrictionQty={restrictedQty}
                stockLevel={stockLevel}
                message={preciousJewelryItem ? PRECIOUS_JEWELRY_PAYPAL_MESSAGE : ''}
                onlyXLeftThreshold={onlyXLeftThreshold}
                product={product}
                selectedSku={selectedSku}
              />
            </div>
          )
          : (
            <div id="addToBagButton" className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
              <ErrorMessage message={product.addToBagError} />
              <div className="grid-50 tablet-grid-65 mobile-grid-100 grid-parent">
                <Button
                  ariaLabel="Add to Shopping Bag"
                  onClick={this.onClickAddToBag}
                  onKeyDown={this.handleKeyDown}
                  value="Add to Shopping Bag"
                />
              </div>
            </div>
          )
      }
      </>
    );
  }
}

const mapStateToProps = (state, { productId }) => {
  const {
    api: {
      add_to_bag: {
        loading: isLoading,
        resolved: isResolved,
      } = {},
      mini_cart: {
        loading: isMiniCartLoading,
        resolved: isMiniCartResolved,
      } = {},
    },
  } = state;

  const product = getProduct(state, productId);
  const siloCategory = get(state.navigation, 'breadcrumbs[0].id', '');
  const { onlyXLeftThresholds } = state;
  const onlyXLeftThreshold = get(
    onlyXLeftThresholds,
    siloCategory,
    onlyXLeftThresholds.defaultThreshold,
  );

  return {
    isLoading,
    isResolved,
    isMiniCartLoading,
    isMiniCartResolved,
    product,
    vendorQtyRestrictions: state.productCatalog.vendorQtyRestrictions,
    showPdpRedesign: get(state, 'abTestsOpt.nmpd0004.variation', 'a') === 'b',
    quantity: product.quantity,
    onlyXLeftThreshold,
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  addToBag: (...args) => (
    dispatch(addToBagAction(...args))
  ),
  showAddToBagError: (...args) => (
    dispatch(showAddToBagErrorAction(productId, ...args))
  ),
  clearError: () => (
    dispatch(clearErrorAction(productId))
  ),
  screenReaderAnnouncer: (...args) => (
    dispatch(screenReaderAnnouncerAction(...args))
  ),
  updateQuantity: (...args) => dispatch(updateQty(productId, ...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DumbAddToBagButton);
