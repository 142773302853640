import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import window from 'window-or-global';
import { setGender } from 'shared/components/App/app-actions';
import { GENDER_PLACEMENT } from 'universal/constants/genderPlacement';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import './genderSwitcher.scss';

const genderSwitcher = ({
  setGender,
  genderToggle,
  selectedGender,
  brand,
}) => {
  const onGenderSelected = (gender) => {
    setGender(gender);
    window.location.href = gender === 'W' ? '/' : '/mens';
  };

  if (brand !== 'HC' && genderToggle) {
    return (
      <ul className="gender-switcher-container">
        <li
          onClick={() => onGenderSelected('W')}
          className={selectedGender === 'W' ? 'active' : undefined}
        >
          WOMEN
        </li>
        <li
          onClick={() => onGenderSelected('M')}
          className={selectedGender === 'M' ? 'active' : undefined}
        >
          MEN
        </li>
      </ul>
    );
  }

  return null;
};

const mapDispatchToProps = ({
  setGender: (gender) => setGender(gender, GENDER_PLACEMENT.HOME),
});

const mapStateToProps = (state) => {
  return {
    genderToggle: get(state, 'toggles.HP_GENDER'),
    selectedGender: getGenderWithOverride(state),
    brand: state.brand_name.env,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(genderSwitcher);
