import { combineReducers } from 'redux';
import searchReducer, {
  currentPage,
  selectedSortBy,
  selectedFilterOptions,
} from './searchReducer';
import searchReducersHc from 'clientHorchow/components/SearchBox/search-reducers';

export default combineReducers({
  search: searchReducer,
  hcSearch: searchReducersHc,
  currentPage,
  selectedSortBy,
  selectedFilterOptions,
});
