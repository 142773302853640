import { combineReducers } from 'redux';
import breadcrumbReducer from './breadcrumb-reducer';
import editorialClassReducer from './editorialClass-reducer';
import productReducer from './product-reducer';
import pageReducer from './page-reducer';
import userSessionReducer from './userSession-reducer';
import productListReducer from './productList-reducer';
import searchListReducer from './searchList-reducer';
import categoryTemplatesReducer from './categoryTemplates-reducer';
import specialOffers from './specialOffers-reducer';

export default combineReducers({
  breadcrumbs: breadcrumbReducer,
  product: productReducer,
  page: pageReducer,
  userSession: userSessionReducer,
  productListPage: productListReducer,
  srp: searchListReducer,
  categoryTemplates: categoryTemplatesReducer,
  splOffers: specialOffers,
  editorialClass: editorialClassReducer,
});
