import React from 'react';
import { updateCloudinaryUrl, dprSteps } from 'client-utils/utilities-cloudinary';

const MIN_DESKTOP_WIDTH = 1025;
const MIN_TABLET_WIDTH = 768;
const MAX_TABLET_WIDTH = 1024;
const MIN_MOBILE_WIDTH = 1;
const MAX_MOBILE_WIDTH = 767;

export const getSrcSets = (url) => {
  const srcSets = {
    desktop: [],
    tablet: [],
    mobile: [],
  };

  Object.keys(srcSets).forEach((key) => {
    srcSets[key] = dprSteps.map(({ quality, dprValue }) => (
      `${updateCloudinaryUrl(url, { quality, dpr: dprValue })} ${dprValue}x`
    )).join(',');
  });

  return srcSets;
};

const CloudinaryPicture = ({
  src,
  alt = '',
  name,
  title,
  onError,
  onLoad,
  className,
  onClick,
  dataProductId,
  selectedColorName,
}) => {
  const { desktop: desktopSrcSet, mobile: mobileSrcSet, tablet: tabletSrcSet } = getSrcSets(src);

  return (
    <picture className={className} data-name={name}>
      <source
        media={`(min-width: ${MIN_DESKTOP_WIDTH}px)`}
        srcSet={desktopSrcSet}
        width='380'
        height='475'
      />
      <source
        media={`(min-width: ${MIN_TABLET_WIDTH}px) and (max-width: ${MAX_TABLET_WIDTH}px)`}
        srcSet={tabletSrcSet}
        width='380'
        height='475'
      />
      <source
        media={`(min-width: ${MIN_MOBILE_WIDTH}px) and (max-width: ${MAX_MOBILE_WIDTH}px)`}
        srcSet={mobileSrcSet}
        width='380'
        height='475'
      />
      <img
        src={src}
        alt={alt}
        name={name}
        width='295'
        height='369'
        data-product-id={dataProductId}
        data-color-name={selectedColorName}
        title={title}
        onError={onError}
        onLoad={onLoad}
        onClick={onClick}
      />
    </picture>
  );
};

export default CloudinaryPicture;
