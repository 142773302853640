import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';

export function Quiz(props) {
  const { cmsContentItem = {} } = props;
  const { identifier } = get(cmsContentItem, 'fields', {});

  return (
    <div className="quiz-container">
      <script type="text/javascript" src={`https://api-barracuda.zoovu.com/api/v1/advisors/${identifier}/js-loader?locale=en-US`} />
      <div id="zoovu-assistant" />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
});

export default connect(mapStateToProps)(Quiz);
