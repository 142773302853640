import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import classNames from 'classnames';
import Image from 'plp/components/ProductListPage/components/ProductList/components/Product/components/Image/image';
import { ENTER_KEYCODE, SPACE_KEYCODE } from 'client-utils/keyCodes';
import { SOLD_OUT_TEXT } from 'plp/constants';
import { showQLModalWindow } from 'plp/components/ProductListPage/actions';
import Promotions from 'plp/components/ProductListPage/components/ProductList/components/Product/components/Promotions/promotions';
import SalePrice from 'plp/components/ProductListPage/components/ProductList/components/Product/components/SalePrice/salePrice';
import { get113TypePromotion } from 'client-utils/utilities-promotions';
import { renderFormattedPrice } from 'client-utils/utilities-price';
import './ProductTile.scss';

export class DumbProduct extends Component {
  constructor() {
    super();
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    const { isMobilePhone, id, url } = this.props;
    this.element.addEventListener('click', () => {
      if (!isMobilePhone) {
        this.props.showQLModalWindow(id, url);
      }
    });
  }

  onClick = (url) => {
    window.location.href = url;
  }

  handleKeyDown(event, url) {
    if (event.which === ENTER_KEYCODE || event.which === SPACE_KEYCODE) {
      event.stopPropagation();
      window.location.href = url;
    }
  }

  filterPromotions(promotions) {
    return promotions ? partition(promotions, { showPromoPrice: true }) : [[], []];
  }

  render() {
    const {
      id = '',
      imageUrl = '',
      designer = '',
      name = '',
      url = '',
      price = {},
      promotions = [],
      displayable,
      is2x2 = false,
      isSingleProduct = false,
      isMobilePhone = false,
      displayOptions = {},
    } = this.props;
    const {
      displayDesigner,
      displayDescription,
      displayPrice,
      displayPromotions,
    } = displayOptions;
    const [
      promotionWithPrice,
      promotionWithoutPrice,
    ] = this.filterPromotions(promotions);
    const originalPrice = get(price, 'adornments[0].price', 0);
    let formattedOGPrice;
    if (originalPrice > 0) {
      formattedOGPrice = renderFormattedPrice(originalPrice, price.currencyCode);
    }
    originalPrice ? renderFormattedPrice(originalPrice) : null;
    const retailPrice = renderFormattedPrice(price.retailPrice, price.currencyCode);
    const promotionalPrice = renderFormattedPrice(price.promotionalPrice, price.currencyCode);

    let styles = (is2x2 || isMobilePhone)
      ? classNames('product-thumbnail-list grid-50 tablet-grid-50 mobile-grid-50')
      : classNames('product-thumbnail-list grid-25 tablet-grid-25 mobile-grid-50');

    if (isSingleProduct) {
      styles = classNames('product-thumbnail-list grid-100 tablet-grid-100 mobile-grid-100');
    }

    return (
      <button
        ref={(el) => { this.element = el; }}
        className={styles}
        id={id}
        onKeyDown={(e) => this.handleKeyDown(e, url)}
        onClick={isMobilePhone ? () => this.onClick(url) : null}
      >
        <div className="product-thumbnail-list__image">
          <Image src={imageUrl} alt={`${designer} ${name}`} />
          {!displayable
              && <div className="product-thumbnail-list__image__soldout">{SOLD_OUT_TEXT}</div>
            }
        </div>
        <div className="product-thumbnail-list__description">
          {displayDesigner
            && (
            <div className={designer && designer.trim() ? 'product-thumbnail-list__description__designer' : 'no-designer'} aria-hidden="true">
              {designer}
            </div>
            )
          }
          {displayDescription
            && (
              <div className="product-thumbnail-list__description__name" aria-hidden="true">
                {name}
              </div>
            )
          }
          <div className="product-thumbnail-list__description__price">
            <SalePrice
              currencyCode={price.currencyCode}
              retailPrice={displayPrice && retailPrice}
              adornments={price.adornments}
              isPLP
              original={formattedOGPrice}
              isUIPRICTest
              priceAdornmentColor={`#${get113TypePromotion(promotions).templatePricingAdornColor || ''}`}
              promotionComponent={!isEmpty(promotionWithPrice) ? () => (
                <Promotions
                  promotions={promotions}
                  currencyCode={price.currencyCode}
                  promotionalPrice={promotionalPrice}
                  isPLP
                  promoColor={get113TypePromotion(promotions).thumbnailPromoColor}
                  promoText={get113TypePromotion(promotions).templateHTML}
                  isUIPRICTest
                />
              ) : null
              }
            />
            {displayPromotions && <Promotions promotions={promotionWithoutPrice} />}
          </div>
        </div>
      </button>
    );
  }
}

export default connect((state) => state, { showQLModalWindow })(DumbProduct);
